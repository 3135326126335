import { createPopper } from '@popperjs/core';
import { DateTime } from 'luxon';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { ComparableDateRange } from '@/models/ComparableDateRange';
import { DateOrISO } from './Types';

const getObjectDiff = <T extends Record<string, any>>(object1: T, object2: T): Partial<T> => {
	const properties = [...new Set([...Object.keys(object1), ...Object.keys(object2)])];
	const changedProperties = properties.reduce((diff, key) => {
		if (object2[key] === object1[key]) {
			return diff;
		}

		return {
			...diff,
			[key]: object1[key],
		};
	}, {});

	return changedProperties;
};

const isDifferent = (object1: Record<string, any>, object2: Record<string, any>): boolean => Object.keys(getObjectDiff(object1, object2)).length > 0;

const orderByDate = <P extends string, K extends { [U in P]: Date | string }>(
	array: K[],
	dateProperty: P,
	ascOrDesc: 'asc' | 'desc' = 'asc',
): K[] => {
	const sortedArray = [...array].sort((itemA, itemB) => {
		const dateA = typeof itemA[dateProperty] === 'string' ? new Date(itemA[dateProperty]) : itemA[dateProperty] as Date;
		const dateB = typeof itemB[dateProperty] === 'string' ? new Date(itemB[dateProperty]) : itemB[dateProperty] as Date;

		return dateA.getTime() - dateB.getTime();
	});

	if (ascOrDesc === 'desc') {
		sortedArray.reverse();
	}

	return sortedArray;
};

const generateUniqueKeyByObjectValues = (object: Record<string, any>): string => {
	const valuesString = Object.values(object).join('-').replace(/\s+/g, '-').toLowerCase();
	return valuesString;
};

const withPopper = (dropdownList: HTMLUListElement, component: any, { width }: { width: string }) => {
	dropdownList.style.width = width;

	const toggle = component.$refs.toggle as HTMLElement;

	const popper = createPopper(toggle, dropdownList, {
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 8],
				},
			},
			{
				name: 'toggleClass',
				enabled: true,
				phase: 'write',
				fn({ state }) {
					component.$el.classList.toggle('drop-up', state.placement === 'top');
				},
			}],
	});

	return () => popper.destroy();
};

const createURLQueryPath = (path: string, params: Record<string, string>): string => {
	const paramsArray = Object.entries(params);
	const queryString = paramsArray.reduce((string, param) => {
		let newString = string;

		if (string) {
			newString += `&${param[0]}=${param[1]}`;
			return newString;
		}

		newString += `?${param[0]}=${param[1]}`;
		return newString;
	}, '');

	return `${path}${queryString}`;
};

const formatDecimal = (input: number, decimals: number): number => {
	const factor = 10 ** decimals;
	const a = input * factor;
	const b = Math.round(a);
	const c = b / factor;
	return c;
};

const isSameDay = (date1: Date, date2: Date): boolean => {
	const isSameYear = date1.getFullYear() === date2.getFullYear();

	if (!isSameYear) {
		return false;
	}

	const isSameMonth = date1.getMonth() === date2.getMonth();

	if (!isSameMonth) {
		return false;
	}

	const isSameDate = date1.getDate() === date2.getDate();

	return isSameDate;
};

const toLocaleISOTime = (date: Date) => {
	const tzoffset = date.getTimezoneOffset() * 60000;
	const localISOTime = (new Date(date.getTime() - tzoffset)).toISOString().slice(0, -1);
	return localISOTime;
};

const normalizeDate = (date: Date) => {
	const dateCopy = new Date(date);

	dateCopy.setHours(0, 0, 0, 0);

	return dateCopy;
};

const fillUpDateArray = <P extends string, T extends { [U in P]: DateOrISO }>(dateRange: ComparableDateRange, dateArray: T[], dateProperty: P, fillInFunction: (date: Date) => T): T[] => {
	if (dateRange.diffInDays === dateArray.length) {
		return dateArray;
	}

	const orderedDateArray = orderByDate([...dateArray], dateProperty, 'desc');

	if (orderedDateArray.length === 0) {
		orderedDateArray.push(fillInFunction(dateRange.endDate));
	}

	const filledUpArray = orderedDateArray.reduce((newArray, item, currentIndex) => {
		const itemDate = new Date(item[dateProperty]);

		if (currentIndex === 0 && !isSameDay(itemDate, dateRange.endDate)) {
			const currentDate = new Date(dateRange.endDate);

			while (currentDate.getTime() > itemDate.getTime()) {
				newArray.push(fillInFunction(new Date(currentDate)));
				currentDate.setDate(currentDate.getDate() - 1);
			}
		}

		newArray.push(item);

		const nextItem = orderedDateArray[currentIndex + 1];

		if (nextItem) {
			const nextItemDate = new Date(nextItem[dateProperty]);

			const currentDate = new Date(itemDate);
			currentDate.setDate(currentDate.getDate() - 1);

			while (currentDate.getTime() > nextItemDate.getTime()) {
				newArray.push(fillInFunction(new Date(currentDate)));
				currentDate.setDate(currentDate.getDate() - 1);
			}
		}

		if (currentIndex === (orderedDateArray.length - 1) && !isSameDay(itemDate, dateRange.startDate)) {
			const currentDate = new Date(itemDate);
			currentDate.setDate(currentDate.getDate() - 1);

			while (currentDate.getTime() > dateRange.startDate.getTime()) {
				newArray.push(fillInFunction(new Date(currentDate)));
				currentDate.setDate(currentDate.getDate() - 1);
			}

			newArray.push(fillInFunction(dateRange.startDate));
		}

		return newArray;
	}, [] as T[]);

	return filledUpArray;
};

const clamp = (number: number, min: number, max: number) => {
	if (number < min) {
		return min;
	}

	if (number > max) {
		return max;
	}

	return number;
};

export const ensureDate = (date?: Date, maxDaysFromNow = 30): Date => {
	const defaultStartDate = DateTime.now().minus({ days: maxDaysFromNow });

	if (date) {
		const inputDate = DateTime.fromJSDate(date);

		if (defaultStartDate < inputDate) {
			return inputDate.plus({ days: 1 }).toJSDate();
		}
	}

	return defaultStartDate.toJSDate();
};

export const getEndOfDayTime = () => {
	const end = new Date();
	end.setUTCHours(23, 59, 59, 999);
	return end.getTime();
};

export const getTimeUntilEndOfDay = () => {
	const now = Date.now();
	const endOfDay = getEndOfDayTime();

	return endOfDay - now;
};

export const toDate = (dateOrISO: DateOrISO): Date => {
	if (typeof dateOrISO === 'string') {
		return new Date(dateOrISO);
	}

	return dateOrISO;
};

export const getDateTime = (date: DateTime | Date): DateTime => {
	if (DateTime.isDateTime(date)) {
		return date;
	}

	return DateTime.fromJSDate(date);
};

export const getJSDate = (date: DateTime | Date): Date => {
	if (DateTime.isDateTime(date)) {
		return date.toJSDate();
	}

	return date;
};

export const toInt = (string: string) => parseInt(string, 10);

export const asArray = <T>(value: T | T[]): T[] => {
	if (Array.isArray(value)) {
		return value;
	}

	if (value) {
		return [value];
	}

	return [];
};

export const removeFromArray = <T>(array: T[], itemToRemove: T): T[] => {
	const optionIndex = array.findIndex((s) => s === itemToRemove);
	const newSelection = [...array];

	newSelection.splice(optionIndex, 1);

	return newSelection;
};

export const getStrategyIdFromRoute = (route: RouteLocationNormalizedLoaded): number | null => {
	if (!route.query.strategy) {
		return null;
	}

	if (Array.isArray(route.query.strategy)) {
		if (route.query.strategy[0] === null) {
			return null;
		}

		return parseInt(route.query.strategy[0], 10);
	}

	return parseInt(route.query.strategy, 10);
};

export const isObject = (obj: any): obj is object => obj !== null && typeof obj === 'object' && !Array.isArray(obj);

export const keyCodes = Object.freeze({
	enter: 13,
	tab: 9,
	delete: 46,
	esc: 27,
	space: 32,
	up: 38,
	down: 40,
	left: 37,
	right: 39,
	end: 35,
	home: 36,
	del: 46,
	backspace: 8,
	insert: 45,
	pageup: 33,
	pagedown: 34,
	shift: 16,
});

export const localize = (input: string | (() => string)): string => {
	if (typeof input === 'function') {
		return input();
	}

	return input;
};

export {
	getObjectDiff,
	orderByDate,
	generateUniqueKeyByObjectValues,
	isDifferent,
	withPopper,
	createURLQueryPath,
	formatDecimal,
	isSameDay,
	toLocaleISOTime,
	normalizeDate,
	fillUpDateArray,
	clamp,
};
