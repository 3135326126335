export interface StorageItemWithExpiry<T extends any> {
	value: T;
	expiry: number;
}

export const setWithExpiry = (key: string, value: any, timeToExpire: number) => {
	const now = Date.now();

	const item = {
		value,
		expiry: now + timeToExpire,
	};

	localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = <T extends any>(key: string): T | null => {
	const itemString = localStorage.getItem(key);

	if (!itemString) {
		return null;
	}

	const item: StorageItemWithExpiry<T> = JSON.parse(itemString);

	const now = Date.now();

	if (now > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
};
