import * as Sentry from '@sentry/browser';
import { DateTime } from 'luxon';
import { APIClient } from '@/api/clients';
import { ressourceClient } from '@/api/clients/ressourceClient';

const getDefaultState = () => ({
	providers: [],
	selectedProvider: null,
	status: '',
	integrationDates: [],
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_PROVIDERS(state, providers) {
			state.status = 'fetched';
			state.providers = providers;
		},
		SET_SELECTED_PROVIDER(state, providerId) {
			state.selectedProvider = providerId;
			Sentry.setContext('Selected ProviderId', { providerId });
		},
		ADD_INTEGRATION_DATE(state, integrationDateObject) {
			state.integrationDates.push(integrationDateObject);
		},
	},
	actions: {
		resetProviderState({ commit }) {
			commit('RESET_STATE');
		},
		async fetchProviders({ commit, state }, force = false) {
			if (force || state.status !== 'fetched') {
				const response = await APIClient.useWithLoaderToJson(ressourceClient.client.get('providers', {
					searchParams: {
						order: 'Name',
						Predicates: 'ProviderStatus!=Deleted',
					},
				}));

				commit('SET_PROVIDERS', response.Objects);
			}
		},
		async selectProvider({ commit, dispatch }, providerId) {
			await dispatch('fetchIntegrationDateByProviderId', providerId);
			commit('SET_SELECTED_PROVIDER', providerId);
			dispatch('fetchSelectedProvidersIntegrationDate');
		},
		async fetchSelectedProvidersIntegrationDate({ commit, state, getters }) {
			if (state.integrationDates.findIndex((date) => date.ProviderLink_Id === getters.selectedProvider.Id) < 0) {
				const response = await APIClient.useWithLoaderToJson(ressourceClient.client.get('strategies', {
					searchParams: {
						ProviderLink_Id: getters.selectedProvider.Id,
						ParentStrategyLink_Id: 'null',
						predicates: 'FirstExecution!=null',
						order: 'FirstExecution',
						take: '1',
					},
				}));

				const strategy = response.Objects[0];

				commit('ADD_INTEGRATION_DATE', {
					ProviderLink_Id: getters.selectedProvider.Id,
					IntegrationDate: strategy ? (strategy.StartDate || strategy.FirstExecution) : null,
				});
			}
		},
		async fetchIntegrationDateByProviderId({ commit, state }, providerId) {
			if (state.integrationDates.findIndex((date) => date.ProviderLink_Id === providerId) < 0) {
				const response = await APIClient.useWithLoaderToJson(ressourceClient.client.get('strategies', {
					searchParams: {
						ProviderLink_Id: providerId,
						ParentStrategyLink_Id: 'null',
						predicates: 'FirstExecution!=null',
						order: 'FirstExecution',
						take: '1',
					},
				}));

				const strategy = response.Objects[0];

				commit('ADD_INTEGRATION_DATE', {
					ProviderLink_Id: providerId,
					IntegrationDate: strategy ? (strategy.StartDate || strategy.FirstExecution) : null,
				});
			}
		},
	},
	getters: {
		providers: (state) => state.providers,
		selectedProvider: (state) => state.providers.find((provider) => provider.Id === state.selectedProvider) || state.providers[0],
		hasMultipleProvider: (state) => state.providers.length > 1,
		providersIntegrationDate: (state, getters) => {
			const providersIntegrationDate = state.integrationDates.find((date) => date.ProviderLink_Id === getters.selectedProvider.Id);

			if (providersIntegrationDate) {
				return DateTime.fromISO(providersIntegrationDate.IntegrationDate, { zone: 'utc' });
			}

			return null;
		},
	},
};
