import { orderByDate } from '@/utilities/Helpers';
import { MUTATION_DATE_RANGE } from '@/utilities/Constants';
import { APIClient } from '@/api/clients';
import { ressourceClient } from '@/api/clients/ressourceClient';

const getDefaultState = () => ({
	updates: [],
	totalUpdatesInRange: 0,
	isLoading: false,
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_UPDATES(state, updates) {
			state.updates = updates;
		},
		SET_TOTAL_UPDATES_IN_RANGE(state, total) {
			state.totalUpdatesInRange = total;
		},
		UPDATE_UPDATES(state, updates) {
			updates.forEach((update) => {
				const index = state.updates.findIndex((stateUpdate) => stateUpdate.MutationDate === update.MutationDate);

				if (index >= 0) {
					state.updates[index] = update;
				} else {
					state.updates.push(update);
				}
			});
		},
		SET_LOADING_STATE(state, isLoading) {
			state.isLoading = isLoading;
		},
	},
	actions: {
		resetUpdatesState({ commit }) {
			commit('RESET_STATE');
		},
		async fetchUpdates({ commit, rootGetters }, { total = 5, pagination = false, silent = false }) {
			commit('SET_LOADING_STATE', true);

			const response = await APIClient.useWithLoaderToJson(ressourceClient.client.get('MutationLogs', {
				searchParams: {
					take: total,
					order: 'MutationDate desc',
					MutationDate: MUTATION_DATE_RANGE,
					ProviderLink_Id: rootGetters['provider/selectedProvider'].Id,
					pagination,
				},
				silent,
			}));

			commit('SET_LOADING_STATE', false);

			if (pagination) {
				commit('SET_TOTAL_UPDATES_IN_RANGE', response.Pagination.Total);
			}

			commit('SET_UPDATES', response.Objects);
		},
		async getCpcUpdates({ commit }, { total, silent = false }) {
			commit('SET_LOADING_STATE', true);

			const response = await APIClient.useWithLoaderToJson(ressourceClient.client.get('MutationLogs', {
				searchParams: {
					take: total,
					order: 'MutationDate desc',
					MutationDate: MUTATION_DATE_RANGE,
					MutationType: 'Cpc',
					pagination: false,
				},
				silent,
			}));

			commit('SET_LOADING_STATE', false);
			commit('UPDATE_UPDATES', response.Objects);

			return response.Objects;
		},
		async getReactorUpdates({ commit }, { total, silent = false }) {
			commit('SET_LOADING_STATE', true);

			const response = await APIClient.useWithLoaderToJson(ressourceClient.client.get('MutationLogs', {
				searchParams: {
					take: total,
					order: 'MutationDate desc',
					MutationDate: MUTATION_DATE_RANGE,
					MutationType: 'Reactor',
					pagination: false,
				},
				silent,
			}));

			commit('SET_LOADING_STATE', false);
			commit('UPDATE_UPDATES', response.Objects);

			return response.Objects;
		},
	},
	getters: {
		updates: (state) => orderByDate(state.updates, 'MutationDate', 'desc'),
		cpcUpdates: (state) => orderByDate(state.updates.filter((update) => update.MutationType === 'Cpc'), 'MutationDate', 'desc'),
		reactorUpdates: (state) => orderByDate(state.updates.filter((update) => update.MutationType === 'Reactor'), 'MutationDate', 'desc'),
		isLoading: (state) => state.isLoading,
		totalUpdatesInRange: (state) => state.totalUpdatesInRange,
	},
};
