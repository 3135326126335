import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "icon-wrapper flex items-center justify-center absolute inset-0 z-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createElementBlock("button", _mergeProps({ class: _ctx.classObject }, _ctx.$attrs, {
    class: "text-base font-semibold relative flex items-center justify-center box-border rounded-md font-sans transition-all focus:outline focus:outline-1 outline-offset-2 disabled:cursor-not-allowed",
    disabled: _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }), [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "loadingText", {}, () => [
            _createElementVNode("div", {
              class: _normalizeClass(["loader loader-4", _ctx.loaderColorClass])
            }, null, 2)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass([{
				'opacity-0 translate-y-4': _ctx.isLoading,
				'flex-row-reverse': _ctx.iconPosition === 'right',
			}, "transition flex items-center transform z-10 relative"])
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_AtomIcon, {
            key: 0,
            icon: _ctx.icon,
            size: "sm",
            class: _normalizeClass({
					'mr-2': _ctx.iconPosition === 'left' && _ctx.label.length > 0,
					'ml-2': _ctx.iconPosition === 'right' && _ctx.label.length > 0,
				})
          }, null, 8, ["icon", "class"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ], 2)
  ], 16, _hoisted_1)), [
    [_directive_ripple]
  ])
}