import { computed, reactive, toRefs } from 'vue';
import { APIClient } from '@/api/clients';
import { ressourceClient } from '@/api/clients/ressourceClient';
import { fetchUsers } from '@/api/users';
import { User } from '@/models/User';
import { UserSettings } from '@/models/UserSettings';

export interface UserState {
	users: User[];
	settings: Partial<UserSettings>;
}

const defaultUserSettings = {};

const getDefaultState = () => ({
	users: [],
	settings: { ...defaultUserSettings, ...JSON.parse(localStorage.getItem('userSettings') || '{}') },
});

const state = reactive<UserState>(getDefaultState());

export const useUserStore = () => {
	const currentUser = computed(() => state.users[0]);

	const getUsers = async (): Promise<User[]> => {
		const result = await fetchUsers();

		if (result.isErr()) {
			return [];
		}

		state.users = result.value;

		return result.value;
	};

	const changePasswordForCurrentUser = async (newPassword: string) => {
		if (!currentUser.value) {
			throw new Error('Can not change password, because no user is logged in.');
		}

		const response = await APIClient.useWithLoaderToJson(ressourceClient.client.post(`users/${currentUser.value.Id}`, {
			json: {
				Password: newPassword,
			},
		}));

		return response;
	};

	const updateUserSettings = (newSettings: Partial<UserSettings>) => {
		const mergedSettings = { ...state.settings, ...newSettings };

		localStorage.setItem('userSettings', JSON.stringify(mergedSettings));

		state.settings = mergedSettings;
	};

	const resetState = () => {
		Object.assign(state, getDefaultState());
	};

	return {
		...toRefs(state),
		currentUser,
		getUsers,
		changePasswordForCurrentUser,
		updateUserSettings,
		resetState,
	};
};
