import { nextTick } from 'vue';
import { ressourceClient } from '@/api/clients/ressourceClient';
import { APIClient } from '@/api/clients';
import { useSelectionStorage } from '@/composables/useSelectionStorage';
import { useUserStore } from '../user';
import { useGlobalStore } from '../global';

export default {
	namespaced: true,
	state: {
		token: localStorage.getItem('authToken') || '',
		status: '',
	},
	mutations: {
		REQUEST_AUTHENTICATION(state) {
			state.status = 'loading';
		},
		AUTH_SUCCESS(state) {
			state.status = 'success';
		},
		AUTH_ERROR(state) {
			state.status = 'error';
			localStorage.removeItem('authToken');
			state.token = '';
		},
		AUTH_LOGOUT(state) {
			state.status = 'loggedout';
			localStorage.removeItem('authToken');
			useSelectionStorage().clear();
			state.token = '';
		},
		SET_TOKEN(state, token) {
			state.token = token;
		},
	},
	actions: {
		requestPasswordReset(context, { email }) {
			return APIClient.useWithLoaderToJson(ressourceClient.client.post(`user/request-reset-password?email=${encodeURIComponent(email)}`));
		},
		resetPassword(context, { token, password }) {
			return APIClient.useWithLoaderToJson(ressourceClient.client.post(`user/reset-password?token=${token}&password=${encodeURIComponent(password)}`));
		},

		async requestAuthentication({ commit, dispatch }, { userName, password, saveToken }) {
			try {
				commit('REQUEST_AUTHENTICATION');

				const response = await APIClient.useWithLoaderToJson(ressourceClient.client.post('login', {
					json: {
						userName,
						password,
					},
				}));

				const { AccessToken } = response.Objects[0];
				commit('SET_TOKEN', AccessToken);
				await dispatch('initAuthentication');

				if (saveToken) {
					localStorage.setItem('authToken', AccessToken);
				}
			} catch (error) {
				commit('AUTH_ERROR', error);
				throw error;
			}
		},
		async logoutUser({ dispatch }) {
			const globalStore = useGlobalStore();

			globalStore.showLoadingScreen = true;

			await nextTick();

			dispatch('resetStore', '', { root: true });
			dispatch('removeAuthentication');

			globalStore.showLoadingScreen = false;
		},
		removeAuthentication(context) {
			ressourceClient.setConfig({ headers: { user_token: null } });
			context.commit('AUTH_LOGOUT');
		},
		async initAuthentication({
			state, rootGetters, dispatch, commit,
		}) {
			const { token, status } = state;
			const { getUsers } = useUserStore();

			if (token && status !== 'success') {
				ressourceClient.setConfig({ headers: { user_token: token } });

				await Promise.all([
					dispatch('provider/fetchProviders', null, { root: true }),
					getUsers(),
				]);

				await dispatch('provider/selectProvider', rootGetters['provider/providers'][0].Id, { root: true });

				commit('AUTH_SUCCESS');
			}
		},
	},
	getters: {
		isAuthenticated: (state) => !!state.token,
		authStatus: (state) => state.status,
	},
};
