<template>
	<div
		v-ripple
		class="relative px-6 pt-6 pb-5 flex transition group ripple-purple-300"
		:class="{
			'bg-white hover:bg-purple-200': active,
			'hover:bg-purple-100': !active,
		}"
	>
		<MoleculeKPIIconBadge
			:kpi="kpi.key"
			class="mr-3"
		/>
		<div>
			<p class="text-xs mb-1">
				{{ kpi.displayName }}
			</p>
			<p
				v-animated-number:[kpi.formatter]="kpi.originDataset.alltimeValue"
				class="text-xl font-semibold tracking-tight"
			/>
			<div
				ref="appendAchor"
				class="group inline-block"
			>
				<AtomLink
					:icon="trendIcon"
					:to="''"
					size="sm"
					:color="trendColor"
					@mouseover="showCompareOverlay = true"
					@mouseleave="showCompareOverlay = false"
					@focusin="showCompareOverlay = true"
					@focusout="showCompareOverlay = false"
				>
					<span v-animated-number.toPercentageFixed="kpi.percentDiffValue()" />
				</AtomLink>
				<teleport to="#poppers">
					<div
						ref="overlayRef"
						class="z-50"
						:class="{
							'pointer-events-none': !showCompareOverlay,
						}"
					>
						<OrganismOverlayCompare
							class="transition min-w-[480px]"
							:class="{
								'opacity-0': !showCompareOverlay,
							}"
							:range1="toCompareDateRange(kpi.compareDataset, kpi.hasAverage)"
							:range2="toCompareDateRange(kpi.originDataset, kpi.hasAverage)"
							:formatter="kpi.formatter"
							@mouseover="showCompareOverlay = true"
							@mouseleave="showCompareOverlay = false"
							@focusin="showCompareOverlay = true"
							@focusout="showCompareOverlay = false"
						/>
					</div>
				</teleport>
			</div>
		</div>
		<div
			class="absolute -z-[1] w-full h-full shadow-md top-0 left-0 pointer-events-none rounded-lg transition"
			:class="{
				'opacity-0 group-hover:opacity-100': !active,
			}"
		/>
	</div>
</template>

<script lang="ts">
import {
	computed, defineComponent, onMounted, PropType, ref,
} from 'vue';
import { DateTime } from 'luxon';
import { isNumber } from 'lodash';
import { createPopper } from '@popperjs/core';
import { Insight } from '@/models/Insight';
import { InsightDataset } from '@/models/InsightDataset';
import AnimatedNumber from '@/directives/AnimatedNumberDirective';
import { Ripple } from '@/directives/RippleDirective';
import OrganismOverlayCompare, { CompareDateRange } from '../OrganismOverlayCompare/OrganismOverlayCompare.vue';
import MoleculeKPIIconBadge from '../../molecules/MoleculeKPIIconBadge/MoleculeKPIIconBadge.vue';
import AtomLink from '../../atoms/AtomLink/AtomLink.vue';

export default defineComponent({
	name: 'OrganismKPICard',

	components: {
		AtomLink,
		MoleculeKPIIconBadge,
		OrganismOverlayCompare,
	},

	directives: {
		AnimatedNumber,
		Ripple,
	},

	props: {
		kpi: {
			type: Object as PropType<Insight>,
			required: true,
		},
		active: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const showCompareOverlay = ref(false);
		const appendAchor = ref();
		const overlayRef = ref();

		const toCompareDateRange = (insightDataset: InsightDataset, hasAverage: boolean): CompareDateRange => ({
			start: DateTime.fromJSDate(insightDataset.startDate),
			end: DateTime.fromJSDate(insightDataset.endDate),
			totalValue: insightDataset.alltimeValue,
			dailyAverageValue: hasAverage ? insightDataset.getValueAverage() : undefined,
		});

		const trendDirection = computed(() => props.kpi.trendDirection());

		const percentDiffValue = computed(() => props.kpi.percentDiffValue());

		const trendIcon = computed(() => {
			if (trendDirection.value === 'up' && isNumber(percentDiffValue.value)) {
				return 'trend-up';
			}

			return 'trend-down';
		});

		const trendColor = computed(() => {
			if (props.kpi.trendType() === 'positive' && isNumber(props.kpi.percentDiffValue())) {
				return 'green';
			}

			if (props.kpi.trendType() === 'negative' && isNumber(props.kpi.percentDiffValue())) {
				return 'red';
			}

			return 'purple';
		});

		onMounted(() => {
			if (!appendAchor.value || !overlayRef.value) {
				return;
			}

			createPopper(appendAchor.value, overlayRef.value);
		});

		return {
			showCompareOverlay,
			appendAchor,
			overlayRef,
			toCompareDateRange,
			trendColor,
			trendIcon,
		};
	},
});
</script>
