import './plugins/VeeValidate';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Toast from 'vue-toastification';
import VueTippy from 'vue-tippy';
import { vfmPlugin } from 'vue-final-modal';
import { options as toastOptions } from '@/plugins/Toast';
import App from './App.vue';
import router from './router';
import { store } from './store';
import i18n from './plugins/i18n';
import { FreshworksWidgetPlugin } from './plugins/FreshworksWidget';
import './plugins/numeral';
import { registerGlobalComponents } from './plugins/GlobalComponents';
import './index.css';
import { pinia } from './plugins/pinia';
import { appProgressLoader } from './plugins/AppProgressLoader';

const app = createApp(App);

Sentry.init({
	app,
	dsn: 'https://85e21e45d3db4af7baf8fd0e9a183cd8@o440428.ingest.sentry.io/5411551',
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: ['netlify.app', 'biddinglab.com', /^\//],
		}),
	],
	tracesSampleRate: 0.7,
	environment: process.env.VUE_APP_ENVIRONMENT,
	release: process.env.VUE_APP_COMMIT_REF,
});

app.use(VueGtag, {
	config: {
		id: 'UA-147533835-2',
	},
}, router);

app.use(vfmPlugin(), {
	key: '$modal',
});

app.use(VueTippy, {
	directive: 'tippy',
	defaultProps: {
		theme: 'info',
		animation: 'shift-toward',
	},
});
app.use(FreshworksWidgetPlugin);
app.use(appProgressLoader, { router });
app.use(pinia);
app.use(store);
app.use(router);
app.use(i18n);
app.use(Toast, toastOptions);

registerGlobalComponents(app);

app.mount('#app');
