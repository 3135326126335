<template>
	<MoleculeOverlayBox class="w-80">
		<div class="flex">
			<AtomBadgeUser
				:user="user"
				class="mr-3"
			/>
			<div>
				<p class="text-sm font-semibold">
					{{ transformedUserName }}
				</p>
				<p class="text-sm text-grey-500">
					{{ accountType }}
				</p>
				<AtomLink
					:to="logout"
					color="grey"
					size="sm"
					icon="log-out"
					class="mt-3"
				>
					{{ $t('button.logout') }}
				</AtomLink>
			</div>
		</div>
	</MoleculeOverlayBox>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { User, getAccountTypeFromUser } from '@/models/User';
import { LinkLocation } from '@/components/atoms/AtomLink/AtomLinkModel';
import MoleculeOverlayBox from '../../molecules/MoleculeOverlayBox/MoleculeOverlayBox.vue';
import AtomBadgeUser from '../../atoms/AtomBadgeUser/AtomBadgeUser.vue';
import AtomLink from '../../atoms/AtomLink/AtomLink.vue';

const USERNAME_CHARACTER_LIMIT = 26;

export default defineComponent({
	name: 'OrganismOverlayUser',

	components: {
		AtomBadgeUser,
		AtomLink,
		MoleculeOverlayBox,
	},

	props: {
		user: {
			type: Object as PropType<User>,
			required: true,
		},
		logout: {
			type: [String, Object, Function] as PropType<LinkLocation>,
			required: true,
		},
	},

	setup(props) {
		const accountType = computed(() => getAccountTypeFromUser(props.user));

		const transformedUserName = computed(() => {
			const userName = props.user.UserName;
			if (userName.length > USERNAME_CHARACTER_LIMIT) {
				return `${userName.slice(0, USERNAME_CHARACTER_LIMIT)}...`;
			}
			return userName;
		});

		return {
			accountType,
			transformedUserName,
		};
	},
});
</script>
