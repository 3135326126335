<template>
	<MoleculeSelectWithLabel
		v-model="selectedLanguage"
		:options="languageOptions"
		required
		color-mode="dark"
		:border="false"
		:same-width="false"
	/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useLanguageSelect } from '@/composables/useLanguageSelect';
import MoleculeSelectWithLabel from '@/components/molecules/MoleculeSelectWithLabel/MoleculeSelectWithLabel.vue';

export default defineComponent({
	name: 'LanguageMenu',

	components: { MoleculeSelectWithLabel },

	setup() {
		const { languageOptions, selectedLanguage } = useLanguageSelect();

		return {
			languageOptions,
			selectedLanguage,
		};
	},
});
</script>
