<template>
	<OrganismHero
		v-model:provider="selectedProviderName"
		:user="currentUser"
		:providers="providerOptions"
		:logout="onLogout"
		:headline="viewTitle"
		:subheadline="viewSubtitle"
		:icon="viewIcon"
		compact
	/>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { computed, defineComponent } from 'vue';
import {
	useRouter, useRoute, RouteRecordNormalized, RouteLocationNormalized,
} from 'vue-router';
import OrganismHero from '@/components/organisms/OrganismHero/OrganismHero.vue';
import { useUserStore } from '@/store/user';
import { useProvider } from '@/composables/useProvider';

export default defineComponent({
	name: 'TheHeader',

	components: {
		OrganismHero,
	},

	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const { currentUser } = useUserStore();
		const { selectedProviderName, providerOptions } = useProvider();

		const onLogout = async () => {
			store.dispatch('auth/logoutUser');
			router.push({ name: 'login' });
		};

		const getRouteTitle = (inputRoute: RouteRecordNormalized | RouteLocationNormalized): string => {
			if (!inputRoute.meta.title) {
				return '';
			}

			if (typeof inputRoute.meta.title === 'function') {
				return inputRoute.meta.title();
			}

			return inputRoute.meta.title as string;
		};

		const getViewTitle = () => {
			const firstRouteWithTitle = route.matched.find((r) => !!r.meta.title);

			if (firstRouteWithTitle) {
				return getRouteTitle(firstRouteWithTitle);
			}

			return getRouteTitle(route);
		};

		const viewTitle = computed(() => getViewTitle());

		const viewSubtitle = computed(() => {
			if (!route.meta.subheadline) {
				return '';
			}

			if (typeof route.meta.subheadline === 'function') {
				return route.meta.subheadline();
			}

			return route.meta.subheadline;
		});

		const viewIcon = computed(() => {
			if (!route.meta.icon) {
				return null;
			}

			return route.meta.icon;
		});

		return {
			currentUser,
			selectedProviderName,
			providerOptions,
			onLogout,
			viewTitle,
			viewSubtitle,
			viewIcon,
		};
	},
});
</script>
