import { RouteRecordRaw } from 'vue-router';
import { managementNavigation } from '@/utilities/Navigations';

const routes: RouteRecordRaw[] = [{
	path: '/management',
	name: 'management',
	component: () => import(/* webpackChunkName: "TabViewWrapper" */ '@/components/TabViewWrapper.vue'),
	redirect: { name: 'accountSettings' },
	props: {
		navigation: managementNavigation,
	},
	meta: {
		title: () => 'Settings',
		subheadline: 'Set up your profile to fit YOU',
		icon: 'cog',
		requiresAuth: true,
	},
	children: [
		{
			path: 'account-settings',
			name: 'accountSettings',
			component: () => import(/* webpackChunkName: "accountSettings" */ '@/views/AccountSettings.vue'),
			meta: {
				title: () => 'Account Settings',
			},
		},
		{
			path: 'customization',
			name: 'dashboardCustomization',
			component: () => import(/* webpackChunkName: "dashboardCustomization" */ '@/views/DashboardCustomization.vue'),
			meta: {
				title: () => 'Customization',
			},
		},
	],
}];

export default routes;
