export enum InsightType {
	IMPRESSIONS = 'Impressions',
	CLICKS = 'Clicks',
	CTR = 'Ctr',
	CPC = 'AverageCpc',
	COST = 'Cost',
	CONVERSIONS = 'Conversions',
	COST_PER_CONVERSION = 'CostPerConversion',
	ROAS = 'Roas',
	CONVERSION_VALUE = 'ConversionValue'
}

export const DEFAULT_SELECTED_INSIGHT_TYPES = [InsightType.CLICKS, InsightType.COST, InsightType.ROAS];
