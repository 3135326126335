import {
	ref, computed, Ref, watch,
} from 'vue';
import { errAsync } from 'neverthrow';
import { Strategy } from '@/models/Strategy';
import { fetchTopLevelStrategies } from '@/api/strategy';

export const useTopLevelStrategies = (options: { providerId: Ref<number | null> }) => {
	const strategies = ref<Strategy[]>([]);
	const isLoading = ref(false);

	const fetch = () => {
		if (options.providerId.value === null) {
			return errAsync(new Error('Can not use fetchInsightsByDateRange without ProviderId'));
		}

		isLoading.value = true;

		return fetchTopLevelStrategies(options.providerId.value)
			.map((s) => {
				strategies.value = s;
				isLoading.value = false;
				return s;
			})
			.mapErr((error) => {
				isLoading.value = false;
				return error;
			});
	};

	const activeStrategies = computed(() => {
		const topLevelStrategiesOptions = strategies.value.filter((strategy) => strategy.State !== 'Inactive').map((strategy) => strategy.Name);
		return topLevelStrategiesOptions;
	});

	watch([options.providerId], () => {
		fetch();
	}, { immediate: true });

	return {
		fetch,
		strategies,
		activeStrategies,
	};
};
