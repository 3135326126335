<template>
	<div class="bg-purple-600 bg-wave-pattern min-h-screen flex flex-col">
		<div class="text-center pt-8 mb-32">
			<img
				class="mx-auto"
				src="@/assets/logo-white.svg"
				alt="BiddingLab Logo"
			>
		</div>
		<div class="flex-1">
			<div class="w-full max-w-[1152px] mx-auto">
				<div class="grid grid-cols-12">
					<div class="col-span-4">
						<transition
							name="zoom-in-long"
							mode="out-in"
							appear
						>
							<img
								src="@/assets/biddy-profile.png"
								class="mb-4"
								alt="404 request with Biddy"
							>
						</transition>
						<transition
							name="fade-up-long"
							mode="out-in"
							appear
						>
							<p class="text-5xl font-bold tracking-tight text-white mb-4">
								{{ $t('view.login.title.returning') }}
							</p>
						</transition>
						<transition
							name="fade-up-long"
							mode="out-in"
							appear
						>
							<p class="text-white">
								{{ $t('view.login.subtitle') }}
							</p>
						</transition>
					</div>
					<div class="col-span-5 col-start-8">
						<transition
							name="fade-up-long"
							mode="out-in"
							appear
						>
							<MoleculeOverlayBox>
								<div class="p-6">
									<form
										class="mb-6"
										@submit.prevent
									>
										<div class="space-y-6 mb-6">
											<MoleculeInputWithLabel
												v-model="form.email.value"
												:label="$t('input.label.username')"
												:placeholder="$t('input.placeholder.enterYourUsername')"
												:rules="{ required: true }"
												:show-error="false"
												type="text"
												mode="aggressive-touch"
											/>
											<MoleculeInputWithLabel
												v-model="form.password.value"
												:label="$t('input.label.password')"
												:placeholder="$t('input.placeholder.enterYourPassword')"
												:rules="{ required: true }"
												:show-error="false"
												type="password"
												mode="aggressive-touch"
											/>
										</div>
										<div class="flex items-center mb-6 text-sm">
											<AtomCheckbox
												v-model="form.saveToken.value"
												class="mr-2"
											/>
											<p
												class="cursor-pointer"
												@click="form.saveToken.value = !form.saveToken.value"
												@keydown.enter="form.saveToken.value = !form.saveToken.value"
											>
												{{ $t('checkbox.label.stayLoggedIn') }}
											</p>
										</div>
										<AtomButton
											class="w-full"
											:loading="waitingForAuthServer"
											@click="loginUser"
										>
											{{ $t('button.login') }}
										</AtomButton>
										<p
											v-show="formMessage"
											class="text-sm text-center text-grey-600 mt-2"
										>
											<span class="error">{{ formMessage }}</span>
										</p>
									</form>
									<p class="text-center text-sm">
										<i18n-t keypath="link.noAccountYet">
											<a
												class="text-purple-600 font-semibold"
												:href="BIDDINGLAB_HOME_URL"
											>
												{{ $t('link.getFreeTrial') }}
											</a>
										</i18n-t>
									</p>
								</div>
							</MoleculeOverlayBox>
						</transition>
					</div>
				</div>
			</div>
		</div>
		<div class="w-full max-w-[1152px] mx-auto pb-10">
			<div class="flex justify-between">
				<p class="text-white text-sm">
					{{ copyright }}
				</p>
				<div class="flex items-center space-x-6">
					<LanguageMenu />
					<a
						v-for="item in loginFooterNavigationItems"
						:key="item.label"
						:href="item.link[language]"
						class="text-white text-sm"
						target="_blank"
					>
						{{ $t(item.label) }}
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import {
	computed, defineComponent, nextTick, reactive, ref, watch,
} from 'vue';
import { validate } from 'vee-validate';
import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { loginFooterNavigationItems } from '@/utilities/Navigations';
import { BIDDINGLAB_HOME_URL } from '@/utilities/Constants';
import LanguageMenu from '@/components/_misc/LanguageMenu/LanguageMenu.vue';
import MoleculeInputWithLabel from '@/components/molecules/MoleculeInputWithLabel/MoleculeInputWithLabel.vue';
import AtomCheckbox from '@/components/atoms/AtomCheckbox/AtomCheckbox.vue';
import AtomButton from '@/components/atoms/AtomButton/AtomButton.vue';
import MoleculeOverlayBox from '@/components/molecules/MoleculeOverlayBox/MoleculeOverlayBox.vue';
import { useLanguageStore } from '@/store/language';

type LoginFormField = {
	value: string | boolean;
	rules?: string;
	isValid: boolean;
}

export default defineComponent({
	name: 'LoginView',

	components: {
		LanguageMenu,
		MoleculeInputWithLabel,
		AtomCheckbox,
		AtomButton,
		MoleculeOverlayBox,
	},

	setup() {
		const { language } = useLanguageStore();
		const store = useStore();
		const formMessage = ref('');

		// TODO: Refactor whole from / validation logic to new vee-validate useForm / useField
		const form = reactive<Record<string, LoginFormField>>({
			email: {
				value: '',
				rules: 'required|email',
				isValid: false,
			},
			password: {
				value: '',
				rules: 'required',
				isValid: false,
			},
			saveToken: {
				value: true,
				isValid: true,
			},
		});

		const isFormValid = computed(() => {
			const validProperties = Object.values(form).map((formField) => formField.isValid);
			const allPropertiesValid = validProperties.every(((property) => property === true));

			return allPropertiesValid;
		});

		const validateField = async (field: LoginFormField): Promise<boolean> => {
			if (!field.rules) {
				return Promise.resolve(true);
			}

			return validate(field.value, field.rules).then((result) => {
				if (result.errors.length > 0) {
					return false;
				}

				return true;
			});
		};

		const validateAllFields = () => {
			Object.values(form).forEach((field) => {
				validateField(field).then((isValid) => {
					field.isValid = isValid;
				});
			});
		};

		watch(form, () => {
			formMessage.value = '';
			validateAllFields();
		});

		const copyright = computed(() => `© ${DateTime.now().get('year')} - BiddingLab`);

		const waitingForAuthServer = ref(false);

		const router = useRouter();
		const { t } = useI18n();

		const loginUser = async () => {
			try {
				waitingForAuthServer.value = true;
				formMessage.value = '';

				await store.dispatch('auth/requestAuthentication', {
					userName: form.email.value,
					password: form.password.value,
					saveToken: form.saveToken.value,
				});

				router.push({ name: 'dashboard' });
			} catch (error) {
				formMessage.value = t('error.genericLoginError');
			} finally {
				await nextTick();
				waitingForAuthServer.value = false;
			}
		};

		return {
			copyright,
			form,
			isFormValid,
			formMessage,
			language,
			BIDDINGLAB_HOME_URL,
			loginFooterNavigationItems,
			waitingForAuthServer,
			loginUser,
		};
	},
});
</script>
