<template>
	<div
		:class="{
			'flex items-center': orientation === 'horizontal'
		}"
	>
		<AtomLabel
			:label="label"
			:size="labelSize"
			:class="computedLabelClass"
			:color-mode="colorMode"
			:required="required"
		/>
		<AtomInput
			v-bind="$attrs"
			class="flex-1"
			:class="inputClass"
			:color-mode="colorMode"
		/>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import AtomInput from '../../atoms/AtomInput/AtomInput.vue';
import AtomLabel, { LabelSize } from '../../atoms/AtomLabel/AtomLabel.vue';

export enum InputWithLabelOrientation {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal',
}

export enum InputWithLabelColorMode {
	LIGHT = 'light',
	DARK = 'dark',
}

export const labelSizeOrientationMap: Record<InputWithLabelOrientation, LabelSize> = {
	[InputWithLabelOrientation.VERTICAL]: LabelSize.SM,
	[InputWithLabelOrientation.HORIZONTAL]: LabelSize.MD,
};

export const labelClassOrientationClassMap: Record<InputWithLabelOrientation, String> = {
	[InputWithLabelOrientation.VERTICAL]: 'mb-2',
	[InputWithLabelOrientation.HORIZONTAL]: 'mr-6',
};

export default defineComponent({
	name: 'MoleculeInputWithLabel',

	components: {
		AtomInput,
		AtomLabel,
	},

	props: {
		label: {
			type: String,
			required: true,
		},
		orientation: {
			type: String as PropType<InputWithLabelOrientation>,
			default: InputWithLabelOrientation.VERTICAL,
		},
		colorMode: {
			type: String as PropType<InputWithLabelColorMode>,
			default: InputWithLabelColorMode.LIGHT,
		},
		required: {
			type: Boolean,
			default: false,
		},
		inputClass: {
			type: String,
			default: '',
		},
		labelClass: {
			type: String,
			default: '',
		},
	},

	setup(props) {
		const labelSize = computed(() => labelSizeOrientationMap[props.orientation]);
		const computedLabelClass = computed(() => [labelClassOrientationClassMap[props.orientation], props.labelClass]);

		return {
			labelSize,
			computedLabelClass,
		};
	},
});
</script>

<style>

</style>
