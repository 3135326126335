import { computed } from 'vue';
import { useLanguageStore } from '@/store/language';

export const useLanguageSelect = () => {
	const { availableLanguages, language, setLanguageByNativeName } = useLanguageStore();

	const languageOptions = computed(() => Object.values(availableLanguages.value).map((l) => l.nativeName));

	const selectedLanguage = computed({
		get() {
			return availableLanguages.value[language.value].nativeName;
		},
		set(value: string) {
			setLanguageByNativeName(value);
		},
	});

	return {
		languageOptions,
		selectedLanguage,
	};
};
