import {
	ref, watch, Ref, onMounted, onUnmounted,
} from 'vue';

export const useTypeAhead = (
	options: Ref<string[]>,
	selectedOptions: Ref<string | string[]>,
	select: (option: string) => void,
	selectable: Ref<boolean>,
) => {
	const typeAheadPointer = ref(-1);

	watch(options, () => {
		typeAheadPointer.value = 0;
	});

	const setTypeAheadPointer = () => {
		if (Array.isArray(selectedOptions.value)) {
			const firstValue = selectedOptions.value[0];
			const firstIndex = options.value.indexOf(firstValue);
			typeAheadPointer.value = firstIndex;
			return;
		}

		const index = options.value.indexOf(selectedOptions.value);
		typeAheadPointer.value = index;
	};

	const typeAheadUp = () => {
		if (typeAheadPointer.value >= options.value.length - 1) {
			typeAheadPointer.value = options.value.length - 1;
		} else {
			typeAheadPointer.value += 1;
		}
	};

	const typeAheadDown = () => {
		if (typeAheadPointer.value <= 0) {
			typeAheadPointer.value = 0;
		} else {
			typeAheadPointer.value -= 1;
		}
	};

	const selectOptionByIndex = (index: number) => {
		const option = options.value[index];

		if (option) {
			select(option);
		}
	};

	const onKeydown = (event: KeyboardEvent) => {
		if (!selectable.value) {
			return;
		}

		const mappings: Record<string, (e: KeyboardEvent) => void> = {
			ArrowUp: (e) => {
				e.preventDefault();
				return typeAheadDown();
			},
			ArrowDown: (e) => {
				e.preventDefault();
				return typeAheadUp();
			},
			Enter: () => selectOptionByIndex(typeAheadPointer.value),
		};

		const mapped = mappings[event.key];

		if (typeof mapped !== 'function') {
			return;
		}

		mapped(event);
	};

	onMounted(() => {
		setTypeAheadPointer();
		window.addEventListener('keydown', onKeydown);
	});

	onUnmounted(() => {
		window.removeEventListener('keydown', onKeydown);
	});

	return {
		typeAheadPointer,
		setTypeAheadPointer,
		typeAheadUp,
		typeAheadDown,
	};
};
