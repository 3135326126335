<template>
	<div class="bg-[#F6F5FA] min-h-full">
		<TheSidebar class="fixed z-10" />
		<div class="pl-[106px]">
			<TheHeader v-if="!hasCustomHeader" />
			<div
				:class="{
					'px-20 pt-12 pb-24': !hasCustomHeader
				}"
			>
				<slot />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import TheHeader from '@/components/TheHeader.vue';
import TheSidebar from '@/components/TheSidebar.vue';

export default defineComponent({
	name: 'LayoutDefault',

	components: {
		TheHeader,
		TheSidebar,
	},

	setup() {
		const route = useRoute();
		const hasCustomHeader = computed(() => route.meta.customHeader === true);

		return {
			hasCustomHeader,
		};
	},
});
</script>
