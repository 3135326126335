<template>
	<div class="flex h-full content-center">
		<LoadingBar
			:progress="appProgressLoader"
			class="absolute left-0 top-0 w-full z-[1001]"
			bar-classes="w-full transition-all ease-linear pointer-events-none transform-gpu scale-x-0 origin-left bg-purple-400 shadow-glow"
			:threshold-before-show="350"
		/>
		<div
			class="absolute inset-0 overflow-y-auto overflow-x-hidden"
			:class="{ 'shake': isShacking }"
			@animationend="isShacking = false"
		>
			<router-view v-slot="{ Component }">
				<transition
					name="fade"
					mode="out-in"
				>
					<LoadingScreen v-if="globalStore.showLoadingScreen" />
					<component
						:is="globalStore.viewLayoutComponent"
						v-else
					>
						<component :is="Component" />
					</component>
				</transition>
			</router-view>
			<div id="poppers" />
			<ModalsContainer />
		</div>
	</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import * as Sentry from '@sentry/browser';
import { defineComponent, watch } from 'vue';
import { useAppProgressLoader, LoadingBar } from '@sunlabde/vuelab';
import { useRouter } from 'vue-router';
import { ressourceClient } from '@/api/clients/ressourceClient';
import { useGlobalStore } from '@/store/global';
import { useShake } from '@/composables/useShake';
import { useUserStore } from './store/user';
import LoadingScreen from './components/LoadingScreen.vue';
import { useLanguageStore } from './store/language';

export default defineComponent({
	name: 'App',

	components: {
		LoadingBar,
		LoadingScreen,
	},

	setup() {
		const store = useStore();
		const globalStore = useGlobalStore();
		const appProgressLoader = useAppProgressLoader();
		const router = useRouter();
		const { currentUser } = useUserStore();
		const { initLanguage } = useLanguageStore();
		const { isShacking } = useShake();

		watch(currentUser, (user) => {
			Sentry.setUser(user ?? null);
		}, { immediate: true });

		initLanguage();

		ressourceClient.setConfig({
			hooks: {
				afterResponse: [
					(request, options, response) => {
						if (
							[401, 403].includes(response.status)
							&& !router.currentRoute.value.matched.some((route) => route.name === 'login')
						) {
							store.dispatch('auth/logoutUser');
							router.push('/');
						}
					},
				],
			},
		});

		return {
			globalStore,
			appProgressLoader,
			isShacking,
		};
	},
});
</script>

<style lang="scss">
@import "~vue-toastification/dist/index.css";
</style>
