import { CompareType } from '@/enums/CompareType';

const MUTATION_DATE_RANGE = '24H';
const BIDDINGLAB_HOME_URL = 'https://www.biddinglab.com/';
const DEFAULT_COMPARE_RANGE_TYPE: CompareType = CompareType.PERIOD;

export { MUTATION_DATE_RANGE, BIDDINGLAB_HOME_URL, DEFAULT_COMPARE_RANGE_TYPE };

// Use ISO 639-1 codes only
export const SUPPORTED_LOCALES = ['de', 'en'] as const;
