export enum CompareType {
	PERIOD = 'period',
	YEAR = 'year',
}

export const compareTypes: Record<CompareType, { type: CompareType; displayName: string }> = {
	[CompareType.PERIOD]: {
		type: CompareType.PERIOD,
		displayName: 'Previous Period',
	},
	[CompareType.YEAR]: {
		type: CompareType.YEAR,
		displayName: 'Previous Year',
	},
};
