import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoleculeSelectWithLabel = _resolveComponent("MoleculeSelectWithLabel")!

  return (_openBlock(), _createBlock(_component_MoleculeSelectWithLabel, {
    modelValue: _ctx.selectedLanguage,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
    options: _ctx.languageOptions,
    required: "",
    "color-mode": "dark",
    border: false,
    "same-width": false
  }, null, 8, ["modelValue", "options"]))
}