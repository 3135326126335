import upperFirst from 'lodash/upperFirst';
import { defineStore } from 'pinia';

export const useGlobalStore = defineStore({
	id: 'global',

	state() {
		return {
			viewLayout: 'blank',
			showLoadingScreen: false,
		};
	},

	actions: {
	},

	getters: {
		viewLayoutComponent(): string {
			return `Layout${upperFirst(this.viewLayout)}`;
		},
	},
});

export const useGlobalStoreHooks = () => ({
});
