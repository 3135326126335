<template>
	<transition name="fade">
		<div
			v-if="show"
			:class="{ 'flex items-center justify-center absolute inset-0 z-[100]': wrapper }"
		>
			<div
				:class="{ 'is-success': isSuccess }"
				class="relative mx-0 w-[40px] h-[40px]"
			>
				<svg
					class="circular-loader"
					viewBox="0 0 42 42"
				>
					<g class="loader-group">
						<circle
							class="loader-background"
							cx="21"
							cy="21"
							r="20"
							fill="none"
							stroke-width="2"
						/>
						<circle
							class="loader-path"
							cx="21"
							cy="21"
							r="20"
							fill="none"
							stroke-width="2"
						/>
						<path
							class="loader-check"
							d="M951,540.91l5.37,5.44L969,533.65"
							transform="translate(-939 -519)"
							fill="none"
						/>
					</g>
				</svg>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'LoaderCheckmark',

	props: {
		isSuccess: {
			type: Boolean,
			default: false,
		},
		wrapper: {
			type: Boolean,
			default: false,
		},
		backgroundColor: {
			type: String,
			default: '',
		},
		threshold: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			show: false,
			timeout: null,
		};
	},

	mounted() {
		this.timeout = setTimeout(() => {
			this.show = true;
		}, this.threshold);
	},

	beforeUnmount() {
		clearTimeout(this.timeout);
	},
};
</script>

<style scoped>
.is-success .loader-group {
	animation: none;
}

.is-success .loader-path {
	animation: path-fill cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.is-success .loader-background {
	animation: fill 0.1s ease-in-out 0.8s forwards;
}

.is-success .loader-check {
	@apply block;
}

.is-success .loader-logo {
	@apply opacity-0;
}

.loader-check {
	@apply hidden stroke-white stroke-2;
}

.loader-group {
	animation: rotate 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
}

.loader-path {
	stroke-dasharray: 150, 200;
	stroke-dashoffset: -10;
	animation: dash 1.5s ease-in-out infinite;
	stroke-linecap: round;
	@apply stroke-purple-500;
}

.loader-background {
	@apply stroke-grey-300;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}

.loader-check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes fill {
	100% {
		@apply fill-green-500 stroke-green-500;
	}
}

@keyframes path-fill {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	100% {
		stroke-dashoffset: 0;
		stroke-dasharray: 150, 200;
		@apply stroke-green-500;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}
</style>
