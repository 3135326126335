import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ae7c9f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative transition-all z-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomTabBarItem = _resolveComponent("AtomTabBarItem")!
  const _component_AtomDivider = _resolveComponent("AtomDivider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "scrollContainer",
      class: _normalizeClass(["w-full box-border overflow-x-auto overflow-y-hidden pb-[10px] flex z-10 relative", {
				'px-20': _ctx.hasDivider,
				'space-x-8': _ctx.size === 'md',
				'space-x-4': _ctx.size === 'sm'
			}]),
      onWheel: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onWheel && _ctx.onWheel(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limitedItems, (item) => {
          return (_openBlock(), _createBlock(_component_AtomTabBarItem, {
            key: item,
            "model-value": _ctx.isSelected(item),
            size: _ctx.size,
            "onUpdate:modelValue": ($event: any) => (_ctx.onSelectedItem($event, item))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item), 1)
            ]),
            _: 2
          }, 1032, ["model-value", "size", "onUpdate:modelValue"]))
        }), 128))
      ], true)
    ], 34),
    (_ctx.hasDivider)
      ? (_openBlock(), _createBlock(_component_AtomDivider, {
          key: 0,
          "color-mode": "light",
          size: "sm",
          class: "absolute left-0 right-0 z-0 bottom-[10px]"
        }))
      : _createCommentVNode("", true)
  ]))
}