export enum IconBadgeSize {
	SM = 'sm',
	MD = 'md',
}

export enum IconBadgeColor {
	PURPLE = 'purple',
	BLUE = 'blue',
	MAGENTA = 'magenta',
	CYAN = 'cyan',
	RED = 'red',
	GREEN = 'green',
	ORANGE = 'orange',
	VIOLET = 'violet',
	GREY = 'grey',
}

export interface IconBadge {
	size?: IconBadgeSize;
	color?: IconBadgeColor;
	icon?: string;
}
