<template>
	<div class="bg-purple-500 w-[106px] h-screen flex flex-col">
		<div class="p-4 flex items-center justify-center mb-2">
			<img
				src="@/assets/logo-icon.svg"
				alt="BiddingLab Bildmarke"
			>
		</div>
		<div class="flex-1 px-2 space-y-0.5">
			<RouterLink
				v-for="item in navigation"
				:key="item.label"
				v-slot="{ navigate, isActive }"
				:to="item.link"
				custom
			>
				<AtomSidenavItem
					:label="item.label"
					:icon="item.icon"
					:active="isActive"
					class="w-full"
					@click="navigate"
				/>
			</RouterLink>
		</div>
		<div>
			<slot />
			<div
				v-if="version"
				class="p-6 text-center text-xs opacity-50 text-white font-semibold"
			>
				{{ version }}
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { RouterLink } from 'vue-router';
import { NavigationItem } from '@/models/NavigationItem';
import AtomSidenavItem from '../../atoms/AtomSidenavItem/AtomSidenavItem.vue';

export default defineComponent({
	name: 'OrganismSidebar',

	components: {
		RouterLink,
		AtomSidenavItem,
	},

	props: {
		navigation: {
			type: Array as PropType<NavigationItem[]>,
			required: true,
		},
		version: {
			type: String,
			default: '',
		},
	},

	setup() {

	},
});
</script>
