export enum BiddingMode {
	AUTO = 'Auto',
	ROAS = 'Roas',
	CPA = 'Cpa',
	MAX_CONVERSIONS = 'MaxConversions',
	MAX_CONVERSION_VALUE = 'MaxConversionValue',
	MAX_CLICKS = 'MaxClicks',
	MAX_IMPRESSIONS = 'MaxImpressions',
}

export const biddingModeSortOrder: BiddingMode[] = [
	BiddingMode.AUTO,
	BiddingMode.ROAS,
	BiddingMode.CPA,
	BiddingMode.MAX_CONVERSIONS,
	BiddingMode.MAX_CONVERSION_VALUE,
	BiddingMode.MAX_CLICKS,
	BiddingMode.MAX_IMPRESSIONS,
];

export interface BiddingModeDescription {
	id: BiddingMode;
	label: string;
	isTargetValueRequired: boolean;
}

export const biddingModeDictionary: Record<BiddingMode, BiddingModeDescription> = {
	[BiddingMode.AUTO]: {
		id: BiddingMode.AUTO,
		label: 'Parent',
		isTargetValueRequired: false,
	},
	[BiddingMode.ROAS]: {
		id: BiddingMode.ROAS,
		label: 'ROAS',
		isTargetValueRequired: true,
	},
	[BiddingMode.CPA]: {
		id: BiddingMode.CPA,
		label: 'CPA',
		isTargetValueRequired: true,
	},
	[BiddingMode.MAX_CONVERSIONS]: {
		id: BiddingMode.MAX_CONVERSIONS,
		label: 'MAX Conversion',
		isTargetValueRequired: false,
	},
	[BiddingMode.MAX_CONVERSION_VALUE]: {
		id: BiddingMode.MAX_CONVERSION_VALUE,
		label: 'MAX Conversion Value',
		isTargetValueRequired: false,
	},
	[BiddingMode.MAX_CLICKS]: {
		id: BiddingMode.MAX_CLICKS,
		label: 'MAX Click',
		isTargetValueRequired: false,
	},
	[BiddingMode.MAX_IMPRESSIONS]: {
		id: BiddingMode.MAX_IMPRESSIONS,
		label: 'MAX Impression',
		isTargetValueRequired: false,
	},
};

export const sortBiddingModeDictionary = <T extends BiddingModeDescription[]>(dictionary: T): T => dictionary
	.sort((descriptionA, descriptionB) => {
		const indexA = biddingModeSortOrder.indexOf(descriptionA.id);
		const indexB = biddingModeSortOrder.indexOf(descriptionB.id);

		if (indexA > indexB) {
			return 1;
		}

		if (indexA < indexB) {
			return -1;
		}
		return 0;
	});

export const getBiddingModeLabel = (biddingMode: BiddingMode) => biddingModeDictionary[biddingMode].label;
