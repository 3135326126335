import numeral from 'numeral';
import { DateString } from '@/types/DateString';
import { BiddingMode, BiddingModeDescription, biddingModeDictionary } from './BiddingMode';
import { ServiceType } from './ServiceType';
import { StrategyLearningStage, StrategyLearningStageDescription, strategyLearningStageDescription } from './StrategyLearningStage';

/* eslint-disable camelcase */
export interface Strategy {
	LearningStage: Nullable<StrategyLearningStage>;
	CurrentStageLearningProgress: number;
	TotalLearningProgress: number;
	CurrentStageLearningDaysRemaining: number;
	TotalLearningDaysRemaining: number;
	Forecast: number;
	BudgetRecommendation: number;
	MinCpc: number | null;
	MaxCpc: number | null;
	BiddingMode: BiddingMode | null;
	Target: number | null;
	FeedUrl: string | null;
	Id: number;
	ProviderLink_Id: number;
	Name: string;
	FirstExecution: DateString | null;
	State: string;
	ServiceType: ServiceType;
	ParentStrategyLink_Id: number | null;
	StartDate: DateString | null;
}

export const isStrategyActive = (strategy: Strategy): boolean => strategy.State === 'Active';

export const isStrategyInactive = (strategy: Strategy): boolean => strategy.State === 'Inactive';

export const hasStrategyStarted = (strategy: Strategy): boolean => typeof strategy.StartDate === 'string';

export const isTopLevelStrategy = (strategy: Strategy): boolean => strategy.ParentStrategyLink_Id === null;

export const getFormattedTargetValue = ({ Target, BiddingMode: StrategyBiddingMode, ParentStrategyLink_Id }: Strategy): string => {
	if (!Target) {
		return '';
	}

	if (StrategyBiddingMode === 'Auto' && ParentStrategyLink_Id) {
		return '';
	}

	if (StrategyBiddingMode === 'Auto' || StrategyBiddingMode === 'Roas') {
		return `${numeral(Target * 100).format('(0,0)')}%`;
	}

	return Target.toString();
};

export const getBiddingModeDescription = (strategy: Strategy): BiddingModeDescription => biddingModeDictionary[strategy.BiddingMode || BiddingMode.AUTO];

export const getAvailableTargets = (strategy: Strategy): BiddingModeDescription[] => Object.values(biddingModeDictionary)
	.filter((biddingModeDescription) => {
		if (biddingModeDescription.id === BiddingMode.AUTO && !strategy.ParentStrategyLink_Id) {
			return false;
		}

		return true;
	});

export const getLearningStageDescription = (strategy: Strategy): StrategyLearningStageDescription => {
	if (!strategy.LearningStage) {
		return strategyLearningStageDescription.Initial;
	}

	return strategyLearningStageDescription[strategy.LearningStage];
};

export const createEmptyStrategy = (): Strategy => ({
	BiddingMode: null,
	BudgetRecommendation: 0,
	CurrentStageLearningDaysRemaining: 0,
	CurrentStageLearningProgress: 0,
	FeedUrl: '',
	FirstExecution: '',
	Forecast: 0,
	Id: 0,
	LearningStage: null,
	MaxCpc: null,
	MinCpc: null,
	Name: '',
	ParentStrategyLink_Id: 0,
	ProviderLink_Id: 0,
	ServiceType: 'GoogleDisplay',
	StartDate: '',
	State: '',
	Target: null,
	TotalLearningDaysRemaining: 0,
	TotalLearningProgress: 0,
});
