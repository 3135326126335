<template>
	<div>
		<AtomProgressBar
			class="mb-2"
			:progress-value="learningProgress"
			:full-value="100"
		/>
		<div class="flex justify-between">
			<p class="text-white text-sm opacity-75">
				{{ description }}
			</p>
			<p class="text-white text-sm opacity-75">
				{{ learningProgress }}%
			</p>
		</div>
	</div>
</template>

<script lang="ts">
import {
	defineComponent, PropType, toRef, computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import AtomProgressBar from '@/components/atoms/AtomProgressBar/AtomProgressBar.vue';
import { useStrategyLearningProgress } from '@/composables/useStrategyLearningProgress';
import { getLearningStageDescription, Strategy } from '@/models/Strategy';
import { localize } from '@/utilities/Helpers';

export default defineComponent({
	name: 'LearningProgress',

	components: {
		AtomProgressBar,
	},

	props: {
		strategy: {
			type: Object as PropType<Strategy | null>,
			default: null,
		},
	},

	setup(props) {
		const { t } = useI18n();
		const { learningProgress, totalLearningWeeksRemaining, strategy } = useStrategyLearningProgress(toRef(props, 'strategy'));

		const description = computed(() => {
			if (!strategy.value) {
				return t('progress.durationDescription.accountInactive');
			}

			return localize(getLearningStageDescription(strategy.value).displayName);
		});

		return {
			learningProgress,
			totalLearningWeeksRemaining,
			description,
		};
	},
});
</script>
