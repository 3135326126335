import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganismUpdatesList = _resolveComponent("OrganismUpdatesList")!

  return (_openBlock(), _createBlock(_component_OrganismUpdatesList, {
    updateCategory: _ctx.selectedFilter,
    "onUpdate:updateCategory": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilter) = $event)),
    updates: _ctx.dataAsUpdateTime,
    "changes-count": _ctx.totalUpdatesInRange,
    loading: _ctx.requestedNewFetch
  }, null, 8, ["updateCategory", "updates", "changes-count", "loading"]))
}