import { DateTime } from 'luxon';
import { InsightType } from '@/enums/InsightType';
import { orderByDate } from '@/utilities/Helpers';
import { DateOrISO } from '@/utilities/Types';
import { ComparableDateRange } from './ComparableDateRange';
import { ProviderInsights } from './ProviderInsights';

export type DatasetDailyValue = {
	date: Date;
	value: number;
};

export class InsightDataset {
	dailyValues: DatasetDailyValue[];
	dateRange: ComparableDateRange;
	alltimeValue: number;

	constructor(dailyValues: DatasetDailyValue[], startDate: DateOrISO, endDate: DateOrISO, alltimeValue: number) {
		this.alltimeValue = alltimeValue;
		this.dateRange = new ComparableDateRange(startDate, endDate);
		this.dailyValues = orderByDate(dailyValues, 'date', 'desc');
	}

	get startDate(): Date {
		return this.dateRange.startDate;
	}

	get endDate(): Date {
		return this.dateRange.endDate;
	}

	get days(): number {
		const serverDateRange = this.dateRange.clampToServerTime();
		const diff = DateTime.fromJSDate(serverDateRange.endDate).diff(DateTime.fromJSDate(serverDateRange.startDate), 'days');
		return Math.round(diff.days) + 1;
	}

	/**
	 * Returns this.dailyValues but fills every date with no data with an empty dailyValue
	 */
	get completeDailyValues(): DatasetDailyValue[] {
		const completedDailyValues: DatasetDailyValue[] = [];

		for (let i = 0; i < this.days; i++) {
			const day = DateTime.fromJSDate(this.startDate).plus({ days: i });
			const dayISO = day.toISODate();
			const matchedDailyValue = this.dailyValues.find((dailyValue) => DateTime.fromJSDate(dailyValue.date).toISODate() === dayISO);

			if (matchedDailyValue) {
				completedDailyValues.push(matchedDailyValue);
			} else {
				completedDailyValues.push({
					date: day.toJSDate(),
					value: 0,
				});
			}
		}

		return completedDailyValues;
	}

	getValueAverage() {
		return this.dailyValues.reduce((a, b) => a + b.value, 0) / this.dailyValues.length;
	}

	static fromProviderInsights(providerInsights: ProviderInsights, type: InsightType): InsightDataset {
		const dailyValues: DatasetDailyValue[] = providerInsights.daily.map((insights) => ({
			date: new Date(insights.InsightDate),
			value: insights[type],
		}));

		const alltimeValue = providerInsights.alltime[type];

		return new InsightDataset(dailyValues, providerInsights.startDate, providerInsights.endDate, alltimeValue);
	}

	static fromProviderInsightsArray(providerInsightsArray: ProviderInsights[], type: InsightType): InsightDataset[] {
		return providerInsightsArray.map((providerInsights) => InsightDataset.fromProviderInsights(providerInsights, type));
	}
}
