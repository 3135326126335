<template>
	<div
		class="flex items-center py-2 px-3 rounded-md shadow-lg"
		:class="wrapperClass"
	>
		<AtomIcon
			:icon="icon"
			size="sm"
			:class="iconClass"
			class="mr-2"
		/>
		<div class="text-sm">
			<slot>
				{{ message }}
			</slot>
		</div>
		<AtomLink
			v-if="!hideClose"
			icon="close"
			size="sm"
			class="ml-auto"
			color="grey"
			:to="onCloseClick"
		/>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import AtomIcon from '../../atoms/AtomIcon/AtomIcon.vue';
import AtomLink from '../../atoms/AtomLink/AtomLink.vue';

export enum FlashMessageType {
	POSITIVE = 'positive',
	NEGATIVE = 'negative',
	NEUTRAL = 'neutral',
	WARNING = 'warning',
	INFORMATION = 'information'
}

const typeMap: Record<FlashMessageType, { icon: string, wrapperClass: string, iconClass: string }> = {
	[FlashMessageType.POSITIVE]: {
		icon: 'check-circle',
		wrapperClass: 'bg-green-300',
		iconClass: 'text-green-500',
	},
	[FlashMessageType.NEGATIVE]: {
		icon: 'exclamation-circle',
		wrapperClass: 'bg-red-300',
		iconClass: 'text-red-500',
	},
	[FlashMessageType.NEUTRAL]: {
		icon: 'bulb-circle',
		wrapperClass: 'bg-grey-300',
		iconClass: 'text-grey-500',
	},
	[FlashMessageType.WARNING]: {
		icon: 'exclamation-triangle',
		wrapperClass: 'bg-orange-300',
		iconClass: 'text-orange-500',
	},
	[FlashMessageType.INFORMATION]: {
		icon: 'info',
		wrapperClass: 'bg-purple-300',
		iconClass: 'text-purple-500',
	},
};

export default defineComponent({
	name: 'MoleculeFlashMessage',

	components: {
		AtomIcon,
		AtomLink,
	},

	props: {
		type: {
			type: String as PropType<FlashMessageType>,
			default: FlashMessageType.INFORMATION,
		},
		hideClose: {
			type: Boolean,
			default: false,
		},
		message: {
			type: String,
			default: '',
		},
	},

	emits: [
		'close',
	],

	setup(props, { emit }) {
		const icon = computed(() => typeMap[props.type].icon);
		const wrapperClass = computed(() => typeMap[props.type].wrapperClass);
		const iconClass = computed(() => typeMap[props.type].iconClass);

		const onCloseClick = () => {
			emit('close');
		};

		return {
			icon,
			wrapperClass,
			iconClass,
			onCloseClick,
		};
	},
});
</script>

<style>

</style>
