<template>
	<MoleculeOverlayBox>
		<div class="flex">
			<div class="mr-6">
				<div class="flex h-full space-x-3">
					<AtomComparativeBar
						hue="300"
						:percentage-height="getPercentageForRange(range1)"
					/>
					<AtomComparativeBar
						:percentage-height="getPercentageForRange(range2)"
					/>
				</div>
			</div>
			<div class="flex-1 border-r border-grey-300 pr-4 mr-4">
				<div class="flex items-center mb-2">
					<AtomDot
						hue="300"
						class="mr-2"
					/>
					<p class="text-sm font-semibold">
						{{ range1.start.toFormat('dd.LL.yyyy') }} - {{ range1.end.toFormat('dd.LL.yyyy') }}
					</p>
				</div>
				<div class="flex space-x-4">
					<div>
						<p class="text-xs">
							{{ $t('graphLabel.total') }}
						</p>
						<p class="font-semibold">
							<span v-if="range1.totalValue">
								{{ getFormattedValue(range1.totalValue) }}
							</span>
							<span v-else>
								-
							</span>
						</p>
					</div>
					<div v-if="range1.dailyAverageValue">
						<p class="text-xs">
							{{ $t('graphLabel.dailyAverage') }}
						</p>
						<p class="font-semibold">
							{{ getFormattedValue(range1.dailyAverageValue) }}
						</p>
					</div>
				</div>
			</div>
			<div class="flex-1">
				<div class="flex items-center mb-2">
					<AtomDot
						class="mr-2"
					/>
					<p class="text-sm font-semibold">
						{{ range2.start.toFormat('dd.LL.yyyy') }} - {{ range2.end.toFormat('dd.LL.yyyy') }}
					</p>
				</div>
				<div class="flex space-x-4">
					<div>
						<p class="text-xs">
							{{ $t('graphLabel.total') }}
						</p>
						<p class="font-semibold">
							<span v-if="range2.totalValue">
								{{ getFormattedValue(range2.totalValue) }}
							</span>
							<span v-else>
								-
							</span>
						</p>
					</div>
					<div v-if="range2.dailyAverageValue">
						<p class="text-xs">
							{{ $t('graphLabel.dailyAverage') }}
						</p>
						<p class="font-semibold">
							{{ getFormattedValue(range2.dailyAverageValue) }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</MoleculeOverlayBox>
</template>

<script lang="ts">
import { DateTime } from 'luxon';
import { computed, defineComponent, PropType } from 'vue';
import MoleculeOverlayBox from '../../molecules/MoleculeOverlayBox/MoleculeOverlayBox.vue';
import AtomDot from '../../atoms/AtomDot/AtomDot.vue';
import AtomComparativeBar from '../../atoms/AtomComparativeBar/AtomComparativeBar.vue';

export interface CompareDateRange {
	start: DateTime;
	end: DateTime;
	totalValue?: number;
	dailyAverageValue?: number;
}

export default defineComponent({
	name: 'OrganismOverlayCompare',

	components: {
		AtomDot,
		AtomComparativeBar,
		MoleculeOverlayBox,
	},

	props: {
		range1: {
			type: Object as PropType<CompareDateRange>,
			required: true,
		},
		range2: {
			type: Object as PropType<CompareDateRange>,
			required: true,
		},
		formatter: {
			type: Function as PropType<(value: number) => string>,
			default: null,
		},
	},

	setup(props) {
		const highestValue = computed(() => {
			if (props.range1.totalValue && props.range2.totalValue) {
				return props.range1.totalValue > props.range2.totalValue ? props.range1.totalValue : props.range2.totalValue;
			}

			return props.range1.totalValue || props.range2.totalValue || 0;
		});

		const getPercentageForRange = (range: CompareDateRange) => {
			if (highestValue.value !== 0 && range.totalValue) {
				const percent = 100 / highestValue.value;

				return percent * range.totalValue;
			}

			return 0;
		};

		const getFactorForRange = (range: CompareDateRange) => getPercentageForRange(range) / 100;

		const getFormattedValue = (value: number): string => {
			if (typeof props.formatter === 'function') {
				return props.formatter(value);
			}

			return `${value}`;
		};

		return {
			getPercentageForRange,
			getFactorForRange,
			getFormattedValue,
		};
	},
});
</script>
