import {
	computed, Ref, reactive, toRef, watch, WatchStopHandle,
} from 'vue';
import { Strategy } from '@/models/Strategy';
import { useTopLevelStrategies } from '@/composables/useTopLevelStrategies';
import { DEFAULT_SELECTED_INSIGHT_TYPES, InsightType } from '@/enums/InsightType';

export interface StrategyState {
	strategy: Strategy | null;
	providerId: number | null;
	providerIdWatcher: WatchStopHandle | null;
	selectedInsights: InsightType[];
}

export const useStrategyStore = (providerId: number | null) => {
	const state = reactive<StrategyState>({
		strategy: null,
		providerId,
		providerIdWatcher: null,
		selectedInsights: DEFAULT_SELECTED_INSIGHT_TYPES,
	});

	const { strategies, activeStrategies } = useTopLevelStrategies({ providerId: toRef(state, 'providerId') });

	const selectedStrategyName = computed({
		get() {
			if (state.strategy) {
				return state.strategy.Name;
			}

			return '';
		},
		set(strategyName) {
			const strategy = strategies.value.find((s) => s.Name === strategyName);

			if (strategy) {
				state.strategy = strategy;
			} else {
				state.strategy = null;
			}
		},
	});

	const topLevelStrategies = computed(() => strategies.value);
	const topLevelStrategiesOptions = computed(() => activeStrategies.value);
	const selectedStrategy = computed<Strategy | null>(() => state.strategy);
	const selectedStrategyId = computed(() => selectedStrategy.value?.Id || null);

	const useProviderId = (providerIdRef: Ref<number | null>) => {
		if (state.providerIdWatcher) {
			state.providerIdWatcher();
		}

		state.providerIdWatcher = watch(providerIdRef, (newProviderId) => {
			if (newProviderId) {
				state.providerId = newProviderId;
				state.strategy = null;
			}
		});
	};

	return {
		selectedInsights: toRef(state, 'selectedInsights'),
		selectedStrategyName,
		topLevelStrategies,
		topLevelStrategiesOptions,
		selectedStrategy,
		selectedStrategyId,
		useProviderId,
	};
};
