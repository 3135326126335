import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["readonly", "disabled", "aria-label"]
const _hoisted_3 = {
  key: 0,
  class: "text-grey-500 text-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([
					_ctx.colorModeClass,
					'flex items-center outline-0 rounded-md text-sm cursor-pointer group transition',
					{
						colorModeDisabledClass: _ctx.isDisabled,
						colorModeActiveClass: _ctx.isActive,
						'cursor-not-allowed': _ctx.isDisabled,
						'border px-4 w-full': _ctx.border,
						'!bg-transparent': !_ctx.border,
						'shadow-sm': _ctx.isActive && _ctx.border,
						'focus:shadow-sm': _ctx.border,
						'h-12': _ctx.size === 'md' && _ctx.border,
						'h-9': _ctx.size === 'sm' && _ctx.border,
						'!border-red-500 !border-opacity-100': _ctx.isInvalid,
					}
				]),
        onMousedown: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onMousedown && _ctx.onMousedown(...args)), ["prevent"]))
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_AtomIcon, {
              key: 0,
              icon: _ctx.icon,
              size: "sm",
              class: _normalizeClass(["mr-2", _ctx.colorModeIconClass])
            }, null, 8, ["icon", "class"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["relative overflow-hidden overflow-ellipsis", {
						'pr-8 w-full': _ctx.border,
					}])
        }, [
          (_ctx.computedValue)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["whitespace-nowrap overflow-ellipsis w-full", {
							'opacity-0': _ctx.searchValue,
						}])
              }, _toDisplayString(_ctx.computedValue), 3))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: _normalizeClass(_ctx.searchValue ? 'opacity-0' : 'opacity-50')
              }, _toDisplayString(_ctx.placeholder), 3)),
          _withDirectives(_createElementVNode("input", {
            ref: "searchField",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
            type: "text",
            class: _normalizeClass(["absolute bg-transparent outline-none right-8 left-0", {
							'pointer-events-none': !_ctx.searchable,
							'cursor-not-allowed': _ctx.isDisabled,
						}]),
            readonly: !_ctx.searchable,
            disabled: _ctx.disabled,
            "aria-label": _ctx.placeholder,
            onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeydown && _ctx.onKeydown(...args))),
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSearchFocus && _ctx.onSearchFocus(...args))),
            onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSearchBlur && _ctx.onSearchBlur(...args)))
          }, null, 42, _hoisted_2), [
            [_vModelText, _ctx.searchValue]
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["h-full flex flex-col items-center justify-center overflow-hidden", {
						'absolute right-2 top-0': _ctx.border,
						'ml-2 mt-0.5': !_ctx.border,
					}])
        }, [
          _createElementVNode("button", {
            class: _normalizeClass(["block", _ctx.colorModeNumberArrowClass]),
            tabindex: "-1"
          }, [
            _createVNode(_component_AtomIcon, {
              class: _normalizeClass(["transform group-focus:-scale-y-100 transition", {
								'-scale-y-100': _ctx.isActive,
							}]),
              icon: "chevron-down"
            }, null, 8, ["class"])
          ], 2)
        ], 2)
      ], 34)
    ]),
    (_ctx.note)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.note), 1))
      : _createCommentVNode("", true),
    (_ctx.errorMessage && _ctx.isInvalid)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["text-red-500 text-sm mt-1", {
				'text-right': _ctx.border,
			}])
        }, _toDisplayString(_ctx.errorMessage), 3))
      : _createCommentVNode("", true)
  ]))
}