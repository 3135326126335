import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, Teleport as _Teleport, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "wrapperRef",
  class: "flex-1 relative"
}
const _hoisted_2 = {
  ref: "menuRef",
  class: "z-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomLabel = _resolveComponent("AtomLabel")!
  const _component_AtomSelect = _resolveComponent("AtomSelect")!
  const _component_AtomDropdownMenu = _resolveComponent("AtomDropdownMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
			'flex items-center': _ctx.orientation === 'horizontal'
		})
  }, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_AtomLabel, {
          key: 0,
          label: _ctx.label,
          size: _ctx.labelSize,
          class: _normalizeClass(_ctx.labelClass),
          "color-mode": _ctx.colorMode,
          required: _ctx.required
        }, null, 8, ["label", "size", "class", "color-mode", "required"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AtomSelect, _mergeProps({
        ref: "selectRef",
        search: _ctx.searchValue,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
      }, _ctx.$attrs, {
        value: _ctx.computedValue,
        class: "flex-1",
        "color-mode": _ctx.colorMode,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDropdownMenu = true)),
        onBlur: _ctx.onSelectBlur
      }), null, 16, ["search", "value", "color-mode", "onBlur"]),
      (_openBlock(), _createBlock(_Teleport, { to: "#poppers" }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_Transition, { name: "fade-up-long" }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_AtomDropdownMenu, {
                modelValue: _ctx.computedValue,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedValue) = $event)),
                  _ctx.onSelect
                ],
                selectable: _ctx.showDropdownMenu,
                search: _ctx.searchValue,
                options: _ctx.options,
                multiselect: _ctx.multiselect,
                required: _ctx.required,
                class: "max-h-96"
              }, null, 8, ["modelValue", "selectable", "search", "options", "multiselect", "required", "onUpdate:modelValue"]), [
                [_vShow, _ctx.showDropdownMenu]
              ])
            ]),
            _: 1
          })
        ], 512)
      ]))
    ], 512)
  ], 2))
}