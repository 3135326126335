import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_AtomLink = _resolveComponent("AtomLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center py-2 px-3 rounded-md shadow-lg", _ctx.wrapperClass])
  }, [
    _createVNode(_component_AtomIcon, {
      icon: _ctx.icon,
      size: "sm",
      class: _normalizeClass([_ctx.iconClass, "mr-2"])
    }, null, 8, ["icon", "class"]),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.message), 1)
      ])
    ]),
    (!_ctx.hideClose)
      ? (_openBlock(), _createBlock(_component_AtomLink, {
          key: 0,
          icon: "close",
          size: "sm",
          class: "ml-auto",
          color: "grey",
          to: _ctx.onCloseClick
        }, null, 8, ["to"]))
      : _createCommentVNode("", true)
  ], 2))
}