import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "overlay-backdrop-blur shadow-lg border border-grey-300 rounded-lg min-w-[360px] max-w-[1100px]" }
const _hoisted_2 = {
  key: 0,
  class: "px-6 pt-6 relative flex"
}
const _hoisted_3 = {
  key: 0,
  class: "text-2xl font-bold tracking-tight"
}
const _hoisted_4 = {
  key: 1,
  class: "border-t border-grey-300 p-6 flex justify-end space-x-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomButton = _resolveComponent("AtomButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({ 'p-6' : _ctx.bodyPadding })
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (_ctx.actions.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, $index) => {
            return (_openBlock(), _createBlock(_component_AtomButton, _mergeProps({ key: $index }, action, { class: "w-40" }), null, 16))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}