import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icomoon = _resolveComponent("Icomoon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_component_Icomoon, {
      key: _ctx.icon,
      name: "icon",
      "icon-set": _ctx.iconSet,
      icon: _ctx.icon,
      size: _ctx.computedSize
    }, null, 8, ["icon-set", "icon", "size"]))
  ]))
}