import { InsightType } from '@/enums/InsightType';

export type InsightVisibilitySettings = Record<InsightType, boolean>;

export const DEFAULT_INSIGHT_VISIBILITY_SETTINGS: InsightVisibilitySettings = {
	[InsightType.IMPRESSIONS]: true,
	[InsightType.CLICKS]: true,
	[InsightType.CTR]: true,
	[InsightType.CPC]: true,
	[InsightType.COST]: true,
	[InsightType.CONVERSIONS]: true,
	[InsightType.COST_PER_CONVERSION]: true,
	[InsightType.ROAS]: true,
	[InsightType.CONVERSION_VALUE]: true,
};

export const getSavedInsightVisibilitySettings = (): InsightVisibilitySettings => ({ ...DEFAULT_INSIGHT_VISIBILITY_SETTINGS, ...JSON.parse(localStorage.getItem('insightsVisibilitySettings') || '{}') });

export const saveInsightVisibilitySettings = (settings: InsightVisibilitySettings) => {
	const mergedSettings = { ...DEFAULT_INSIGHT_VISIBILITY_SETTINGS, ...settings };
	localStorage.setItem('insightsVisibilitySettings', JSON.stringify(mergedSettings));
};
