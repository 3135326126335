import { DateTime } from 'luxon';
import { CompareType } from '@/enums/CompareType';
import { DateOrISO } from '@/utilities/Types';
import { DateRange } from './DateRange';

export class ComparableDateRange {
	_startDate: DateTime;
	_endDate: DateTime;

	constructor(startDate: DateOrISO, endDate: DateOrISO) {
		this._startDate = typeof startDate === 'string' ? DateTime.fromISO(startDate) : DateTime.fromJSDate(startDate);
		this._endDate = typeof endDate === 'string' ? DateTime.fromISO(endDate) : DateTime.fromJSDate(endDate);

		if (this._startDate > this._endDate) {
			this._startDate = this._endDate;
			this._endDate = typeof startDate === 'string' ? DateTime.fromISO(startDate) : DateTime.fromJSDate(startDate);
		}
	}

	get diffInDays(): number {
		return this._endDate.diff(this._startDate, 'days').days + 1;
	}

	get startDate() {
		return this._startDate.toJSDate();
	}

	get endDate() {
		return this._endDate.toJSDate();
	}

	toDateArray(): Date[] {
		const currentDate = new Date(this._startDate.toJSDate());
		const days: Date[] = [];

		while (currentDate.getTime() < this._endDate.toMillis()) {
			days.push(new Date(currentDate));
			currentDate.setDate(currentDate.getDate() + 1);
		}

		days.push(new Date(this._endDate.toJSDate()));

		return days;
	}

	clampToServerTime(): ComparableDateRange {
		const startDateObject = this._startDate;
		const expectedServerStartDate = DateTime.utc(startDateObject.year, startDateObject.month, startDateObject.day, 0, 0, 0, 0);
		let serverStartDate = DateTime.fromISO(expectedServerStartDate.toISO() ?? 'No valid date').toUTC();

		if (expectedServerStartDate < this._startDate) {
			serverStartDate = serverStartDate.plus({ days: 1 });
		}

		const endDateObject = this._endDate;
		const expectedServerEndDate = DateTime.utc(endDateObject.year, endDateObject.month, endDateObject.day, 0, 0, 0, 0);
		let serverEndDate = DateTime.fromISO(expectedServerEndDate.toISO() ?? 'No valid date').toUTC();

		if (expectedServerStartDate > this._endDate) {
			serverEndDate = serverEndDate.minus({ days: 1 });
		}

		return ComparableDateRange.fromDateTime(serverStartDate, serverEndDate);
	}

	toPreviousPeriod(): ComparableDateRange {
		const startDate = this._startDate.minus({ days: this.diffInDays }).toJSDate();
		const endDate = this._startDate.minus({ days: 1 }).toJSDate();

		return new ComparableDateRange(startDate, endDate);
	}

	toPreviousYear(): ComparableDateRange {
		const startDate = this._startDate.minus({ years: 1 }).toJSDate();
		const endDate = this._endDate.minus({ years: 1 }).toJSDate();

		return new ComparableDateRange(startDate, endDate);
	}

	toCompareType(compareType: CompareType): ComparableDateRange {
		switch (compareType) {
			case CompareType.YEAR:
				return this.toPreviousYear();
			default:
				return this.toPreviousPeriod();
		}
	}

	toFormat(format = 'dd.LL.yy') {
		return `${this._startDate.toFormat(format)} - ${this._endDate.toFormat(format)}`;
	}

	static fromDateTime(startDate: DateTime, endDate: DateTime) {
		return new ComparableDateRange(startDate.toISO() ?? 'No valid date', endDate.toISO() ?? 'No valid date');
	}
}

export const toDateRange = (input: ComparableDateRange | DateRange): DateRange => {
	if (input instanceof ComparableDateRange) {
		return {
			start: input._startDate,
			end: input._endDate,
		};
	}

	return input;
};
