export interface User {
	Id: number;
	UserName: string;
}

export const getInitialsFromUser = (user: User): string => {
	const beforeAtSign = user.UserName.split('@')[0];
	const splittedAtDot = beforeAtSign.split('.');

	if (splittedAtDot.length > 1) {
		return `${splittedAtDot[0][0]}${splittedAtDot[1][0]}`;
	}

	return `${splittedAtDot[0][0]}${splittedAtDot[0][1]}`;
};

// TODO: Replace with real implementation
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getAccountTypeFromUser = (user: User): string => 'Merchant account';
