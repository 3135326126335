<template>
	<div
		class="w-2 h-2 rounded-full"
		:class="classObject"
	/>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export enum DotColor {
	PURPLE = 'purple',
	GREY = 'grey',
	BLUE = 'blue',
	MAGENTA = 'magenta',
}

export enum DotHue {
	LIGHT = '300',
	DARK = '500',
}

const colorClassMap: Record<DotColor, Record<DotHue, String>> = {
	[DotColor.PURPLE]: {
		[DotHue.LIGHT]: 'bg-purple-300',
		[DotHue.DARK]: 'bg-purple-500',
	},
	[DotColor.GREY]: {
		[DotHue.LIGHT]: 'bg-grey-300',
		[DotHue.DARK]: 'bg-grey-500',
	},
	[DotColor.BLUE]: {
		[DotHue.LIGHT]: 'bg-blue-300',
		[DotHue.DARK]: 'bg-blue-500',
	},
	[DotColor.MAGENTA]: {
		[DotHue.LIGHT]: 'bg-magenta-300',
		[DotHue.DARK]: 'bg-magenta-500',
	},
};

export default defineComponent({
	name: 'AtomDot',

	props: {
		color: {
			type: String as PropType<DotColor>,
			default: DotColor.PURPLE,
		},
		hue: {
			type: String as PropType<DotHue>,
			default: DotHue.DARK,
		},
	},

	setup(props) {
		const colorClass = computed(() => colorClassMap[props.color][props.hue]);

		const classObject = computed(() => [
			colorClass.value,
		]);

		return {
			classObject,
		};
	},
});
</script>

<style scoped>

</style>
