<template>
	<div
		class="overlay-backdrop-blur shadow-lg rounded-lg grid relative"
		:class="{
			'grid-cols-5': renderedInsights.length > 8,
			'grid-cols-4': [7, 8].includes(renderedInsights.length),
			'grid-cols-3': [6].includes(renderedInsights.length),
			'grid-flow-col auto-cols-fr': renderedInsights.length <= 5
		}"
	>
		<OrganismKPICard
			v-for="(kpi, $index) in renderedInsights"
			:key="kpi.key"
			:kpi="kpi"
			class="cursor-pointer border-purple-200"
			:class="{
				'rounded-tl-lg': $index === 0,
				'rounded-tr-lg': isLastOfFirstRow($index),
				'rounded-bl-lg': isFirstOfLastRow($index),
				'rounded-br-lg': isLastOfLastRow($index),
				'border-r': !isLastOfRow($index),
				'border-b': !isInLastRow($index),
			}"
			:active="isSelected(kpi)"
			@click="onSelectKpi(kpi)"
		/>
		<LoaderCheckmark
			v-if="isLoading"
			:wrapper="true"
			class="rounded-lg bg-white/80"
		/>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { Insights } from '@/models/Insights';
import { Insight } from '@/models/Insight';
import { InsightType } from '@/enums/InsightType';
import LoaderCheckmark from '@/components/LoaderCheckmark.vue';
import OrganismKPICard from '../OrganismKPICard/OrganismKPICard.vue';

export default defineComponent({
	name: 'OrganismKPIList',

	components: {
		OrganismKPICard,
		LoaderCheckmark,
	},

	props: {
		kpis: {
			type: Object as PropType<Insight[]>,
			required: true,
		},
		selectedKpis: {
			type: Array as PropType<InsightType[]>,
			required: true,
		},
		visibilitySettings: {
			type: Object as PropType<Record<InsightType, boolean>>,
			required: false,
			default: null,
		},
		isLoading: {
			type: Boolean,
			required: true,
		},
	},

	emits: [
		'update:selectedKpis',
	],

	setup(props, { emit }) {
		const sortedInsights = computed(() => {
			if (props.kpis.length > 0) {
				return Insights.sortInsightsByDefaultOrder(props.kpis);
			}

			return Insights.createEmpty().insightsByKey;
		});

		const renderedInsights = computed(() => {
			if (props.visibilitySettings) {
				return sortedInsights.value.filter((insight) => !!props.visibilitySettings[insight.key]);
			}

			return sortedInsights.value;
		});

		const columnsCount = computed(() => {
			if (renderedInsights.value.length > 8) {
				return 5;
			}

			if ([7, 8].includes(renderedInsights.value.length)) {
				return 4;
			}

			if ([6].includes(renderedInsights.value.length)) {
				return 3;
			}

			return renderedInsights.value.length;
		});

		const rowsCount = computed(() => Math.ceil(renderedInsights.value.length / columnsCount.value));

		const isLastOfRow = (index: number): boolean => (index + 1) / rowsCount.value === columnsCount.value;

		const isInLastRow = (index: number): boolean => index + 1 > columnsCount.value * (rowsCount.value - 1);

		const isLastOfFirstRow = (index: number): boolean => index + 1 === columnsCount.value;

		const isFirstOfLastRow = (index: number): boolean => ((columnsCount.value * rowsCount.value) - columnsCount.value + 1) === index + 1;

		const isLastOfLastRow = (index: number): boolean => index + 1 === columnsCount.value * rowsCount.value;

		const onSelectKpi = (kpi: Insight) => {
			if (props.selectedKpis.includes(kpi.key)) {
				if (props.selectedKpis.length === 1) {
					return;
				}

				const index = props.selectedKpis.findIndex((selectedKpi) => selectedKpi === kpi.key);
				const selectedKpisCopy = [...props.selectedKpis];

				selectedKpisCopy.splice(index, 1);
				emit('update:selectedKpis', selectedKpisCopy);
				return;
			}

			emit('update:selectedKpis', [...props.selectedKpis, kpi.key]);
		};

		const isSelected = (kpi: Insight) => props.selectedKpis.includes(kpi.key);

		return {
			renderedInsights,
			isLastOfRow,
			isInLastRow,
			isLastOfFirstRow,
			isFirstOfLastRow,
			isLastOfLastRow,
			onSelectKpi,
			isSelected,
		};
	},
});
</script>
