import { Navigation } from '@/models/NavigationItem';
import i18n from '@/plugins/i18n';

export const firstLevelNavigation: Navigation = [
	{ label: i18n.global.t('navigation.overview'), link: { name: 'dashboard' }, icon: 'dashboard' },
	{ label: i18n.global.t('navigation.strategiesOverview'), link: { name: 'strategiesOverview' }, icon: 'strategy-alt' },
	{ label: i18n.global.t('navigation.auditlog'), link: { name: 'auditlog' }, icon: 'change-history' },
	{ label: i18n.global.t('navigation.management'), link: { name: 'management' }, icon: 'cog' },
];

export const managementNavigation: Navigation = [
	{ label: i18n.global.t('navigation.accountSettings'), link: { name: 'accountSettings' }, icon: 'cog' },
	{ label: i18n.global.t('navigation.dashboardCustomization'), link: { name: 'dashboardCustomization' }, icon: 'cogs' },
];

export const userMenuNavigationItems: Navigation = [
	{ label: 'Settings', link: { name: 'accountSettings' }, icon: 'cog' },
	{ label: 'Logout', link: { name: 'logout' }, icon: 'sign-out' },
];

export const topbarNavigationItems: Navigation = [
	{
		label: 'navigation.faq',
		link: {
			de: 'https://www.biddinglab.com/de/faq/',
			en: 'https://www.biddinglab.com/en/faqs/',
		},
	},
];

export const loginFooterNavigationItems: Navigation = [
	{
		label: 'navigation.imprint',
		link: {
			de: 'https://www.biddinglab.com/de/impressum/',
			en: 'https://www.biddinglab.com/en/imprint/',
		},
	},
	{
		label: 'navigation.contact',
		link: {
			de: 'https://www.biddinglab.com/de/kontakt/',
			en: 'https://www.biddinglab.com/en/contact/',
		},
	},
];
