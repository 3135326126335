import { RouteRecordRaw } from 'vue-router';
import Login from '@/views/Login.vue';
import PasswordRequestReset from '@/views/PasswordRequestReset.vue';
import NotFound from '@/views/NotFound.vue';
import { store } from '@/store';
import i18n from '@/plugins/i18n';
import overview from './routes/overview';
import management from './routes/management';

const routes: RouteRecordRaw[] = [
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			title: () => 'Login',
			layout: 'blank',
			requiresGuest: true,
		},
	},
	{
		path: '/logout',
		name: 'logout',
		component: Login,
		meta: {
			requiresAuth: true,
		},
		beforeEnter: async (to, from, next) => {
			await store.dispatch('auth/logoutUser');
			next({ name: 'login' });
		},
	},
	{
		path: '/forgot-password',
		name: 'forgotPassword',
		component: PasswordRequestReset,
		meta: {
			title: () => 'Forgot password',
			layout: 'blank',
			requiresGuest: true,
		},
	},
	{
		path: '/auditlog',
		name: 'auditlog',
		component: () => import(/* webpackChunkName: "auditlog" */ '@/views/Auditlog.vue'),
		meta: {
			title: () => i18n.global.t('widget.auditlog.title'),
			subheadline: 'All your changes will appear here',
			icon: 'change-history',
			requiresAuth: true,
		},
	},
	...overview,
	...management,
	{
		path: '',
		redirect: { name: 'dashboard' },
	},
	{
		path: '/:pathMatch(.*)*',
		component: NotFound,
		meta: {
			title: '404 - Not found',
			customHeader: true,
		},
	},
];

export default routes;
