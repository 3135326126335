import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganismHero = _resolveComponent("OrganismHero")!

  return (_openBlock(), _createBlock(_component_OrganismHero, {
    provider: _ctx.selectedProviderName,
    "onUpdate:provider": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProviderName) = $event)),
    user: _ctx.currentUser,
    providers: _ctx.providerOptions,
    logout: _ctx.onLogout,
    headline: _ctx.viewTitle,
    subheadline: _ctx.viewSubtitle,
    icon: _ctx.viewIcon,
    compact: ""
  }, null, 8, ["provider", "user", "providers", "logout", "headline", "subheadline", "icon"]))
}