<template>
	<button
		v-if="isActionLink"
		v-bind="{
			...linkDefaultBindings,
			...$attrs,
		}"
		:class="linkDefaultBindings.class"
		@click="to"
	>
		<AtomIcon
			v-if="icon"
			v-bind="iconDefaultBindings"
		/>
		<slot>{{ label }}</slot>
	</button>
	<a
		v-else-if="isExternalLink"
		v-bind="{
			...$attrs,
			...linkDefaultBindings,
		}"
		:class="linkDefaultBindings.class"
		:href="to"
		target="_blank"
		@click="onExternalLinkClick"
	>
		<AtomIcon
			v-if="icon"
			v-bind="iconDefaultBindings"
		/>
		<slot>{{ label }}</slot>
	</a>
	<RouterLink
		v-else
		v-slot="{ href, navigate }"
		v-bind="$props"
		custom
	>
		<a
			v-bind="{
				...linkDefaultBindings,
				...$attrs,
			}"
			:class="linkDefaultBindings.class"
			:href="href"
			:to="to"
			@click="navigate"
		>
			<AtomIcon
				v-if="icon"
				v-bind="iconDefaultBindings"
			/>
			<slot>{{ label }}</slot>
		</a>
	</RouterLink>
</template>
<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { RouterLink } from 'vue-router';
import {
	LinkColor, LinkSize, LinkIconPosition, LinkLocation,
} from './AtomLinkModel';
import AtomIcon from '../AtomIcon/AtomIcon.vue';

const colorClassMap: Record<LinkColor, string> = {
	[LinkColor.PURPLE]: 'text-purple-500 decoration-purple-600 hover:text-purple-600 hover:underline focus:bg-purple-300',
	[LinkColor.GREY]: 'text-grey-500 decoration-grey-600 hover:text-grey-600 hover:underline focus:bg-grey-300',
	[LinkColor.GREEN]: 'text-green-500 decoration-green-600 hover:text-green-600 hover:underline focus:bg-green-300',
	[LinkColor.RED]: 'text-red-500 decoration-red-600 hover:text-red-600 hover:underline focus:bg-red-300',
	[LinkColor.WHITE]: 'text-white decoration-white hover:underline focus:bg-white focus:bg-opacity-10',
};

const sizeClassMap: Record<LinkSize, String> = {
	[LinkSize.MD]: 'text-base py-[2px] px-1',
	[LinkSize.SM]: 'text-sm py-[2px] px-1',
};

export default defineComponent({
	name: 'AtomLink',

	components: {
		RouterLink,
		AtomIcon,
	},

	props: {
		color: {
			type: String as PropType<LinkColor>,
			default: LinkColor.PURPLE,
		},
		label: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String as PropType<LinkSize>,
			default: LinkSize.MD,
		},
		icon: {
			type: String,
			default: '',
		},
		iconPosition: {
			type: String as PropType<LinkIconPosition>,
			default: 'left',
		},
		to: {
			type: [String, Object, Function] as PropType<LinkLocation>,
			required: true,
		},
	},

	setup(props) {
		const colorClass = computed(() => colorClassMap[props.color]);
		const sizeClass = computed(() => sizeClassMap[props.size]);

		const isDisabled = computed(() => props.disabled);

		const isExternalLink = computed(() => typeof props.to === 'string' && props.to.startsWith('http'));
		const isActionLink = computed(() => typeof props.to === 'function');

		const onExternalLinkClick = (event: InputEvent) => {
			if (isDisabled.value) {
				event.preventDefault();
			}
		};

		const classObject = computed(() => [
			sizeClass.value,
			colorClass.value,
			'inline-flex items-center transform z-10 relative outline-0 rounded-md font-semibold',
			{
				'cursor-not-allowed !opacity-50 !no-underline focus:!bg-transparent': isDisabled.value,
				'flex-row-reverse': props.iconPosition === 'right',
			},
		]);

		const linkDefaultBindings = computed(() => ({
			class: classObject.value,
			disabled: isDisabled.value,
		}));

		const iconDefaultBindings = computed(() => ({
			icon: props.icon,
			size: 'sm',
			class: {
				'mr-2': props.iconPosition === 'left' && props.label.length > 0,
				'ml-2': props.iconPosition === 'right' && props.label.length > 0,
			},
		}));

		return {
			onExternalLinkClick,

			isDisabled,

			isExternalLink,
			isActionLink,

			classObject,
			linkDefaultBindings,
			iconDefaultBindings,
		};
	},
});
</script>
