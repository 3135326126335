<template>
	<button
		v-ripple
		:class="classObject"
		v-bind="$attrs"
		class="text-base font-semibold relative flex items-center justify-center box-border rounded-md font-sans transition-all focus:outline focus:outline-1 outline-offset-2 disabled:cursor-not-allowed"
		:disabled="isDisabled"
		@click="onClick"
	>
		<span
			v-if="isLoading"
			class="icon-wrapper flex items-center justify-center absolute inset-0 z-10"
		>
			<slot name="loadingText">
				<div
					class="loader loader-4"
					:class="loaderColorClass"
				/>
			</slot>
		</span>

		<span
			:class="{
				'opacity-0 translate-y-4': isLoading,
				'flex-row-reverse': iconPosition === 'right',
			}"
			class="transition flex items-center transform z-10 relative"
		>
			<AtomIcon
				v-if="icon"
				:icon="icon"
				size="sm"
				:class="{
					'mr-2': iconPosition === 'left' && label.length > 0,
					'ml-2': iconPosition === 'right' && label.length > 0,
				}"
			/>
			<slot name="default">{{ label }}</slot>
		</span>
	</button>
</template>
<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { Ripple } from '@/directives/RippleDirective';
import AtomIcon from '../AtomIcon/AtomIcon.vue';

export enum ButtonSize {
	SM = 'sm',
	MD = 'md',
}

export enum ButtonWidth {
	FIXED = 'fixed',
	SM = 'sm',
	MD = 'md',
	LG = 'lg',
}

export enum ButtonIconPosition {
	LEFT = 'left',
	RIGHT = 'right',
}

export enum ButtonColor {
	PURPLE = 'purple',
	PURPLE_BORDER = 'purple-border',
	GREEN = 'green',
	RED = 'red',
	WHITE_BORDER = 'white-border',
}

export const colorClassMap: Record<ButtonColor, String> = {
	[ButtonColor.PURPLE]: 'outline-purple-500 bg-purple-500 text-white hover:bg-purple-600 disabled:bg-purple-300 disabled:text-grey-500',
	[ButtonColor.PURPLE_BORDER]: 'outline-purple-500 border text-purple-500 border-purple-500 hover:border-purple-600 hover:bg-purple-600 hover:text-white disabled:border-purple-300 disabled:text-grey-500',
	[ButtonColor.GREEN]: 'outline-green-500 bg-green-500 text-white hover:bg-green-600 disabled:bg-purple-300 disabled:text-grey-500',
	[ButtonColor.RED]: 'outline-red-500 bg-red-500 text-white hover:bg-red-600 disabled:bg-purple-300 disabled:text-grey-500',
	[ButtonColor.WHITE_BORDER]: 'outline-white border text-white border-white hover:border-purple-600 hover:bg-purple-600 hover:text-white disabled:border-purple-300 disabled:text-grey-500',
};

const loaderColorClassMap: Record<ButtonColor, String> = {
	[ButtonColor.PURPLE]: 'loader-white',
	[ButtonColor.PURPLE_BORDER]: 'loader-purple-500',
	[ButtonColor.GREEN]: 'loader-white',
	[ButtonColor.RED]: 'loader-white',
	[ButtonColor.WHITE_BORDER]: 'loader-white',
};

const sizeClassMap: Record<ButtonSize, String> = {
	[ButtonSize.SM]: 'h-9',
	[ButtonSize.MD]: 'h-12',
};

const widthClassMap: Record<ButtonWidth, String> = {
	[ButtonWidth.FIXED]: '',
	[ButtonWidth.SM]: 'px-4',
	[ButtonWidth.MD]: 'px-6',
	[ButtonWidth.LG]: 'px-8',
};

export default defineComponent({
	name: 'AtomButton',

	components: {
		AtomIcon,
	},

	directives: {
		Ripple,
	},

	props: {
		color: {
			type: String as PropType<ButtonColor>,
			default: ButtonColor.PURPLE,
		},
		size: {
			type: String as PropType<ButtonSize>,
			default: ButtonSize.MD,
		},
		width: {
			type: String as PropType<ButtonWidth>,
			default: ButtonWidth.MD,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		iconPosition: {
			type: String as PropType<ButtonIconPosition>,
			default: 'left',
		},
	},

	emits: [
		'click',
	],

	setup(props, { emit }) {
		const isLoading = computed(() => props.loading);
		const isDisabled = computed(() => props.disabled);

		const colorClass = computed(() => colorClassMap[props.color]);
		const sizeClass = computed(() => sizeClassMap[props.size]);
		const widthClass = computed(() => widthClassMap[props.width]);
		const loaderColorClass = computed(() => loaderColorClassMap[props.color]);

		const classObject = computed(() => [
			{
				'button-disabled': isDisabled.value,
			},
			sizeClass.value,
			widthClass.value,
			colorClass.value,
		]);

		const onClick = ($event: Event) => {
			if (!isDisabled.value && !isLoading.value) {
				emit('click', $event);
			}
		};

		return {
			classObject,
			loaderColorClass,
			isLoading,
			isDisabled,
			onClick,
		};
	},
});
</script>
