import { fillUpDateArray } from '@/utilities/Helpers';
import { ComparableDateRange } from './ComparableDateRange';

export interface ProviderInsightsRaw {
	AverageCpc: number;
	Clicks: number;
	ConversionValue: number;
	Conversions: number;
	Cost: number;
	CostPerConversion: number;
	Ctr: number;
	Impressions: number;
	InsightDate: string;
	// eslint-disable-next-line camelcase
	ProviderLink_Id: string;
	Roas: number;
}

export const createEmptyProviderInsightsByDate = (date: Date): ProviderInsightsRaw => ({
	InsightDate: date.toISOString(),
	ProviderLink_Id: '',
	Clicks: 0,
	Impressions: 0,
	Conversions: 0,
	ConversionValue: 0,
	Cost: 0,
	CostPerConversion: 0,
	Roas: 0,
	AverageCpc: 0,
	Ctr: 0,
});

export class ProviderInsights {
	startDate: Date;
	endDate: Date;
	alltime: ProviderInsightsRaw;
	daily: ProviderInsightsRaw[];

	constructor(startDate: Date, endDate: Date, alltime: ProviderInsightsRaw, daily: ProviderInsightsRaw[]) {
		this.startDate = startDate;
		this.endDate = endDate;
		this.alltime = alltime;
		this.daily = daily;
	}

	hasData() {
		return this.daily.length > 0;
	}

	static createEmptyFromDate(startDate: Date, endDate: Date): ProviderInsights {
		const dateRange = new ComparableDateRange(startDate, endDate).clampToServerTime();
		const providerInsightsRawAlltime: ProviderInsightsRaw = createEmptyProviderInsightsByDate(dateRange.startDate);
		const emptyProviderInsightsRawDaily = fillUpDateArray(dateRange, [], 'InsightDate', createEmptyProviderInsightsByDate);

		return new ProviderInsights(dateRange.startDate, dateRange.endDate, providerInsightsRawAlltime, emptyProviderInsightsRawDaily);
	}

	static createEmtpy(): ProviderInsights {
		const startDate = new Date();
		const endDate = new Date();

		startDate.setDate(endDate.getDate() - 1);

		return ProviderInsights.createEmptyFromDate(startDate, endDate);
	}
}
