<template>
	<div>
		<div class="relative">
			<div
				:class="[
					colorModeClass,
					'flex items-center outline-0 rounded-md text-sm cursor-pointer group transition',
					{
						colorModeDisabledClass: isDisabled,
						colorModeActiveClass: isActive,
						'cursor-not-allowed': isDisabled,
						'border px-4 w-full': border,
						'!bg-transparent': !border,
						'shadow-sm': isActive && border,
						'focus:shadow-sm': border,
						'h-12': size === 'md' && border,
						'h-9': size === 'sm' && border,
						'!border-red-500 !border-opacity-100': isInvalid,
					}
				]"
				@mousedown.prevent="onMousedown"
			>
				<AtomIcon
					v-if="icon"
					:icon="icon"
					size="sm"
					class="mr-2"
					:class="colorModeIconClass"
				/>
				<div
					class="relative overflow-hidden overflow-ellipsis"
					:class="{
						'pr-8 w-full': border,
					}"
				>
					<span
						v-if="computedValue"
						class="whitespace-nowrap overflow-ellipsis w-full"
						:class="{
							'opacity-0': searchValue,
						}"
					>
						{{ computedValue }}
					</span>
					<span
						v-else
						:class="searchValue ? 'opacity-0' : 'opacity-50'"
					>
						{{ placeholder }}
					</span>
					<input
						ref="searchField"
						v-model="searchValue"
						type="text"
						class="absolute bg-transparent outline-none right-8 left-0"
						:class="{
							'pointer-events-none': !searchable,
							'cursor-not-allowed': isDisabled,
						}"
						:readonly="!searchable"
						:disabled="disabled"
						:aria-label="placeholder"
						@keydown="onKeydown"
						@focus="onSearchFocus"
						@blur="onSearchBlur"
					>
				</div>
				<div
					class="h-full flex flex-col items-center justify-center overflow-hidden"
					:class="{
						'absolute right-2 top-0': border,
						'ml-2 mt-0.5': !border,
					}"
				>
					<button
						class="block"
						tabindex="-1"
						:class="colorModeNumberArrowClass"
					>
						<AtomIcon
							class="transform group-focus:-scale-y-100 transition"
							:class="{
								'-scale-y-100': isActive,
							}"
							icon="chevron-down"
						/>
					</button>
				</div>
			</div>
		</div>
		<div
			v-if="note"
			class="text-grey-500 text-sm mt-1"
		>
			{{ note }}
		</div>
		<div
			v-if="errorMessage && isInvalid"
			class="text-red-500 text-sm mt-1"
			:class="{
				'text-right': border,
			}"
		>
			{{ errorMessage }}
		</div>
	</div>
</template>

<script lang="ts">
import {
	computed, defineComponent, PropType, ref, watch,
} from 'vue';
import AtomIcon from '../AtomIcon/AtomIcon.vue';

export enum InputSize {
	SM = 'sm',
	MD = 'md',
}

export enum InputColorMode {
	LIGHT = 'light',
	DARK = 'dark',
}

const colorModeClassMap: Record<InputColorMode, String> = {
	[InputColorMode.LIGHT]: 'border-purple-300 bg-white hover:bg-purple-100',
	[InputColorMode.DARK]: 'text-white border-opacity-50 border-white bg-transparent hover:bg-white hover:bg-opacity-10',
};

const colorModeActiveClassMap: Record<InputColorMode, String> = {
	[InputColorMode.LIGHT]: '!border-purple-600 bg-white',
	[InputColorMode.DARK]: '!border-opacity-100',
};

const colorModeDisabledClassMap: Record<InputColorMode, String> = {
	[InputColorMode.LIGHT]: '!bg-purple-200',
	[InputColorMode.DARK]: '!border-opacity-25',
};

const colorModeIconClassMap: Record<InputColorMode, String> = {
	[InputColorMode.LIGHT]: 'text-purple-500',
	[InputColorMode.DARK]: 'text-white',
};

const colorModeNumberArrowClassMap: Record<InputColorMode, String> = {
	[InputColorMode.LIGHT]: 'text-grey-500 hover:text-grey-600',
	[InputColorMode.DARK]: 'text-grey-500 hover:text-white',
};

export default defineComponent({
	name: 'AtomSelect',

	components: {
		AtomIcon,
	},

	props: {
		value: {
			type: [String, Array, Number] as PropType<string | number | string[] | number[]>,
			default: '',
		},
		size: {
			type: String as PropType<InputSize>,
			default: InputSize.MD,
		},
		colorMode: {
			type: String as PropType<InputColorMode>,
			default: InputColorMode.LIGHT,
		},
		placeholder: {
			type: String,
			default: '',
		},
		validationProps: {
			type: Object,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		note: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		searchable: {
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		border: {
			type: Boolean,
			default: true,
		},
		icon: {
			type: String,
			default: '',
		},
	},

	emits: [
		'update:search',
		'blur',
		'focus',
		'submit',
	],

	setup(props, { emit }) {
		const colorModeClass = computed(() => colorModeClassMap[props.colorMode]);
		const colorModeActiveClass = computed(() => colorModeActiveClassMap[props.colorMode]);
		const colorModeDisabledClass = computed(() => colorModeDisabledClassMap[props.colorMode]);
		const colorModeIconClass = computed(() => colorModeIconClassMap[props.colorMode]);
		const colorModeNumberArrowClass = computed(() => colorModeNumberArrowClassMap[props.colorMode]);

		const searchValue = ref(props.search);
		const searchField = ref<HTMLInputElement>();

		const isSearchFocused = ref(false);

		const isActive = computed(() => isSearchFocused.value);

		watch(searchValue, (newValue) => {
			emit('update:search', newValue);
		});

		watch(() => props.search, (newValue) => {
			searchValue.value = newValue;
		});

		const computedValue = computed(() => {
			if (Array.isArray(props.value)) {
				return props.value.join(', ');
			}

			return props.value;
		});

		const isValid = computed(() => {
			if (props.validationProps) {
				return props.validationProps.valid;
			}

			return false;
		});

		const isInvalid = computed(() => {
			if (props.validationProps && props.validationProps.validated && props.validationProps.touched) {
				return !props.validationProps.valid;
			}

			return false;
		});

		const isDisabled = computed(() => props.disabled);

		const onMousedown = () => {
			if (isDisabled.value) {
				return;
			}

			searchField.value?.focus();
		};

		const onSearchFocus = () => {
			if (isDisabled.value) {
				return;
			}

			emit('focus');
			isSearchFocused.value = true;
		};

		const onSearchBlur = (event: InputEvent) => {
			emit('blur', event);
			isSearchFocused.value = false;
		};

		const blur = () => {
			if (searchField.value) {
				searchField.value.blur();
			}
		};

		const onKeydown = (event: KeyboardEvent) => {
			if (!isActive.value) {
				return;
			}

			const mappings: Record<string, (e: KeyboardEvent) => void> = {
				Escape: (e) => {
					e.preventDefault();
					return blur();
				},
			};

			const mapped = mappings[event.key];

			if (typeof mapped !== 'function') {
				return;
			}

			mapped(event);
		};

		return {
			colorModeClass,
			colorModeActiveClass,
			colorModeDisabledClass,
			colorModeIconClass,
			colorModeNumberArrowClass,

			isActive,
			isSearchFocused,

			searchField,

			computedValue,
			searchValue,

			isValid,
			isInvalid,
			isDisabled,

			onKeydown,
			onMousedown,
			onSearchFocus,
			onSearchBlur,

			blur,
		};
	},
});
</script>
