import {
	ComponentInternalInstance, getCurrentInstance, Ref, unref,
} from 'vue';
import { asArray, removeFromArray } from '@/utilities/Helpers';
import { MaybeRef } from '@/utilities/Types';

export type SelectOption = string | number | { id: string; label: string };

const isPrimitiveOption = (option: SelectOption): option is string | number => ['string', 'number'].includes(typeof option);

export const useSelect = (
	value: Ref<SelectOption | SelectOption[]>,
	multiselect: MaybeRef<boolean>,
	required: MaybeRef<boolean>,
) => {
	const { emit } = getCurrentInstance() as ComponentInternalInstance;

	const getOptionKey = (option: SelectOption): string | number => {
		if (isPrimitiveOption(option)) {
			return option;
		}

		return option.id;
	};

	const getOptionLabel = (option: SelectOption): string | number => {
		if (isPrimitiveOption(option)) {
			return option;
		}

		return option.label;
	};

	const isSelected = (option: SelectOption) => {
		if (Array.isArray(value.value)) {
			return !!value.value.find((v) => v === getOptionKey(option));
		}

		return getOptionKey(option) === value.value;
	};

	const onSelectOption = (select: boolean, option: SelectOption) => {
		if (unref(multiselect)) {
			const newModelValue = asArray(value.value);

			if (select) {
				emit('update:modelValue', [...newModelValue, getOptionKey(option)]);
				return;
			}

			emit('update:modelValue', removeFromArray(newModelValue, getOptionKey(option)));
			return;
		}

		if (select) {
			emit('update:modelValue', getOptionKey(option));
			return;
		}

		if (unref(required)) {
			return;
		}

		emit('update:modelValue', '');
	};

	const toggleOption = (option: SelectOption) => {
		const isOptionSelected = isSelected(option);
		onSelectOption(!isOptionSelected, option);
	};

	return {
		getOptionKey,
		getOptionLabel,
		isSelected,
		onSelectOption,
		toggleOption,
	};
};
