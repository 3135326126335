import { APIClient } from '@/api/clients';
import { ressourceClient } from '@/api/clients/ressourceClient';
import { setLastStrategyUpdate } from '@/utilities/lastUpdate';

const getDefaultState = () => ({
	strategies: [],
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_STRATEGIES(state, strategies) {
			state.strategies = strategies;
		},
		UPDATE_STRATEGIES(state, strategies) {
			strategies.forEach((strategy) => {
				const index = state.strategies.findIndex((stateStrategy) => stateStrategy.Id === strategy.Id);

				if (index >= 0) {
					state.strategies[index] = strategy;
				} else {
					state.strategies.push(strategy);
				}
			});
		},
	},
	actions: {
		resetStrategyState({ commit }) {
			commit('RESET_STATE');
		},

		async fetchStrategies({ commit, rootGetters }, params) {
			const response = await APIClient.useWithLoaderToJson(ressourceClient.client.get('strategies', {
				searchParams: {
					ProviderLink_Id: rootGetters['provider/selectedProvider'].Id,
					...params,
				},
			}));

			commit('UPDATE_STRATEGIES', response.Objects);

			return response.Objects;
		},

		async fetchStrategy({ commit }, { strategyId, params }) {
			const response = await APIClient.useWithLoaderToJson(ressourceClient.client.get(`strategies/${strategyId}`, {
				params,
			}));

			commit('UPDATE_STRATEGIES', response.Objects);

			return response.Objects[0];
		},

		async getStrategyById({ state, dispatch }, strategyId) {
			const strategy = state.strategies.find((s) => s.Id === strategyId);

			if (strategy) {
				return strategy;
			}

			return dispatch('fetchStrategy', { strategyId });
		},

		async updateStrategy(context, { strategyId, options }) {
			const response = await APIClient.useWithLoaderToJson(ressourceClient.client.post(`strategies/${strategyId}`, {
				json: options,
			}));

			setLastStrategyUpdate();

			return response;
		},
	},
	getters: {
		strategies: (state) => state.strategies,
	},
};
