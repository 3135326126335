<template>
	<MoleculeOverlayBox
		:body-padding="false"
		class="w-[994px]"
	>
		<div class="grid grid-cols-5 divide-x divide-grey-300">
			<div class="col-span-3 p-6">
				<div class="flex items-center mb-6 h-6">
					<AtomDot class="mr-2" />
					<p class="text-xl font-semibold">
						{{ $t('label.dateRange') }}
					</p>
				</div>
				<div class="flex flex-wrap gap-3 mb-9">
					<ChipList
						v-model="selectedPredefinedRange"
						:options="predefinedRangesOptions"
					>
						<div class="flex items-center space-x-2">
							<AtomInput
								size="sm"
								class="w-28"
								:model-value="getDateTime(originalDateRange.start).toFormat('dd.LL.yyyy')"
								@change="onChangeOriginalDateRange($event, 'start')"
							/>
							<span>-</span>
							<AtomInput
								size="sm"
								class="w-28"
								:model-value="getDateTime(originalDateRange.end).toFormat('dd.LL.yyyy')"
								@change="onChangeOriginalDateRange($event, 'end')"
							/>
						</div>
					</ChipList>
				</div>
				<DatePicker
					ref="originalDatePicker"
					v-model="originalDateRange"
					is-range
					:masks="masks"
					:max-date="MAX_DATE"
					:max-page="MAX_PAGE"
					:columns="2"
					color="purple"
					class="vc-date"
					is-expanded
				/>
			</div>
			<div class="col-span-2 p-6">
				<div class="flex items-center mb-6 h-6">
					<AtomDot
						class="mr-2"
						hue="300"
					/>
					<p class="font-semibold text-sm">
						{{ $t('label.compareTo') }}
					</p>
				</div>
				<div class="flex flex-wrap gap-3 mb-9">
					<ChipList
						v-model="compareRangeOption"
						:options="compareRangeOptions"
					>
						<div class="flex items-center space-x-2">
							<AtomInput
								size="sm"
								class="w-28"
								:model-value="getDateTime(compareDateRange.start).toFormat('dd.LL.yyyy')"
								@change="onChangeCompareDateRange($event, 'start')"
							/>
							<span>-</span>
							<AtomInput
								size="sm"
								class="w-28"
								:model-value="getDateTime(compareDateRange.end).toFormat('dd.LL.yyyy')"
								@change="onChangeCompareDateRange($event, 'end')"
							/>
						</div>
					</ChipList>
				</div>
				<div class="w-[262px]">
					<DatePicker
						ref="compareDatePicker"
						v-model="compareDateRange"
						is-range
						:columns="1"
						color="purple"
						:masks="masks"
						:max-date="MAX_DATE"
						:max-page="MAX_PAGE"
						is-expanded
						class="vc-compare"
					/>
				</div>
			</div>
		</div>
	</MoleculeOverlayBox>
</template>

<script lang="ts">
import {
	computed, defineComponent, PropType, ref, watch,
} from 'vue';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import MoleculeOverlayBox from '@/components/molecules/MoleculeOverlayBox/MoleculeOverlayBox.vue';
import AtomDot from '@/components/atoms/AtomDot/AtomDot.vue';
import { CompareType, compareTypes } from '@/enums/CompareType';
import { DateRange, getNewDateRangeFromInput } from '@/models/DateRange';
import { ComparableDateRange } from '@/models/ComparableDateRange';
import AtomInput from '@/components/atoms/AtomInput/AtomInput.vue';
import ChipList from '@/components/_misc/ChipList/ChipList.vue';
import { getDateTime } from '@/utilities/Helpers';

interface PageAddress {
	day?: number;
	week?: number;
	month: number;
	year: number;
}

const MAX_DATE = DateTime.now().minus({ days: 1 });
const MAX_PAGE: PageAddress = {
	month: MAX_DATE.month,
	year: MAX_DATE.year,
};

export default defineComponent({
	name: 'OrganismOverlayDateRange',

	components: {
		DatePicker,
		MoleculeOverlayBox,
		AtomDot,
		AtomInput,
		ChipList,
	},

	props: {
		modelValue: {
			type: Object as PropType<DateRange>,
			required: true,
		},
		compareRange: {
			type: [String, Object] as PropType<CompareType | DateRange>,
			required: true,
		},
	},

	emits: [
		'update:modelValue',
		'update:compareRange',
	],

	setup(props, { emit }) {
		const originalDatePicker = ref();
		const compareDatePicker = ref();
		const { t } = useI18n();

		const predefinedRanges = [
			{
				label: t('button.lastDays', [7]),
				value: { days: 6 },
			},
			{
				label: t('button.lastDays', [14]),
				value: { days: 13 },
			},
			{
				label: t('button.lastDays', [30]),
				value: { days: 29 },
			},
			{
				label: t('button.lastDays', [90]),
				value: { days: 89 },
			},
		];

		const predefinedRangesOptions = computed(() => [...predefinedRanges.map((range) => range.label), 'Custom']);

		const originalDateRange = computed<DateRange>({
			get() {
				return props.modelValue;
			},
			set(range) {
				emit('update:modelValue', range);
			},
		});

		watch(originalDateRange, (range) => {
			originalDatePicker.value.move(range.start);
		});

		const asComparableDateRange = computed(() => ComparableDateRange.fromDateTime(
			getDateTime(originalDateRange.value.start),
			getDateTime(originalDateRange.value.end),
		));

		const compareDateRange = computed<DateRange>({
			get() {
				if (typeof props.compareRange === 'string') {
					const compareableDateRange = asComparableDateRange.value.toCompareType(props.compareRange);

					return {
						start: compareableDateRange._startDate,
						end: compareableDateRange._endDate,
					};
				}

				return props.compareRange;
			},
			set(range) {
				emit('update:compareRange', range);
			},
		});

		watch(compareDateRange, (range) => {
			compareDatePicker.value.move(range.start);
		});

		const compareRangeOptions = [
			...Object.values(compareTypes).map((values) => values.displayName),
			'Custom',
		];

		const compareRangeOption = computed({
			get() {
				if (typeof props.compareRange === 'string') {
					return compareTypes[props.compareRange].displayName;
				}

				return 'Custom';
			},
			set(value) {
				const compareType = Object.values(compareTypes).find((v) => v.displayName === value);

				if (compareType) {
					emit('update:compareRange', compareType.type);
				} else {
					emit('update:compareRange', compareDateRange.value);
				}
			},
		});

		const selectedPredefinedRange = computed({
			get() {
				const range = predefinedRanges.find((r) => r.value.days === asComparableDateRange.value.diffInDays - 1);

				if (range) {
					return range.label;
				}

				return 'Custom';
			},
			set(label) {
				const range = predefinedRanges.find((r) => r.label === label);

				if (range) {
					originalDateRange.value = {
						start: MAX_DATE.minus(range.value),
						end: MAX_DATE,
					};
				}
			},
		});

		const onChangeOriginalDateRange = (event: Event, key: keyof DateRange) => {
			originalDateRange.value = getNewDateRangeFromInput(event, key, originalDateRange.value);
		};

		const onChangeCompareDateRange = (event: Event, key: keyof DateRange) => {
			compareDateRange.value = getNewDateRangeFromInput(event, key, originalDateRange.value);
		};

		return {
			originalDatePicker,
			compareDatePicker,

			compareDateRange,
			originalDateRange,

			predefinedRanges,
			predefinedRangesOptions,
			selectedPredefinedRange,

			compareRangeOption,
			compareRangeOptions,

			onChangeOriginalDateRange,
			onChangeCompareDateRange,

			getDateTime,

			MAX_DATE,
			MAX_PAGE,

			masks: {
				title: 'MMM YYYY',
			},
		};
	},
});
</script>

<style scoped>
/* general calendar */
:deep(.vc-container) {
	--purple-100: transparent;
	--purple-200: transparent;
	--purple-300: transparent;
	--purple-400: transparent;
	--purple-500: transparent;
	--purple-600: transparent;
	--purple-700: transparent;
	--purple-800: transparent;
	--font-bold: 400;
	--white: #160F25;

	@apply border-none bg-transparent;
}

:deep(.vc-nav-item.is-active) {
	@apply text-white;
}

:deep(.vc-nav-item) {
	@apply text-purple-900;
}

:deep(.vc-base-icon) {
	@apply text-grey-500;
}

:deep(.vc-nav-popover-container) {
	@apply text-white;
}

:deep(.vc-container .vc-weeks) {
	@apply gap-y-[9px];
}
:deep(.vc-container .vc-arrows-container) {
	@apply py-2 px-0 text-purple-900;
}
:deep(.vc-container .vc-weeks) {
	@apply p-0 pt-3 min-w-0;
}
:deep(.vc-container .vc-pane-layout) {
	@apply gap-6;
}
:deep(.vc-container .vc-day.is-today .vc-day-content) {
	@apply font-bold;
}
:deep(.vc-container .vc-weekday) {
	@apply font-semibold text-xs text-grey-500;
}
:deep(.vc-container .vc-day-content) {
	@apply font-normal text-xs;
}
:deep(.vc-container .vc-title) {
	@apply font-semibold text-sm;
}
:deep(.vc-container .vc-day-content:hover) {
	@apply text-white !h-full !w-full !bg-purple-600;
}
:deep(.vc-container .vc-day-content:focus) {
	@apply !border-white !outline-white !bg-purple-600 !h-full !w-full;
	box-shadow: none;
}
:deep(.vc-container .vc-day .vc-highlights.vc-day-content:hover) {
	@apply border border-white text-white rounded-full;
}
:deep(.vc-container .vc-day.on-right .vc-highlights.vc-day-layer) {
	@apply text-white rounded-r-full overflow-hidden;
}
:deep(.vc-container .vc-day.on-left .vc-highlights.vc-day-layer) {
	@apply text-white rounded-l-full overflow-hidden;
}
:deep(.vc-container .vc-day.is-last-day .vc-highlights.vc-day-layer) {
	@apply text-white rounded-r-full overflow-hidden;
}
:deep(.vc-container .vc-day.is-first-day .vc-highlights.vc-day-layer) {
	@apply text-white rounded-l-full overflow-hidden;
}
:deep(.vc-container .vc-day .vc-highlights.vc-day-layer) {
	@apply text-white;
}
:deep(.vc-container .vc-day-content.vc-disabled) {
	@apply bg-transparent hover:bg-transparent text-opacity-50 pointer-events-none cursor-default;
}
:deep(.vc-container .vc-day.on-left .vc-highlights .vc-day-layer .vc-highlight) {
	@apply text-white overflow-hidden rounded-l-full;
}
:deep(.vc-highlight.vc-highlight-base-start) {
	@apply !rounded-l-full !h-full !w-full;
}
:deep(.vc-highlight.vc-highlight-base-middle) {
	@apply !h-full !w-full;
}
:deep(.vc-highlight.vc-highlight-bg-outline) {
	@apply !border-none;
}
:deep(.vc-highlight.vc-highlight-base-end) {
	@apply !rounded-r-full !h-full !w-full;
}
:deep(.vc-day.on-left .vc-highlight.vc-highlight-base-middle) {
	@apply !w-full !rounded-l-full !h-full;
}
:deep(.vc-day.on-right .vc-highlight.vc-highlight-base-middle) {
	@apply !rounded-r-full !h-full;
}
:deep(.vc-day.on-right .vc-highlight.vc-highlight-base-start) {
	@apply !rounded-full !h-full;
}
:deep(.vc-day .vc-highlights .vc-day-layer:nth-child(2)) {
	@apply hidden;
}
:deep(.vc-container.vc-day.on-right .vc-highlights .vc-day-layer .vc-highlight.vc-highlight-base-start) {
	@apply !h-full !w-11/12;
}
:deep(.vc-container.vc-day.on-left .vc-highlights .vc-day-layer .vc-highlight.vc-highlight-base-end) {
	@apply !h-full !w-11/12;
}

/* date range calendar */
:deep(.vc-container.vc-date) {
  --purple-900: #ffffff;
  --white: white;
}
:deep(.vc-container.vc-date .vc-day-content) {
	@apply text-purple-900;
}
:deep(.vc-container.vc-date .vc-day-content.vc-purple) {
	@apply text-white;
}
:deep(.vc-container.vc-date .vc-title) {
	@apply text-purple-900;
}
:deep(.vc-container.vc-date .vc-day-content:hover) {
	@apply bg-purple-600 text-white;
}
:deep(.vc-container.vc-date .vc-day .vc-highlights.vc-day-content:hover) {
	@apply bg-purple-600 box-border border border-white text-white;
}
:deep(.vc-container.vc-date .vc-day .vc-highlights.vc-day-layer:hover) {
	@apply border border-white text-white;
}
:deep(.vc-container.vc-date .vc-day .vc-highlights.vc-day-layer) {
	@apply bg-transparent !text-white;
}
:deep(.vc-container.vc-date .vc-highlight.vc-highlight-base-start) {
	@apply !bg-purple-500 !text-white;
}
:deep(.vc-container.vc-date .vc-highlight.vc-highlight-base-middle) {
	@apply !bg-purple-500;
}
:deep(.vc-container.vc-date .vc-highlight.vc-highlight-base-end) {
	@apply !bg-purple-500 !text-white;
}
:deep(.vc-container.vc-date .vc-day.on-left .vc-highlight.vc-highlight-base-middle) {
	@apply !bg-purple-500;
}
:deep(.vc-container.vc-date .vc-day.on-right .vc-highlight.vc-highlight-base-middle) {
	@apply !bg-purple-500;
}
:deep(.vc-container.vc-date .vc-day .vc-highlight) {
	@apply !bg-purple-500 !h-full !w-full;
}
:deep(.vc-container.vc-date .vc-day-content.vc-disabled) {
	@apply bg-transparent hover:bg-transparent text-purple-900 text-opacity-50;
}

/* compare calendar */
:deep(.vc-container.vc-compare) {
  --purple-900: #160F25;
}
:deep(.vc-container.vc-compare .vc-day-content) {
	@apply text-purple-900;
}
:deep(.vc-container.vc-compare .vc-title) {
	@apply text-purple-900;
}
:deep(.vc-container.vc-compare .vc-day-content:hover) {
	@apply !bg-purple-200 !text-purple-900;
}
:deep(.vc-container.vc-compare .vc-day-content:focus) {
	@apply !bg-purple-200 !text-purple-900;
}
:deep(.vc-container.vc-compare .vc-day .vc-highlights.vc-day-content:hover) {
	@apply bg-purple-200 border border-white text-white;
}
:deep(.vc-container.vc-compare .vc-day .vc-highlights.vc-day-layer:hover) {
	@apply border border-white text-white;
}
:deep(.vc-container.vc-compare .vc-highlight.vc-highlight-base-start) {
	@apply !bg-purple-300;
}
:deep(.vc-container.vc-compare .vc-highlight.vc-highlight-base-middle) {
	@apply !bg-purple-300;
}
:deep(.vc-container.vc-compare .vc-highlight.vc-highlight-base-end) {
	@apply !bg-purple-300;
}
:deep(.vc-container.vc-compare .vc-day.on-left .vc-highlight.vc-highlight-base-middle) {
	@apply !bg-purple-300;
}
:deep(.vc-container.vc-compare .vc-day.on-right .vc-highlight.vc-highlight-base-middle) {
	@apply !bg-purple-300;
}
:deep(.vc-container.vc-compare .vc-day .vc-highlight) {
	@apply !bg-purple-300 !h-full !w-full;
}
:deep(.vc-container.vc-compare .vc-day-content.vc-disabled) {
	@apply bg-transparent hover:bg-transparent text-purple-900 text-opacity-50;
}
</style>
