<template>
	<button
		v-ripple
		class="ripple-purple-300 font-semibold text-xs font-sans relative box-border cursor-pointer transition-all rounded-lg text-center tracking-wide flex flex-col justify-center items-center h-[69px] text-white"
		:class="classObject"
		v-bind="$attrs"
		:active="isActive"
		@click="onClick"
	>
		<AtomIcon
			v-if="icon"
			:icon="icon"
			size="sm"
			:class="{
				'mb-1': label.length > 0,
			}"
		/>
		<slot name="default">
			{{ label }}
		</slot>
	</button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { Ripple } from '@/directives/RippleDirective';
import AtomIcon from '../AtomIcon/AtomIcon.vue';

export default defineComponent({
	name: 'AtomSidenavItem',

	components: {
		AtomIcon,
	},

	directives: {
		Ripple,
	},

	props: {
		label: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		active: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		'click',
	],

	setup(props, { emit }) {
		const isActive = computed(() => props.active);

		const classObject = computed(() => [
			{
				'text-opacity-50 bg-purple-500 hover:bg-purple-400': !isActive.value,
				'text-opacity-100 bg-purple-400': isActive.value,
			},
		]);

		const onClick = ($event: Event) => {
			emit('click', $event);
		};

		return {
			classObject,
			isActive,
			onClick,
		};
	},
});
</script>

<style scoped>
span::after {
	display: block;
	content: attr(label);
	font-weight: bold;
	color: transparent;
	overflow: hidden;
	visibility: hidden;
	width: 100%;
}
</style>
