import {
	createI18n, I18n, LocaleMessages, VueMessageType,
} from 'vue-i18n';
import { setLocale } from '@vee-validate/i18n';

const loadLocaleMessages = () => {
	if (process.env.NODE_ENV === 'test') {
		return undefined;
	}

	const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages: LocaleMessages<VueMessageType> = {};
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});

	return messages;
};

// export default new VueI18n({
// 	locale: process.env.VUE_APP_I18N_LOCALE || 'en',
// 	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
// 	messages: loadLocaleMessages(),
// });

export const setI18nLanguage = (i18n: I18n, locale: string) => {
	if (i18n.mode === 'legacy') {
		i18n.global.locale = locale;
	} else {
		/* eslint-disable @typescript-eslint/ban-ts-comment */
		// @ts-ignore
		i18n.global.locale.value = locale;
	}

	setLocale(locale);
};

export const setupI18n = (options = { locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE }) => {
	const i18n = createI18n({
		...options,
		messages: loadLocaleMessages(),
	}) as I18n;

	if (options.locale) {
		setI18nLanguage(i18n, options.locale);
	}

	return i18n;
};

const i18n = setupI18n();

export default i18n;
