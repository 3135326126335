<template>
	<button
		v-ripple
		class="font-semibold text-sm overflow-hidden relative flex items-center justify-center box-border max-w-min whitespace-nowrap leading-none rounded-full font-sans transition-all ease-in-out py-2 px-3 cursor-pointer"
		:class="classObject"
		v-bind="$attrs"
		@click="onClick"
	>
		<span>
			<slot name="default">{{ label }}</slot>
		</span>
	</button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { Ripple } from '@/directives/RippleDirective';

export default defineComponent({
	name: 'AtomChip',

	directives: {
		Ripple,
	},

	props: {
		label: {
			type: String,
			default: '',
		},
		modelValue: {
			type: Boolean,
			required: true,
		},
	},

	emits: [
		'update:modelValue',
	],

	setup(props, { emit }) {
		const classObject = computed(() => [
			{
				'hover:bg-purple-600 text-grey-600 hover:text-white border border-grey-600 hover:border-purple-600': !props.modelValue,
				'bg-purple-500 text-white border border-purple-500 hover:bg-purple-600 hover:border-purple-600': props.modelValue,
			},
		]);

		const onClick = () => {
			emit('update:modelValue', !props.modelValue);
		};

		return {
			classObject,
			onClick,
		};
	},
});

</script>
