<template>
	<div class="overlay-backdrop-blur shadow-lg border border-grey-300 rounded-lg min-w-[360px] max-w-[1100px]">
		<div
			v-if="showHeader"
			class="px-6 pt-6 relative flex"
		>
			<p
				v-if="title"
				class="text-2xl font-bold tracking-tight"
			>
				{{ title }}
			</p>
		</div>
		<div :class="{ 'p-6' : bodyPadding }">
			<slot />
		</div>
		<div
			v-if="actions.length > 0"
			class="border-t border-grey-300 p-6 flex justify-end space-x-6"
		>
			<AtomButton
				v-for="(action, $index) in actions"
				:key="$index"
				v-bind="action"
				class="w-40"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import AtomButton, { ButtonColor } from '../../atoms/AtomButton/AtomButton.vue';

export interface ModalBoxAction {
	color?: ButtonColor,
	icon?: string,
	label: string,
	onClick: () => void,
}

export default defineComponent({
	name: 'MoleculeOverlayBox',

	components: {
		AtomButton,
	},

	props: {
		title: {
			type: String,
			default: '',
		},
		actions: {
			type: Array as PropType<ModalBoxAction[]>,
			default: () => [],
		},
		bodyPadding: {
			type: Boolean,
			default: true,
		},
	},

	setup(props) {
		const showHeader = computed(() => props.title.length > 0);

		return {
			showHeader,
		};
	},
});
</script>
