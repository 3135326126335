<template>
	<div class="bg-purple-600 bg-wave-pattern min-h-screen flex flex-col items-center justify-center text-center">
		<transition
			name="zoom-in-long"
			appear
			mode="out-in"
		>
			<img
				src="@/assets/404.png"
				class="mb-3"
				alt="Biddy didn't find your site :('"
			>
		</transition>
		<transition
			name="fade-up-long"
			appear
			mode="out-in"
		>
			<p
				class="text-white text-5xl font-bold mb-3"
				style="transition-delay: 0.1s"
			>
				{{ $t('view.notFound.title') }}
			</p>
		</transition>
		<transition
			name="fade-up-long"
			appear
			mode="out-in"
		>
			<p
				class="text-lg text-white mb-6"
				style="transition-delay: 0.2s"
			>
				{{ $t('view.notFound.subtitle') }}
			</p>
		</transition>
		<transition
			name="fade-up-long"
			appear
			mode="out-in"
		>
			<RouterLink
				:to="{ name: 'dashboard' }"
				style="transition-delay: 0.3s"
			>
				<AtomButton>
					{{ $t('view.notFound.button') }}
				</AtomButton>
			</RouterLink>
		</transition>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AtomButton from '@/components/atoms/AtomButton/AtomButton.vue';

export default defineComponent({
	name: 'NotFound',

	components: { AtomButton },
});
</script>
