<template>
	<div class="bg-purple-200 rounded-lg py-5 px-6 flex flex-col">
		<div>
			<div class="mb-2 flex items-center">
				<AtomIcon
					icon="trend-up"
					class="mr-1 text-purple-500"
				/>
				<p class="text-xl font-bold">
					{{ $t('widget.performanceGraph.title') }}
				</p>
			</div>
			<p class="text-grey-600 text-sm">
				{{ $t('note.performanceGraph.selectKpi') }}
			</p>
		</div>
		<div class="relative flex-1 grid">
			<div
				ref="chartWrapperRef"
				@mousemove="onMouseMove"
				@mouseleave="onMouseLeave"
				@focusout="onMouseLeave"
			>
				<VChart
					ref="chartRef"
					:option="polar"
					:autoresize="true"
					class="w-full min-h-[250px]"
				/>
			</div>
			<div
				ref="tooltipRef"
				class="pointer-events-none"
			>
				<OrganismOverlayKPI
					v-show="isOpen"
					:data="tooltipData"
					class="inline-block"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
	MarkLineComponent,
	TooltipComponent,
	LegendComponent,
	GridComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';
import {
	computed, defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import type { EChartsOption, LineSeriesOption } from 'echarts';
import { DateTime } from 'luxon';
import { VirtualElement } from '@popperjs/core';
import { Insight } from '@/models/Insight';
import AtomIcon from '@/components/atoms/AtomIcon/AtomIcon.vue';
import { ProviderInsightsRaw } from '@/models/ProviderInsights';
import { usePopper } from '@/composables/usePopper';
import { InsightType } from '@/enums/InsightType';
import OrganismOverlayKPI from '../OrganismOverlayKPI/OrganismOverlayKPI.vue';

use([
	CanvasRenderer,
	LineChart,
	MarkLineComponent,
	TooltipComponent,
	LegendComponent,
	GridComponent,
]);

export default defineComponent({
	name: 'OrganismPerformanceGraph',

	components: {
		VChart,
		AtomIcon,
		OrganismOverlayKPI,
	},

	props: {
		insights: {
			type: Array as PropType<Insight[]>,
			required: true,
		},
		providersIntegrationDate: {
			type: Object as PropType<DateTime>,
			default: null,
		},
		selectedInsights: {
			type: Array as PropType<InsightType[]>,
			required: true,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const chartRef = ref<InstanceType<typeof VChart>>();
		const tooltipRef = ref<HTMLElement | null>(null);
		const chartWrapperRef = ref<HTMLElement | null>(null);

		const generateGetBoundingClientRect = (x = 0, y = 0) => (): DOMRect => ({
			width: 0,
			height: 0,
			top: y,
			right: x,
			bottom: y,
			left: x,
			x: 0,
			y: 0,
			toJSON() {
				return '';
			},
		});

		const virtualElement: VirtualElement = {
			getBoundingClientRect: generateGetBoundingClientRect(),
		};

		const { popperInstance, isOpen } = usePopper({
			triggerNode: ref(virtualElement),
			popperNode: tooltipRef,
			flipNode: chartWrapperRef,
			placement: ref('right'),
			offsetDistance: ref('16'),
			offsetSkid: ref('0'),
		});

		const onMouseMove = ({ clientX: x, clientY: y }: MouseEvent) => {
			virtualElement.getBoundingClientRect = generateGetBoundingClientRect(x, y);
			popperInstance.value?.update();
		};

		const onMouseLeave = () => {
			isOpen.value = false;
		};

		const tooltipData: Partial<ProviderInsightsRaw> = reactive({
			InsightDate: DateTime.now().toString(),
		});

		const onLabelChange = (params: any): string => {
			isOpen.value = true;

			const providerInsights: Partial<ProviderInsightsRaw> = params.seriesData.reduce((insights: Partial<ProviderInsightsRaw>, data: any) => {
				if (!props.selectedInsights.includes(data.seriesId)) {
					return insights;
				}

				return {
					...insights,
					[data.seriesId]: data.value[1],
				};
			}, {});

			Object.assign(tooltipData, { ...providerInsights, InsightDate: DateTime.fromJSDate(params.seriesData[0].value[0]) });

			return '';
		};

		const toLegend = (insights: Insight[], selectedInsights: InsightType[]) => insights.reduce((newSelection, insight) => {
			const isSelected = selectedInsights.some((selectedInsight) => selectedInsight === insight.key);

			return {
				...newSelection,
				[insight.displayName]: isSelected,
			};
		}, {});

		const getChart = (): EChartsOption => ({
			boundaryGap: true,
			legend: {
				show: true,
				top: -100,
				selected: toLegend(props.insights, props.selectedInsights),
			},
			tooltip: {
				trigger: 'axis',
				backgroundColor: '#ffffff',
				borderWidth: 1,
				borderColor: '#D4DCE4',
				textStyle: {
					color: '#667180',
				},
				extraCssText: 'opacity: 0',
			},
			grid: {
				show: false,
				top: 40,
				left: 6,
				right: 6,
				bottom: 10,
				containLabel: true,
			},
			xAxis: {
				boundaryGap: false,
				/* @ts-ignore */
				type: 'time',
				scale: true,
				show: true,
				splitNumber: 15,
				minInterval: 3600 * 1000 * 24,
				axisTick: {
					show: false,
				},
				axisLabel: {
					show: true,
					showMinLabel: false,
					showMaxLabel: false,
					formatter: (value: any) => {
						const date = DateTime.fromMillis(value);
						return `{date|${date.toFormat('d MMM yy')}}`;
					},
					rotate: 45,
					margin: 12,
					rich: {
						date: {
							fontFamily: 'Roboto',
							fontSize: 12,
							color: '#7E7491',
						},
					},
				},
				axisLine: {
					lineStyle: {
						color: '#D4CBE4',
						type: 'solid',
					},
				},
				axisPointer: {
					lineStyle: {
						color: '#D4CBE4',
						type: 'solid',
						width: 2,
					},
					z: 0,
					label: {
						formatter: onLabelChange,
					},
				},
				splitLine: {
					show: false,
				},
			},
			yAxis: [
				{
					type: 'value',
					show: true,
					min: 0,
					max: 100,
					axisLine: {
						show: false,
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: '#D4CBE4',
							type: 'solid',
						},
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: false,
					},
				},
			],
			series: [],
			color: [],
		});

		const polar = ref(getChart());

		const series = computed(() => {
			const insightSeries: LineSeriesOption[] = props.insights.map((insight, index) => ({
				name: insight.displayName,
				type: 'line',
				id: insight.key,
				stack: insight.id,
				yAxisIndex: index + 1,
				data: insight.getDateValueArray('origin'),
				showSymbol: false,
				z: 10,
				emphasis: {
					itemStyle: {
						color: '#ffffff',
						borderColor: insight.color[0],
						borderWidth: 2,
					},
				},
				lineStyle: {
					color: insight.color[0],
				},
			}));

			if (props.providersIntegrationDate) {
				insightSeries.push({
					name: 'marks',
					type: 'line',
					showSymbol: false,
					z: 10,
					markLine: {
						data: [{ name: 'BiddingLab Integration', xAxis: props.providersIntegrationDate.toMillis() }],
						symbol: 'none',
						lineStyle: {
							color: '#A0AFBD',
							type: 'solid',
						},
						label: {
							formatter(params) {
								return `${params.name}\n${DateTime.fromMillis(params.value as number).toFormat('dd MMMM yyyy')}`;
							},
						},
					},
				});
			}

			return insightSeries;
		});

		const updateLegend = () => {
			if (!polar.value || !polar.value.legend) {
				return;
			}

			if (Array.isArray(polar.value.legend)) {
				return;
			}

			polar.value.legend.selected = toLegend(props.insights, props.selectedInsights);
		};

		onMounted(() => {
			updateLegend();
		});

		watch(() => props.selectedInsights, updateLegend);
		watch(() => props.insights, updateLegend);

		const updateYAxis = () => {
			if (!polar.value || !Array.isArray(polar.value.yAxis)) {
				return;
			}

			polar.value.yAxis = [polar.value.yAxis[0]];

			props.insights.forEach(() => {
				if (!polar.value || !Array.isArray(polar.value.yAxis)) {
					return;
				}

				polar.value.yAxis.push({
					type: 'value',
					scale: true,
					show: false,
					axisLabel: {
						show: false,
					},
				});
			});
		};

		watch(series, (newSeries) => {
			polar.value.series = newSeries;
			updateYAxis();
		});

		onMounted(() => {
			polar.value.series = series.value;
			updateYAxis();
		});

		return {
			chartWrapperRef,
			tooltipRef,
			isOpen,
			onMouseMove,
			onMouseLeave,
			tooltipData,
			polar,
			series,
			chartRef,
		};
	},
});
</script>
