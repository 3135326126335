<template>
	<div
		class="bg-purple-600 py-16 px-20 relative bg-wave-pattern"
		:class="{
			'h-80': !compact
		}"
	>
		<div class="absolute top-4 right-20 flex items-center">
			<MoleculeSelectWithLabel
				:model-value="provider"
				:options="providers"
				:border="false"
				color-mode="dark"
				size="sm"
				placement="bottom-end"
				:same-width="false"
				searchable
				@update:modelValue="$emit('update:provider', $event)"
			/>
			<div ref="overlayUserTrigger">
				<AtomBadgeUser
					v-if="user"
					v-click-outside="() => isUserOverlayOpen = false"
					class="ml-6"
					:user="user"
					clickable
					@click="isUserOverlayOpen = !isUserOverlayOpen"
				/>
			</div>
			<teleport to="#poppers">
				<div
					ref="overlayUserRef"
					class="z-10"
				>
					<transition name="fade-up">
						<OrganismOverlayUser
							v-if="user"
							v-show="isUserOverlayOpen"
							:user="user"
							:logout="logout"
						/>
					</transition>
				</div>
			</teleport>
		</div>
		<div class="flex">
			<transition
				:name="compact ? 'zoom-in' : 'zoom-in-long'"
				mode="out-in"
				appear
			>
				<div
					v-if="icon"
					:key="icon"
					class="w-20 h-20 rounded-full bg bg-purple-400 mr-4 flex items-center justify-center"
				>
					<AtomIcon
						:icon="icon"
						class="text-white"
						size="md"
					/>
				</div>
				<div
					v-else
					class="w-20 h-20 rounded-full bg bg-purple-400 mr-4 flex items-center justify-center"
				>
					<img
						src="@/assets/biddy-profile.png"
						alt="Profile picture of Biddy"
					>
				</div>
			</transition>
			<div class="flex-1">
				<transition
					:name="compact ? 'fade-up' : 'fade-up-long'"
					mode="out-in"
					appear
				>
					<p
						:key="headline"
						class="text-white text-4xl font-bold tracking-tight mb-2"
					>
						{{ headline }}
					</p>
				</transition>
				<transition
					:name="compact ? 'fade-up' : 'fade-up-long'"
					mode="out-in"
					appear
				>
					<p
						:key="subheadline"
						style="transition-delay: 0.025s"
						class="opacity-75 text-white mb-5"
					>
						{{ subheadline }}
					</p>
				</transition>
				<transition
					name="fade-up-long"
					mode="out-in"
					appear
				>
					<div
						v-if="!compact"
						class="flex"
						style="transition-delay: 0.05s"
					>
						<LearningProgress
							v-if="learningStrategy"
							:strategy="learningStrategy"
							class="w-[420px]"
						/>
						<div
							v-if="dateRange"
							class="flex space-x-6 ml-auto"
						>
							<div
								v-click-outside="() => isDateRangeOverlayOpen = false"
							>
								<div ref="overlayDateRangeTrigger">
									<AtomSelect
										:value="formattedDateRange"
										icon="calendar"
										color-mode="dark"
										class="w-[300px]"
										:note="compareToNote"
										@click="isDateRangeOverlayOpen = !isDateRangeOverlayOpen"
									/>
								</div>
								<teleport to="#poppers">
									<div
										ref="overlayDateRangeRef"
										class="z-50"
									>
										<transition name="fade-up-long">
											<OrganismOverlayDateRange
												v-show="isDateRangeOverlayOpen"
												:compare-range="compareRange"
												:model-value="dateRange"
												@update:modelValue="$emit('update:dateRange', $event)"
												@update:compareRange="$emit('update:compareRange', $event)"
												@click.stop
											/>
										</transition>
									</div>
								</teleport>
							</div>
							<div>
								<MoleculeSelectWithLabel
									v-if="strategies.length > 1"
									:placeholder="$t('input.placeholder.selectCustomStrategy')"
									:model-value="strategy"
									:options="strategies"
									color-mode="dark"
									class="w-[300px]"
									@update:modelValue="$emit('update:strategy', $event)"
								/>
								<AtomLink
									v-if="strategy"
									color="white"
									size="sm"
									:to="() => $emit('update:strategy', '')"
									class="mt-0.5"
								>
									{{ $t('button.reset') }}
								</AtomLink>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { DateTime } from 'luxon';
import {
	computed, defineComponent, PropType, ref,
} from 'vue';
import { User } from '@/models/User';
import AtomBadgeUser from '@/components/atoms/AtomBadgeUser/AtomBadgeUser.vue';
import MoleculeSelectWithLabel from '@/components/molecules/MoleculeSelectWithLabel/MoleculeSelectWithLabel.vue';
import AtomSelect from '@/components/atoms/AtomSelect/AtomSelect.vue';
import LearningProgress from '@/components/_misc/LearningProgress/LearningProgress.vue';
import { DateRange } from '@/models/DateRange';
import { getDateTime } from '@/utilities/Helpers';
import { CompareType } from '@/enums/CompareType';
import AtomLink from '@/components/atoms/AtomLink/AtomLink.vue';
import { usePopper } from '@/composables/usePopper';
import ClickOutside from '@/directives/ClickOutsideDirective';
import AtomIcon from '@/components/atoms/AtomIcon/AtomIcon.vue';
import { ComparableDateRange } from '@/models/ComparableDateRange';
import { Strategy } from '@/models/Strategy';
import { LinkLocation } from '@/components/atoms/AtomLink/AtomLinkModel';
import OrganismOverlayDateRange from '../OrganismOverlayDateRange/OrganismOverlayDateRange.vue';
import OrganismOverlayUser from '../OrganismOverlayUser/OrganismOverlayUser.vue';

export default defineComponent({
	name: 'OrganismHero',

	directives: {
		ClickOutside,
	},

	components: {
		AtomBadgeUser,
		MoleculeSelectWithLabel,
		AtomSelect,
		OrganismOverlayUser,
		OrganismOverlayDateRange,
		AtomIcon,
		LearningProgress,
		AtomLink,
	},

	props: {
		user: {
			type: [Object, null] as PropType<User | null>,
			default: null,
		},
		provider: {
			type: String,
			default: '',
		},
		providers: {
			type: Array,
			default: () => [],
		},
		strategy: {
			type: String,
			default: '',
		},
		strategies: {
			type: Array,
			default: () => [],
		},
		learningStrategy: {
			type: Object as PropType<Strategy | null>,
			default: null,
		},
		dateRange: {
			type: [Object, null] as PropType<DateRange | null>,
			default: null,
		},
		compareRange: {
			type: [String, Object] as PropType<CompareType | DateRange>,
			default: 'period',
		},
		logout: {
			type: [String, Object, Function] as PropType<LinkLocation>,
			required: true,
		},
		headline: {
			type: String,
			required: true,
		},
		subheadline: {
			type: String,
			required: true,
		},
		compact: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: null,
		},
	},

	emits: [
		'update:dateRange',
		'update:compareRange',
		'update:provider',
		'update:strategy',
	],

	setup(props) {
		const formattedDateRange = computed(() => {
			if (!props.dateRange) {
				return '';
			}

			return `${getDateTime(props.dateRange.start).toFormat('dd.LL.yyyy')} - ${getDateTime(props.dateRange.end).toFormat('dd.LL.yyyy')}`;
		});

		const asComparableDateRange = computed(() => ComparableDateRange.fromDateTime(
			getDateTime(props.dateRange?.start || DateTime.now().minus({ days: 2 })),
			getDateTime(props.dateRange?.end || DateTime.now().minus({ days: 1 })),
		));

		const computedCompareRange = computed(() => {
			if (typeof props.compareRange === 'string') {
				const compareableDateRange = asComparableDateRange.value.toCompareType(props.compareRange);

				return {
					start: compareableDateRange._startDate,
					end: compareableDateRange._endDate,
				};
			}

			return props.compareRange;
		});

		const compareToNote = computed(() => `Compare to: ${getDateTime(computedCompareRange.value.start).toFormat('dd.LL.yyyy')} - ${getDateTime(computedCompareRange.value.end).toFormat('dd.LL.yyyy')}`);

		const overlayUserRef = ref<HTMLElement | null>(null);
		const overlayUserTrigger = ref<HTMLElement | null>(null);

		const { isOpen: isUserOverlayOpen } = usePopper({
			popperNode: overlayUserRef,
			placement: ref('bottom-end'),
			offsetDistance: ref('8'),
			offsetSkid: ref('0'),
			triggerNode: overlayUserTrigger,
		});

		const overlayDateRangeRef = ref<HTMLElement | null>(null);
		const overlayDateRangeTrigger = ref<HTMLElement | null>(null);

		const { isOpen: isDateRangeOverlayOpen } = usePopper({
			popperNode: overlayDateRangeRef,
			placement: ref('bottom-end'),
			offsetDistance: ref('-16'),
			offsetSkid: ref('0'),
			triggerNode: overlayDateRangeTrigger,
		});

		return {
			formattedDateRange,
			compareToNote,
			overlayUserRef,
			overlayUserTrigger,
			isUserOverlayOpen,

			overlayDateRangeRef,
			overlayDateRangeTrigger,
			isDateRangeOverlayOpen,
		};
	},
});
</script>
