import { DateTime } from 'luxon';
import {
	reactive, toRefs, watchEffect, unref,
} from 'vue';
import { CompareType } from '@/enums/CompareType';
import { DEFAULT_COMPARE_RANGE_TYPE } from '@/utilities/Constants';
import { DateOrISO, MaybeRefs } from '@/utilities/Types';
import { getWithExpiry, setWithExpiry } from '@/models/StorageWithExpiry';
import { getTimeUntilEndOfDay, toDate } from '@/utilities/Helpers';
import { DateRange } from '@/models/DateRange';

const STORAGE_KEY = 'selection_storage';

const DEFAULT_DATE_RANGE = {
	start: DateTime.now().minus({ days: 91 }).toJSDate(),
	end: DateTime.now().minus({ days: 1 }).toJSDate(),
};

export interface SelectionStorageValues {
	startDate: DateOrISO;
	endDate: DateOrISO;
	compareRange: CompareType | DateRange;
}

export const useSelectionStorage = (fallback?: Partial<MaybeRefs<SelectionStorageValues>>, defaultStartDate = DEFAULT_DATE_RANGE.start) => {
	const storageValues = getWithExpiry<SelectionStorageValues>(STORAGE_KEY);

	const getStartDate = (): Date => {
		if (fallback && fallback.startDate) {
			return toDate(unref(fallback.startDate));
		}

		if (storageValues && storageValues.startDate) {
			return toDate(storageValues.startDate);
		}

		return defaultStartDate;
	};

	const getEndDate = (): Date => {
		if (fallback && fallback.endDate) {
			return toDate(unref(fallback.endDate));
		}

		if (storageValues && storageValues.endDate) {
			return toDate(storageValues.endDate);
		}

		return DEFAULT_DATE_RANGE.end;
	};

	const selection = reactive({
		startDate: getStartDate(),
		endDate: getEndDate(),
		compareType: storageValues?.compareRange || fallback?.compareRange || DEFAULT_COMPARE_RANGE_TYPE,
	});

	const clear = () => localStorage.removeItem(STORAGE_KEY);

	const update = (values: Partial<MaybeRefs<SelectionStorageValues>>) => {
		if (values.startDate) {
			selection.startDate = toDate(unref(values.startDate));
		}

		if (values.endDate) {
			selection.endDate = toDate(unref(values.endDate));
		}

		if (values.compareRange) {
			selection.compareType = unref(values.compareRange);
		}
	};

	watchEffect(() => {
		setWithExpiry(STORAGE_KEY, selection, getTimeUntilEndOfDay());
	});

	return {
		...toRefs(selection),
		clear,
		update,
	};
};
