<template>
	<div class="p-2 overlay-backdrop-blur rounded-lg border border-purple-300 shadow-lg flex flex-col">
		<ul
			ref="optionsListRef"
			class="flex-1 overflow-auto scrollbar relative"
		>
			<li v-if="!hasSearchResults && search">
				<slot name="noResults">
					<p class="p-2 text-grey-500">
						{{ $t('noResults') }}
					</p>
				</slot>
			</li>
			<li
				v-for="(option, $index) in filteredOptions"
				:key="option"
				class="flex items-center p-2 text-grey-500 rounded-sm w-full text-left focus-visible:bg-purple-200 outline-none transition cursor-pointer"
				:class="{
					'!text-black bg-purple-200': isOptionSelected(option) && !multiselect || ($index === typeAheadPointer),
				}"
				@mousedown="onMousedown(option, $event)"
				@mouseover="typeAheadPointer = $index"
				@focus="typeAheadPointer = $index"
			>
				<AtomCheckbox
					v-if="multiselect"
					:model-value="isOptionSelected(option)"
					class="mr-2"
					tabindex="-1"
				/>
				{{ option }}
			</li>
		</ul>
	</div>
</template>

<script lang="ts">
import {
	computed, defineComponent, PropType, toRefs, watch,
} from 'vue';
import { useSelect, SelectOption } from '@/composables/useSelect';
import AtomCheckbox from '../AtomCheckbox/AtomCheckbox.vue';
import { useTypeAhead } from './typeAhead';
import { useAdjustScroll } from './adjustScroll';

export default defineComponent({
	name: 'AtomDropdownMenu',

	components: {
		AtomCheckbox,
	},

	props: {
		modelValue: {
			type: [String, Number, Array] as PropType<SelectOption | SelectOption[]>,
			required: true,
		},
		options: {
			type: Array as PropType<SelectOption[]>,
			required: true,
		},
		multiselect: {
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		selectable: {
			type: Boolean,
			default: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		'update:modelValue',
	],

	setup(props) {
		const filteredOptions = computed(() => props.options
			.filter((option) => `${option}`.toLowerCase().includes(props.search.toLowerCase())));

		const stringifiedFilteredOptions = computed(() => filteredOptions.value.map((option) => `${option}`));

		const hasSearchResults = computed(() => filteredOptions.value.length > 0 && props.search);

		const { isSelected: isOptionSelected, toggleOption } = useSelect(toRefs(props).modelValue, props.multiselect, props.required);

		const onMousedown = (option: SelectOption, event: MouseEvent) => {
			if (props.multiselect) {
				event.preventDefault();
			}

			toggleOption(option);
		};

		const stringifiedModelValue = computed(() => {
			if (Array.isArray(props.modelValue)) {
				return props.modelValue.map((value) => `${value}`);
			}

			return `${props.modelValue}`;
		});

		const { typeAheadPointer, setTypeAheadPointer } = useTypeAhead(
			stringifiedFilteredOptions,
			stringifiedModelValue,
			toggleOption,
			toRefs(props).selectable,
		);

		const { maybeAdjustScroll, optionsListRef } = useAdjustScroll(
			(listElement) => listElement.children[typeAheadPointer.value] as HTMLElement,
		);

		watch(typeAheadPointer, maybeAdjustScroll);
		watch(() => props.selectable, () => {
			setTypeAheadPointer();
			maybeAdjustScroll();
		});

		return {
			typeAheadPointer,
			filteredOptions,
			hasSearchResults,
			isOptionSelected,
			onMousedown,
			optionsListRef,
		};
	},
});
</script>

<style>

</style>
