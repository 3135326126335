<template>
	<div class="fixed inset-0 flex flex-col items-center justify-center bg-white z-100" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'LoadingScreen',
});
</script>
