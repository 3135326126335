import { DateTime } from 'luxon';
import { InsightType } from '@/enums/InsightType';
import { Insight } from '@/models/Insight';
import { InsightDataset } from './InsightDataset';
import { ProviderInsights } from './ProviderInsights';

export const DEFAULT_SORT_ORDER: InsightType[] = [
	InsightType.IMPRESSIONS,
	InsightType.CLICKS,
	InsightType.CTR,
	InsightType.CPC,
	InsightType.COST,
	InsightType.CONVERSIONS,
	InsightType.COST_PER_CONVERSION,
	InsightType.ROAS,
	InsightType.CONVERSION_VALUE,
];

export class Insights {
	insights: Insight[];
	sortOrder: string[];

	constructor(insights: Insight[], sortOrder: string[]) {
		this.insights = insights;
		this.sortOrder = sortOrder;
	}

	static sortInsightsByKey(insights: Insight[], sortOrder: string[]): Insight[] {
		const sortedInsights = insights.sort((a, b) => {
			const indexA = sortOrder.indexOf(a.key);
			const indexB = sortOrder.indexOf(b.key);

			if (indexA > indexB) {
				return 1;
			}

			if (indexA < indexB) {
				return -1;
			}
			return 0;
		});

		return sortedInsights;
	}

	static sortInsightsByDefaultOrder(insights: Insight[]): Insight[] {
		return Insights.sortInsightsByKey(insights, DEFAULT_SORT_ORDER);
	}

	get insightsByKey() {
		return Insights.sortInsightsByKey(this.insights, this.sortOrder);
	}

	static fromProviderInsightsAndKey(keys: InsightType[], originProviderInsights: ProviderInsights, compareProviderInsight?: ProviderInsights): Insights {
		const mappedData: Insight[] = keys.reduce((data: Insight[], key) => {
			const supportedKeys = Object.values(InsightType);

			if (!supportedKeys.includes(key as InsightType)) {
				return data;
			}

			const originInsightDataset = InsightDataset.fromProviderInsights(originProviderInsights, key as InsightType);
			const compareInsightDataset = compareProviderInsight ? InsightDataset.fromProviderInsights(compareProviderInsight, key as InsightType) : undefined;

			const insight = Insight.fromType(key as InsightType, originInsightDataset, compareInsightDataset);

			if (!insight) {
				return data;
			}

			data.push(insight);

			return data;
		}, []);

		const insights = new Insights(mappedData, DEFAULT_SORT_ORDER);

		return insights;
	}

	static fromProviderInsights(originProviderInsight: ProviderInsights, compareProviderInsight?: ProviderInsights): Insights {
		const insightKeys = Object.keys(originProviderInsight.alltime) as InsightType[];

		return Insights.fromProviderInsightsAndKey(insightKeys, originProviderInsight, compareProviderInsight);
	}

	static createEmpty(days = 14) {
		const endDate = DateTime.utc();
		const startDate = endDate.minus({ days });

		const compareStartDate = startDate.minus({ years: 1 });
		const compareEndDate = endDate.minus({ years: 1 });

		const emptyProviderInsightsOrigin = ProviderInsights.createEmptyFromDate(startDate.toJSDate(), endDate.toJSDate());
		const emptyProviderInsightsCompare = ProviderInsights.createEmptyFromDate(compareStartDate.toJSDate(), compareEndDate.toJSDate());

		return Insights.fromProviderInsights(emptyProviderInsightsOrigin, emptyProviderInsightsCompare);
	}
}
