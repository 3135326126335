import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-[#F6F5FA] min-h-full" }
const _hoisted_2 = { class: "pl-[106px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheSidebar = _resolveComponent("TheSidebar")!
  const _component_TheHeader = _resolveComponent("TheHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TheSidebar, { class: "fixed z-10" }),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.hasCustomHeader)
        ? (_openBlock(), _createBlock(_component_TheHeader, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass({
					'px-20 pt-12 pb-24': !_ctx.hasCustomHeader
				})
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ])
  ]))
}