<template>
	<div
		class="relative w-full h-2 rounded-lg bg-purple-300 overflow-hidden"
	>
		<div
			class="h-full rounded-lg relative progress-bar overflow-hidden transition-all ease-in-out duration-1000"
			:style="`width: ${progress}%;`"
			:class="progress === fullValue ? 'bg-cyan-500' : 'bg-violet-500'"
		/>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
	name: 'AtomProgressBar',

	props: {
		fullValue: {
			type: Number,
			required: true,
		},
		progressValue: {
			type: Number,
			required: true,
		},
	},

	setup(props) {
		const progress = computed(() => (props.progressValue * 100) / props.fullValue);

		return {
			progress,
		};
	},
});
</script>

<style scoped>
.progress-bar::after {
    content: '';
    position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 100%;
  background-image: linear-gradient(
    -60deg,
    rgba(255, 255, 255, 0.25) 20%,
    transparent 20%,
    transparent 60%,
    rgba(255, 255, 255, 0.25) 60%,
    rgba(255, 255, 255, 0.25) 80%,
    transparent 80%,
    transparent
  );
  background-size: 20px 20px;
  animation: move 64s linear infinite;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>
