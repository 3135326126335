import { reactive, toRefs } from 'vue';

const state = reactive({
	isShacking: false,
});

export const useShake = () => {
	const shake = () => {
		state.isShacking = true;
	};

	return {
		...toRefs(state),
		shake,
	};
};
