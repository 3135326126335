import type { Cache } from 'fast-memoize';

export const createExpireableCache = <V>(timeToExpireInMS: number): Cache<string, V> => ({
	create() {
		const store: Record<string, { value: V, timestamp: number }> = {};
		return {
			has(key) {
				return (key in store);
			},
			get(key) {
				if (store[key] && store[key].timestamp + timeToExpireInMS < Date.now()) {
					delete store[key];
				}

				return store[key]?.value;
			},
			set(key, value) {
				store[key] = { value, timestamp: Date.now() };
			},
		};
	},
});
