import { vModelDynamic as _vModelDynamic, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-006b9df8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["type", "tabindex", "placeholder", "disabled", "readonly", "min", "max", "aria-label"]
const _hoisted_3 = {
  key: 0,
  class: "text-grey-500 text-sm mt-1"
}
const _hoisted_4 = {
  key: 1,
  class: "text-red-500 text-sm text-right mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        ref: "input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        type: _ctx.type,
        tabindex: _ctx.tabindex,
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        min: _ctx.minValue,
        max: _ctx.maxValue,
        "aria-label": _ctx.placeholder,
        class: _normalizeClass([
					_ctx.colorModeClass,
					'input w-full outline-0 border rounded-md text-sm px-4 placeholder-opacity-50',
					'disabled:cursor-not-allowed',
					{
						'h-12': _ctx.size === 'md',
						'h-9': _ctx.size === 'sm',
						'border-red-500 border-opacity-100': _ctx.isInvalid,
					}
				]),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        onKeypress: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["enter"]))
      }, null, 42, _hoisted_2), [
        [_vModelDynamic, _ctx.value]
      ]),
      (_ctx.isNumber)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["absolute right-0 top-0 bottom-0 h-full flex flex-col items-center justify-center mr-4 overflow-hidden", {
					'pointer-events-none': !_ctx.isNumberChangeable,
					'space-y-1': _ctx.size === 'sm',
					'space-y-2': _ctx.size === 'md',
				}])
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(["h-[10px] overflow-hidden flex justify-center items-center", _ctx.colorModeNumberArrowClass]),
              tabindex: "-1",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.increaseNumber && _ctx.increaseNumber(...args)))
            }, [
              _createVNode(_component_AtomIcon, {
                class: "transform",
                icon: "chevron-up"
              })
            ], 2),
            _createElementVNode("button", {
              class: _normalizeClass(["h-[10px] overflow-hidden flex justify-center items-center", _ctx.colorModeNumberArrowClass]),
              tabindex: "-1",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.decreaseNumber && _ctx.decreaseNumber(...args)))
            }, [
              _createVNode(_component_AtomIcon, {
                class: "transform",
                icon: "chevron-down"
              })
            ], 2)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.note)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.note), 1))
      : _createCommentVNode("", true),
    (_ctx.errorMessage && _ctx.isInvalid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}