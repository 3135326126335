import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/router/routes';
import { store } from '@/store';
import { useGlobalStore } from '@/store/global';
import { pinia } from '@/plugins/pinia';

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	await store.dispatch('auth/initAuthentication');

	const isAuthenticated = store.getters['auth/isAuthenticated'];
	const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
	const requiresGuest = to.matched.some((route) => route.meta.requiresGuest);

	if (requiresAuth && !isAuthenticated) {
		return next({ name: 'login', query: { redirectTo: to.fullPath } });
	}

	if (requiresGuest && isAuthenticated) {
		return next({ name: 'dashboard' });
	}

	return next();
});

router.afterEach((to) => {
	const globalStore = useGlobalStore(pinia);

	globalStore.viewLayout = to.meta.layout || 'default';

	nextTick(() => {
		let title = 'BiddingLab';

		if (to.meta.title) {
			const routeTitle = typeof to.meta.title === 'function' ? to.meta.title(to) : to.meta.title;

			title = `${routeTitle} - ${title}`;
		}

		document.title = title;
	});
});

export default router;
