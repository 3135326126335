import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomLabel = _resolveComponent("AtomLabel")!
  const _component_AtomInput = _resolveComponent("AtomInput")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
			'flex items-center': _ctx.orientation === 'horizontal'
		})
  }, [
    _createVNode(_component_AtomLabel, {
      label: _ctx.label,
      size: _ctx.labelSize,
      class: _normalizeClass(_ctx.computedLabelClass),
      "color-mode": _ctx.colorMode,
      required: _ctx.required
    }, null, 8, ["label", "size", "class", "color-mode", "required"]),
    _createVNode(_component_AtomInput, _mergeProps(_ctx.$attrs, {
      class: ["flex-1", _ctx.inputClass],
      "color-mode": _ctx.colorMode
    }), null, 16, ["class", "color-mode"])
  ], 2))
}