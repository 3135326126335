<template>
	<div
		class="w-5 h-5 rounded-sm border flex items-center justify-center outline-offset-2 focus:outline focus:outline-1"
		:class="[colorModeClass, {
			'cursor-not-allowed': disabled,
		}]"
		tabindex="0"
		@keypress.space="onClick"
		@keypress.enter="onClick"
		@click="onClick"
	>
		<AtomIcon
			v-if="modelValue"
			icon="check"
		/>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import AtomIcon from '../AtomIcon/AtomIcon.vue';

export enum CheckboxColorMode {
	LIGHT = 'light',
	DARK = 'dark',
}

const colorModeClassMap: Record<CheckboxColorMode, String> = {
	[CheckboxColorMode.LIGHT]: 'text-purple-500 border-purple-500 outline-purple-500 bg-white hover:border-purple-600 focus:border-purple-600 focus:bg-white disabled:bg-purple-200 disabled:border-purple-300 disabled:text-grey-500',
	[CheckboxColorMode.DARK]: 'text-white border-white bg-transparent outline-white hover:border-grey-300 disabled:opacity-50 disabled:border-white',
};

export default defineComponent({
	name: 'AtomCheckbox',

	components: {
		AtomIcon,
	},

	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		colorMode: {
			type: String as PropType<CheckboxColorMode>,
			default: CheckboxColorMode.LIGHT,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		'update:modelValue',
	],

	setup(props, { emit }) {
		const colorModeClass = computed(() => colorModeClassMap[props.colorMode]);

		const onClick = () => {
			emit('update:modelValue', !props.modelValue);
		};

		return {
			colorModeClass,
			onClick,
		};
	},
});
</script>
