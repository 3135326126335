<template>
	<div class="view view--fullheight">
		<div class="view-header">
			<div class="container">
				<img
					src="@/assets/logo-white.svg"
					alt="BiddingLab logo white"
				>
			</div>
		</div>
		<div class="view-body">
			<div class="container is-narrow">
				<div class="columns">
					<div class="column is-half is-offset-one-quarter has-text-center">
						<p class="view-title">
							{{ $t('view.forgotPassword.title') }}
						</p>
						<p class="view-subtitle">
							{{ $t('view.forgotPassword.subtitle') }}
						</p>
						<form @submit.prevent="requestPasswordReset">
							<div class="box box--login is-centered">
								<div class="box-body">
									<div class="input-fields">
										<AtomInput
											v-model="form.email"
											:label="$t('input.label.email')"
											:placeholder="$t('input.placeholder.enterYourEmail')"
											type="text"
										/>
									</div>
								</div>
								<div class="box-footer">
									<AtomButton
										:fullwidth="true"
										:loading="waitingForAuthServer"
									>
										{{ $t('button.submit') }}
									</AtomButton>
									<p
										v-show="formMessage"
										class="form-message"
									>
										{{ formMessage }}
									</p>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="view-footer">
			<div class="container">
				<div class="columns is-gapless">
					<div class="column column--copyright">
						<p class="copyright" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AtomInput from '@/components/atoms/AtomInput/AtomInput.vue';
import AtomButton from '@/components/atoms/AtomButton/AtomButton.vue';

export default {
	name: 'PasswordRequestReset',

	components: { AtomInput, AtomButton },

	data() {
		return {
			form: {
				email: '',
			},
			waitingForAuthServer: false,
		};
	},

	methods: {
		requestPasswordReset() {
			// Placeholder function
		},
	},
};
</script>

<style scoped>
/* .box--login {
	width: 440px;

	.box-footer {
		margin-top: $gap-3;
	}

	.box-subactions {
		display: flex;

		.link {
			margin-left: auto;
		}
	}

	.form-message {
		font-size: $size-8;
		color: $color-error;
		text-align: center;
		margin-top: $gap-1;
	}
} */
</style>
