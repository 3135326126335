<template>
	<button
		class="rounded-full relative overflow-hidden border border-violet-500 text-violet-500 box-border flex justify-center items-center transition-all"
		:class="classObject"
		:clickable="isClickable"
		@click="onClick"
	>
		<span class="font-sans font-semibold text-xs uppercase tracking-wider">
			<slot>{{ initials }}</slot>
		</span>
	</button>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { User, getInitialsFromUser } from '@/models/User';

export enum BadgeUserSize {
	SM = 'sm',
	MD = 'md',
}

const sizeClassMap: Record<BadgeUserSize, String> = {
	[BadgeUserSize.SM]: 'w-10 h-10',
	[BadgeUserSize.MD]: 'w-12 h-12',
};

export default defineComponent({
	name: 'AtomBadgeUser',

	props: {
		user: {
			type: Object as PropType<User>,
			required: true,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String as PropType<BadgeUserSize>,
			default: BadgeUserSize.MD,
		},
	},

	emits: [
		'click',
	],

	setup(props, { emit }) {
		const isClickable = computed(() => props.clickable);
		const sizeClass = computed(() => sizeClassMap[props.size]);
		const initials = computed(() => getInitialsFromUser(props.user));

		const classObject = computed(() => [
			{
				'cursor-default': !isClickable.value,
				'hover:text-white focus:outline focus:outline-1 outline-offset-2 hover:bg-violet-500': isClickable.value,
			},
			sizeClass.value,
		]);

		const onClick = ($event: Event) => {
			if (isClickable.value) {
				emit('click', $event);
			}
		};

		return {
			initials,
			onClick,
			classObject,
			isClickable,
			sizeClass,
		};
	},
});
</script>

<style scoped>

</style>
