import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "text-white text-sm opacity-75" }
const _hoisted_3 = { class: "text-white text-sm opacity-75" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomProgressBar = _resolveComponent("AtomProgressBar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_AtomProgressBar, {
      class: "mb-2",
      "progress-value": _ctx.learningProgress,
      "full-value": 100
    }, null, 8, ["progress-value"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.description), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.learningProgress) + "% ", 1)
    ])
  ]))
}