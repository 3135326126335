import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createElementBlock("button", _mergeProps({
    class: ["font-semibold text-sm overflow-hidden relative flex items-center justify-center box-border max-w-min whitespace-nowrap leading-none rounded-full font-sans transition-all ease-in-out py-2 px-3 cursor-pointer", _ctx.classObject]
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }), [
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ])
  ], 16)), [
    [_directive_ripple]
  ])
}