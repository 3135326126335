<template>
	<div
		:class="{
			'flex items-center': orientation === 'horizontal'
		}"
	>
		<AtomLabel
			v-if="label"
			:label="label"
			:size="labelSize"
			:class="labelClass"
			:color-mode="colorMode"
			:required="required"
		/>
		<div
			ref="wrapperRef"
			class="flex-1 relative"
		>
			<AtomSelect
				ref="selectRef"
				v-model:search="searchValue"
				v-bind="$attrs"
				:value="computedValue"
				class="flex-1"
				:color-mode="colorMode"
				@focus="showDropdownMenu = true"
				@blur="onSelectBlur"
			/>
			<teleport to="#poppers">
				<div
					ref="menuRef"
					class="z-50"
				>
					<transition name="fade-up-long">
						<AtomDropdownMenu
							v-show="showDropdownMenu"
							v-model="computedValue"
							:selectable="showDropdownMenu"
							:search="searchValue"
							:options="options"
							:multiselect="multiselect"
							:required="required"
							class="max-h-96"
							@update:modelValue="onSelect"
						/>
					</transition>
				</div>
			</teleport>
		</div>
	</div>
</template>

<script lang="ts">
import {
	computed, defineComponent, PropType, ref,
} from 'vue';
import { Placement } from '@popperjs/core';
import { usePopper } from '@/composables/usePopper';
import AtomSelect from '../../atoms/AtomSelect/AtomSelect.vue';
import AtomDropdownMenu from '../../atoms/AtomDropdownMenu/AtomDropdownMenu.vue';
import AtomLabel, { LabelSize } from '../../atoms/AtomLabel/AtomLabel.vue';

export enum SelectWithLabelOrientation {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal',
}

export enum SelectWithLabelColorMode {
	LIGHT = 'light',
	DARK = 'dark',
}

const labelSizeOrientationMap: Record<SelectWithLabelOrientation, LabelSize> = {
	[SelectWithLabelOrientation.VERTICAL]: LabelSize.SM,
	[SelectWithLabelOrientation.HORIZONTAL]: LabelSize.MD,
};

const labelClassOrientationClassMap: Record<SelectWithLabelOrientation, String> = {
	[SelectWithLabelOrientation.VERTICAL]: 'mb-2',
	[SelectWithLabelOrientation.HORIZONTAL]: 'mr-6',
};

export default defineComponent({
	name: 'MoleculeSelectWithLabel',

	components: {
		AtomSelect,
		AtomLabel,
		AtomDropdownMenu,
	},

	props: {
		modelValue: {
			type: [String, Number, Array] as PropType<string | number | (number | string)[]>,
			required: true,
		},
		options: {
			type: Array as PropType<(number | string)[]>,
			required: true,
		},
		label: {
			type: String,
			default: '',
		},
		orientation: {
			type: String as PropType<SelectWithLabelOrientation>,
			default: SelectWithLabelOrientation.VERTICAL,
		},
		colorMode: {
			type: String as PropType<SelectWithLabelColorMode>,
			default: SelectWithLabelColorMode.LIGHT,
		},
		required: {
			type: Boolean,
			default: false,
		},
		multiselect: {
			type: Boolean,
			default: false,
		},
		placement: {
			type: String as PropType<Placement>,
			default: 'bottom-start',
		},
		sameWidth: {
			type: Boolean,
			default: true,
		},
	},

	emits: [
		'update:modelValue',
	],

	setup(props, { emit }) {
		const labelSize = computed(() => labelSizeOrientationMap[props.orientation]);
		const labelClass = computed(() => labelClassOrientationClassMap[props.orientation]);

		const searchValue = ref('');

		const computedValue = computed({
			get() {
				return props.modelValue;
			},
			set(newValue) {
				emit('update:modelValue', newValue);
			},
		});

		const selectRef = ref<typeof AtomSelect>();
		const menuRef = ref<HTMLElement | null>(null);
		const wrapperRef = ref<HTMLElement | null>(null);

		const { isOpen: showDropdownMenu } = usePopper({
			placement: ref(props.placement),
			offsetSkid: ref('0'),
			offsetDistance: ref('8'),
			popperNode: menuRef,
			triggerNode: wrapperRef,
			options: {
				modifiers: [
					{
						name: 'sameWidth',
						enabled: props.sameWidth,
						fn: ({ state }) => {
							state.styles.popper.width = `${state.rects.reference.width}px`;
						},
						phase: 'beforeWrite',
						requires: ['computeStyles'],
					},
				],
			},
		});

		const closeDropdown = () => {
			showDropdownMenu.value = false;
		};

		const onSelectBlur = () => closeDropdown();

		const onSelect = () => {
			searchValue.value = '';

			if (!props.multiselect) {
				selectRef.value?.blur();
			}
		};

		return {
			labelSize,
			labelClass,
			showDropdownMenu,
			computedValue,
			searchValue,
			onSelectBlur,
			onSelect,
			selectRef,
			menuRef,
			wrapperRef,
		};
	},
});
</script>

<style>

</style>
