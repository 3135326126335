import { configure, defineRule } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import {
	required, email, min, alpha, confirmed, alpha_spaces as alphaSpaces, digits,
} from '@vee-validate/rules';
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';

// Install rules
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('alpha', alpha);
defineRule('confirmed', confirmed);
defineRule('alpha_spaces', alphaSpaces);
defineRule('digits', digits);

// setInteractionMode('aggressive-touch', () => ({
// 	on: ['input', 'change', 'blur'],
// }));

configure({
	generateMessage: localize({
		de,
		en,
	}),
});
