import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["clickable"]
const _hoisted_2 = { class: "font-sans font-semibold text-xs uppercase tracking-wider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["rounded-full relative overflow-hidden border border-violet-500 text-violet-500 box-border flex justify-center items-center transition-all", _ctx.classObject]),
    clickable: _ctx.isClickable,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.initials), 1)
      ])
    ])
  ], 10, _hoisted_1))
}