import { ref } from 'vue';

// const optionElement = optionsListRef.value.children[typeAheadPointer.value] as HTMLElement;

export const useAdjustScroll = (getOptionElement: (listElement: HTMLElement) => HTMLElement) => {
	const optionsListRef = ref<HTMLElement>();

	const getDropdownViewport = () => {
		const optionsListElement = optionsListRef.value;

		if (optionsListElement) {
			return optionsListElement.getBoundingClientRect();
		}

		return {
			height: 0,
			top: 0,
			bottom: 0,
		};
	};

	const maybeAdjustScroll = () => {
		if (!optionsListRef.value) {
			return;
		}

		const optionElement = getOptionElement(optionsListRef.value);

		if (!optionElement) {
			return;
		}

		const bounds = getDropdownViewport();
		const { top, bottom, height } = optionElement.getBoundingClientRect();

		if (top < bounds.top) {
			optionsListRef.value.scrollTop = optionElement.offsetTop;
			return;
		}

		if (bottom > bounds.bottom) {
			optionsListRef.value.scrollTop = optionElement.offsetTop - (bounds.height - height);
		}
	};

	return {
		optionsListRef,
		maybeAdjustScroll,
	};
};
