<template>
	<span
		class="font-sans flex box-border relative"
		:class="classObject"
		:required="isRequired"
	>
		<slot>
			{{ label }}
		</slot>
		<div v-if="isRequired">*</div>
	</span>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export enum LabelSize {
	SM = 'sm',
	MD = 'md',
}

export enum LabelColorMode {
	LIGHT = 'light',
	DARK = 'dark',
}

const sizeClassMap: Record<LabelSize, String> = {
	[LabelSize.SM]: 'text-sm font-semibold',
	[LabelSize.MD]: 'text-base font-normal',
};

const colorModeClassMap: Record<LabelColorMode, Record<LabelSize, String>> = {
	[LabelColorMode.LIGHT]: {
		[LabelSize.SM]: 'text-purple-900',
		[LabelSize.MD]: 'text-grey-500',
	},
	[LabelColorMode.DARK]: {
		[LabelSize.SM]: 'text-white',
		[LabelSize.MD]: 'text-white',
	},
};

export default defineComponent({
	name: 'AtomLabel',

	props: {
		size: {
			type: String as PropType<LabelSize>,
			default: LabelSize.MD,
		},
		label: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		colorMode: {
			type: String as PropType<LabelColorMode>,
			default: LabelColorMode.LIGHT,
		},
	},

	setup(props) {
		const isRequired = computed(() => props.required);

		const colorModeClass = computed(() => colorModeClassMap[props.colorMode][props.size]);
		const sizeClass = computed(() => sizeClassMap[props.size]);

		const classObject = computed(() => [
			colorModeClass.value,
			sizeClass.value,
		]);

		return {
			classObject,
			isRequired,
			colorModeClass,
		};
	},
});
</script>
