<template>
	<OrganismSidebar
		:navigation="firstLevelNavigation"
		version="V3.0.1"
	>
		<DevelopmentDetails />
	</OrganismSidebar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { firstLevelNavigation } from '@/utilities/Navigations';
import OrganismSidebar from './organisms/OrganismSidebar/OrganismSidebar.vue';
import DevelopmentDetails from './DevelopmentDetails.vue';

export default defineComponent({
	name: 'TheSidebar',

	components: {
		OrganismSidebar,
		DevelopmentDetails,
	},

	setup() {
		return {
			firstLevelNavigation,
		};
	},
});
</script>
