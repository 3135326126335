import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomChip = _resolveComponent("AtomChip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createBlock(_component_AtomChip, {
        key: option,
        "model-value": _ctx.isSelected(option),
        "onUpdate:modelValue": ($event: any) => (_ctx.onSelectOption($event, option))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getOptionLabel(option)), 1)
        ]),
        _: 2
      }, 1032, ["model-value", "onUpdate:modelValue"]))
    }), 128)),
    _renderSlot(_ctx.$slots, "default")
  ]))
}