import { reactive, toRefs, watch } from 'vue';
import { getSavedInsightVisibilitySettings, saveInsightVisibilitySettings } from '@/models/InsightVisibilitySettings';
import { Insight } from '@/models/Insight';

const state = reactive({
	insightsVisibilitySettings: getSavedInsightVisibilitySettings(),
});

watch(state.insightsVisibilitySettings, (newSettings) => {
	saveInsightVisibilitySettings(newSettings);
});

export const useInsightsVisibilitySettings = () => {
	const filterInsights = (insights: Insight[]): Insight[] => {
		const filteredInsights = insights.filter((insight) => !!state.insightsVisibilitySettings[insight.key]);
		return filteredInsights;
	};

	return {
		...toRefs(state),
		filterInsights,
	};
};
