import { RouteRecordRaw } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';

const routes: RouteRecordRaw[] = [
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		meta: {
			title: () => 'Dashboard',
			requiresAuth: true,
			customHeader: true,
		},
	},
	{
		path: '/strategies',
		name: 'strategiesOverview',
		component: () => import(/* webpackChunkName: "strategiesOverview" */ '@/views/StrategiesOverview.vue'),
		meta: {
			title: () => 'Strategies center',
			subheadline: 'Overview or manage your strategies',
			icon: 'strategy-alt',
			requiresAuth: true,
		},
		props: (route) => ({ ...route.query, ...route.params }),
		beforeEnter(to, from, next) {
			if (to.query.campaignId && !to.query.accountId) {
				next({ name: 'strategiesOverview' });
			}

			next();
		},
	},
	{
		path: '/strategy/:strategyId',
		name: 'strategySingle',
		props: (to) => ({ strategyId: parseInt(Array.isArray(to.params.strategyId) ? to.params.strategyId[0] : to.params.strategyId, 10) }),
		component: () => import(/* webpackChunkName: "strategiesOverview" */ '@/views/StrategySingle.vue'),
		meta: {
			title: () => 'Strategy Single',
			requiresAuth: true,
		},
	},
];

export default routes;
