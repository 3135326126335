import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pb-24" }
const _hoisted_2 = { class: "px-20 -mt-16 grid grid-cols-12 gap-6 items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganismHero = _resolveComponent("OrganismHero")!
  const _component_InsightList = _resolveComponent("InsightList")!
  const _component_OrganismPerformanceGraph = _resolveComponent("OrganismPerformanceGraph")!
  const _component_WidgetUpdates = _resolveComponent("WidgetUpdates")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_OrganismHero, {
      provider: _ctx.selectedProviderName,
      "onUpdate:provider": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedProviderName) = $event)),
      strategy: _ctx.selectedStrategyName,
      "onUpdate:strategy": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedStrategyName) = $event)),
      dateRange: _ctx.dateRange,
      "onUpdate:dateRange": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dateRange) = $event)),
      compareRange: _ctx.compareType,
      "onUpdate:compareRange": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.compareType) = $event)),
      "learning-strategy": _ctx.learningStrategy,
      strategies: _ctx.topLevelStrategiesOptions,
      "selected-insights": _ctx.selectedInsights,
      user: _ctx.currentUser,
      providers: _ctx.providerOptions,
      logout: _ctx.onLogout,
      headline: "Hi friend!",
      subheadline: "Welcome back to the mothership"
    }, null, 8, ["provider", "strategy", "dateRange", "compareRange", "learning-strategy", "strategies", "selected-insights", "user", "providers", "logout"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        name: "fade-up-long",
        mode: "out-in",
        appear: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InsightList, {
            "selected-insights": _ctx.selectedInsights,
            "onUpdate:selectedInsights": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedInsights) = $event)),
            style: {"transition-delay":".15s","transition-duration":".4s"},
            insights: _ctx.insights,
            loading: _ctx.isLoading,
            class: "col-span-12"
          }, null, 8, ["selected-insights", "insights", "loading"])
        ]),
        _: 1
      }),
      _createVNode(_Transition, {
        name: "fade-up-long",
        mode: "out-in",
        appear: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_OrganismPerformanceGraph, {
            class: "col-span-8 h-[450px] transition relative z-20",
            style: {"transition-delay":".3s","transition-duration":".4s"},
            insights: _ctx.insights,
            "selected-insights": _ctx.selectedInsights
          }, null, 8, ["insights", "selected-insights"])
        ]),
        _: 1
      }),
      _createVNode(_Transition, {
        name: "fade-up-long",
        mode: "out-in",
        appear: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_WidgetUpdates, {
            style: {"transition-delay":".3s","transition-duration":".4s"},
            class: "col-span-4"
          })
        ]),
        _: 1
      })
    ])
  ]))
}