import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-purple-200 rounded-lg py-5 px-6 flex flex-col" }
const _hoisted_2 = { class: "mb-2 flex items-center" }
const _hoisted_3 = { class: "text-xl font-bold" }
const _hoisted_4 = { class: "text-grey-600 text-sm" }
const _hoisted_5 = { class: "relative flex-1 grid" }
const _hoisted_6 = {
  ref: "tooltipRef",
  class: "pointer-events-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_VChart = _resolveComponent("VChart")!
  const _component_OrganismOverlayKPI = _resolveComponent("OrganismOverlayKPI")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AtomIcon, {
          icon: "trend-up",
          class: "mr-1 text-purple-500"
        }),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('widget.performanceGraph.title')), 1)
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('note.performanceGraph.selectKpi')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        ref: "chartWrapperRef",
        onMousemove: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseMove && _ctx.onMouseMove(...args))),
        onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args))),
        onFocusout: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
      }, [
        _createVNode(_component_VChart, {
          ref: "chartRef",
          option: _ctx.polar,
          autoresize: true,
          class: "w-full min-h-[250px]"
        }, null, 8, ["option"])
      ], 544),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createVNode(_component_OrganismOverlayKPI, {
          data: _ctx.tooltipData,
          class: "inline-block"
        }, null, 8, ["data"]), [
          [_vShow, _ctx.isOpen]
        ])
      ], 512)
    ])
  ]))
}