<template>
	<div
		class="relative flex items-center justify-center box-border"
		:class="classObject"
		v-bind="$attrs"
	>
		<span
			class="transition flex items-center transform z-10 relative"
		>
			<AtomIcon
				v-if="icon"
				:icon="icon"
				:size="size"
			/>
		</span>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { IconBadgeSize, IconBadgeColor } from '@/models/IconBadge';
import AtomIcon from '../AtomIcon/AtomIcon.vue';

const sizeClassMap: Record<IconBadgeSize, String> = {
	[IconBadgeSize.SM]: 'w-10 h-10 rounded-md',
	[IconBadgeSize.MD]: 'w-12 h-12 rounded-lg',
};

const colorClassMap: Record<IconBadgeColor, String> = {
	[IconBadgeColor.PURPLE]: 'bg-purple-300 text-purple-500',
	[IconBadgeColor.BLUE]: 'bg-blue-300 text-blue-500',
	[IconBadgeColor.MAGENTA]: 'bg-magenta-300 text-magenta-500',
	[IconBadgeColor.CYAN]: 'bg-cyan-300 text-cyan-500',
	[IconBadgeColor.RED]: 'bg-red-300 text-red-500',
	[IconBadgeColor.GREEN]: 'bg-green-300 text-green-500',
	[IconBadgeColor.ORANGE]: 'bg-orange-300 text-orange-500',
	[IconBadgeColor.VIOLET]: 'bg-violet-300 text-violet-500',
	[IconBadgeColor.GREY]: 'bg-grey-300 text-grey-500',
};

export default defineComponent({
	name: 'AtomIconBadge',

	components: {
		AtomIcon,
	},

	props: {
		color: {
			type: String as PropType<IconBadgeColor>,
			default: IconBadgeColor.PURPLE,
		},
		size: {
			type: String as PropType<IconBadgeSize>,
			default: IconBadgeSize.MD,
		},
		icon: {
			type: String,
			default: '',
		},
	},

	setup(props) {
		const colorClass = computed(() => colorClassMap[props.color]);
		const sizeClass = computed(() => sizeClassMap[props.size]);

		const classObject = computed(() => [
			colorClass.value,
			sizeClass.value,
		]);

		return {
			classObject,
			colorClass,
			sizeClass,
		};
	},
});

</script>
