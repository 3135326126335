<template>
	<div
		class="font-semibold font-sans flex flex-col max-w-max box-border whitespace-nowrap hover:text-purple-500 cursor-pointer"
		:class="classObject"
		v-bind="$attrs"
		:active="isActive"
		@click="onClick"
		@keydown.enter="onClick"
	>
		<span class="flex-1 grid items-center">
			<slot>{{ label }}</slot>
		</span>
		<div
			class="h-0.5 w-full"
			:class="{
				'bg-purple-500': isActive,
			}"
		/>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export enum TabBarItemSize {
	SM = 'sm',
	MD = 'md',
}

const sizeClassMap: Record<TabBarItemSize, String> = {
	[TabBarItemSize.SM]: 'text-sm space-y-[6px]',
	[TabBarItemSize.MD]: 'text-base h-12',
};

export default defineComponent({
	name: 'AtomTabBarItem',

	props: {
		size: {
			type: String as PropType<TabBarItemSize>,
			default: TabBarItemSize.MD,
		},
		label: {
			type: String,
			default: '',
		},
		modelValue: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		'update:modelValue',
	],

	setup(props, { emit }) {
		const isActive = computed(() => props.modelValue);

		const sizeClass = computed(() => sizeClassMap[props.size]);

		const classObject = computed(() => [
			{
				'text-purple-500': isActive.value,
				'text-grey-500': !isActive.value,
			},
			sizeClass.value,
		]);

		const onClick = () => {
			emit('update:modelValue', !props.modelValue);
		};

		return {
			classObject,
			isActive,
			onClick,
		};
	},
});
</script>
