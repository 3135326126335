import { Options } from 'ky';
import { errAsync, okAsync, ResultAsync } from 'neverthrow';
import { APIResponse } from '@/models/APIResponse';
import { Strategy } from '@/models/Strategy';
import { CustomSearchParams } from '@/types/CustomSearchParams';
import { withStrategyMemoize } from '@/utilities/memoize';
import { ressourceClient } from './clients/ressourceClient';
import { APIClient, responseWithErrorHandler } from './clients';

export const fetchStrategy = withStrategyMemoize((strategyId: number, options?: Options): ResultAsync<Strategy, Error> => {
	const data: Promise<APIResponse<Strategy>> = APIClient.useWithLoaderToJson(ressourceClient.client.get(`strategies/${strategyId}`, {
		...options,
	}));

	return responseWithErrorHandler(data)
		.andThen((response) => {
			const strategy = response.Objects[0];

			if (!strategy) {
				return errAsync(new Error('strategy.notFound'));
			}

			return okAsync(strategy);
		});
});

export const fetchStrategies = (providerId: number, params?: CustomSearchParams, options?: Options): ResultAsync<Strategy[], Error> => {
	const data: Promise<APIResponse<Strategy>> = APIClient.useWithLoaderToJson(ressourceClient.client.get('strategies', {
		...options,
		searchParams: {
			...params,
			ProviderLink_Id: providerId,
		},
	}));

	return responseWithErrorHandler(data).map((response) => response.Objects);
};

export const fetchTopLevelStrategies = (providerId: number, params?: CustomSearchParams, options?: Options): ResultAsync<Strategy[], Error> => fetchStrategies(providerId, { ...params, ParentStrategyLink_Id: 'null' }, options);

export const getTopLevelStrategy = (strategy: Strategy, options?: Options): ResultAsync<Strategy, Error> => {
	if (strategy.ParentStrategyLink_Id) {
		return fetchStrategy(strategy.ParentStrategyLink_Id, options)
			.andThen((parentStrategy) => getTopLevelStrategy(parentStrategy, options));
	}

	return okAsync(strategy);
};

export const getParentStrategy = (strategy: Strategy, options?: Options): ResultAsync<Strategy, Error> => {
	if (!strategy.ParentStrategyLink_Id) {
		return errAsync(new Error('strategy.hasNoParentStrategy'));
	}

	return fetchStrategy(strategy.ParentStrategyLink_Id, options);
};

export const getParentStrategies = (strategy: Strategy, options?: Options): ResultAsync<Strategy[], Error> => {
	const parentStrategies: Strategy[] = [];

	const fetchParentStrategy = (strategyToFetch: Strategy): ResultAsync<Strategy[], Error> => {
		if (!strategyToFetch.ParentStrategyLink_Id) {
			return okAsync(parentStrategies);
		}

		return getParentStrategy(strategyToFetch, options)
			.andThen((parentStrategy) => {
				parentStrategies.push(parentStrategy);
				return fetchParentStrategy(parentStrategy);
			})
			.orElse(() => okAsync(parentStrategies));
	};

	return fetchParentStrategy(strategy);
};
