<template>
	<div class="bg-purple-200 rounded-lg p-6">
		<div class="flex items-center mb-2">
			<AtomIcon
				icon="updates"
				class="mr-2 text-purple-500"
			/>
			<p class="text-lg font-bold">
				{{ $t('widget.updates.title') }}
			</p>
		</div>
		<p class="text-sm text-grey-600 mb-6">
			<i18n-t :keypath="`widget.updates.totalUpdatesInRange_${MUTATION_DATE_RANGE}`">
				<strong>{{ formattedCount }}</strong>
			</i18n-t>
		</p>
		<MoleculeTabBar
			:items="updateCategories"
			:model-value="updateCategory"
			:has-divider="false"
			size="sm"
			class="mb-4"
			@update:modelValue="$emit('update:updateCategory', $event)"
		/>
		<transition
			enter-from-class="opacity-0 -translate-y-1"
			enter-active-class="transform duration-300"
			leave-active-class="transform duration-300"
			leave-to-class="opacity-0 translate-y-1"
			mode="out-in"
		>
			<div
				v-if="loading"
				class="divide-y divide-purple-300 space-y-3 relative"
			>
				<div
					v-for="item in 5"
					:key="item"
					class="pt-3 first:pt-0 flex"
				>
					<AppPlaceholder class="w-12 h-12 bg-purple-300 mr-3" />
					<div class="flex-1">
						<AppPlaceholder class="w-full h-3 bg-purple-300" />
						<AppPlaceholder class="w-32 h-2 bg-purple-300" />
					</div>
				</div>
			</div>
			<div
				v-else-if="updates.length > 0"
				:key="JSON.stringify(updates)"
				class="divide-y divide-purple-300 space-y-3 relative"
			>
				<div
					v-for="update in displayData"
					:key="update.date"
					class="pt-3 first:pt-0 inline-block w-full"
				>
					<MoleculeNotificationCard
						v-bind="toIconBadge(update.type)"
						:date="update.date"
					>
						<!-- eslint-disable-next-line vue/no-v-html -->
						<p v-html="update.content" />
					</MoleculeNotificationCard>
				</div>
			</div>
			<MoleculeFlashMessage
				v-else
				hide-close
			>
				{{ $t('updates.list.noUpdatesFound') }}
			</MoleculeFlashMessage>
		</transition>
	</div>
</template>

<script lang="ts">
import numeral from 'numeral';
import { computed, defineComponent, PropType } from 'vue';
import { MUTATION_DATE_RANGE } from '@/utilities/Constants';
import { UpdateItem } from '@/models/UpdateItem';
import { toIconBadge } from '@/models/MutationType';
import AtomIcon from '@/components/atoms/AtomIcon/AtomIcon.vue';
import MoleculeNotificationCard from '@/components/molecules/MoleculeNotificationCard/MoleculeNotificationCard.vue';
import MoleculeFlashMessage from '@/components/molecules/MoleculeFlashMessage/MoleculeFlashMessage.vue';
import MoleculeTabBar from '@/components/molecules/MoleculeTabBar/MoleculeTabBar.vue';

export default defineComponent({
	name: 'OrganismUpdatesList',

	components: {
		AtomIcon, MoleculeNotificationCard, MoleculeFlashMessage, MoleculeTabBar,
	},

	props: {
		updates: {
			type: Array as PropType<UpdateItem[]>,
			default: () => [],
		},
		updateCategory: {
			type: String,
			default: 'All',
		},
		changesCount: {
			type: Number,
			default: 0,
		},
		total: {
			type: Number,
			default: 5,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		'update:updateCategory',
	],

	setup(props) {
		const updateCategories = [
			'All',
			'Bids',
			'Keywords',
		];

		const displayData = computed(() => {
			if (props.total === -1) {
				return props.updates;
			}

			return props.updates.slice(0, props.total);
		});

		const formattedCount = computed(() => numeral(props.changesCount).format('(0,0)'));

		return {
			updateCategories,
			displayData,
			toIconBadge,
			MUTATION_DATE_RANGE,
			formattedCount,
		};
	},
});
</script>
