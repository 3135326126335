<template>
	<div class="relative transition-all z-10">
		<div
			ref="scrollContainer"
			class="w-full box-border overflow-x-auto overflow-y-hidden pb-[10px] flex z-10 relative"
			:class="{
				'px-20': hasDivider,
				'space-x-8': size === 'md',
				'space-x-4': size === 'sm'
			}"
			@wheel="onWheel"
		>
			<slot>
				<AtomTabBarItem
					v-for="item in limitedItems"
					:key="item"
					:model-value="isSelected(item)"
					:size="size"
					@update:modelValue="onSelectedItem($event, item)"
				>
					{{ item }}
				</AtomTabBarItem>
			</slot>
		</div>
		<AtomDivider
			v-if="hasDivider"
			color-mode="light"
			size="sm"
			class="absolute left-0 right-0 z-0 bottom-[10px]"
		/>
	</div>
</template>

<script lang="ts">
import {
	computed, defineComponent, PropType, ref,
} from 'vue';
import AtomTabBarItem from '../../atoms/AtomTabBarItem/AtomTabBarItem.vue';
import AtomDivider from '../../atoms/AtomDivider/AtomDivider.vue';

export enum TabBarSize {
	SM = 'sm',
	MD = 'md',
}

export default defineComponent({
	name: 'MoleculeTabBar',

	components: {
		AtomTabBarItem,
		AtomDivider,
	},

	props: {
		items: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		limit: {
			type: Number,
			default: null,
		},
		modelValue: {
			type: String,
			default: '',
		},
		hasDivider: {
			type: Boolean,
			default: true,
		},
		size: {
			type: String as PropType<TabBarSize>,
			default: TabBarSize.MD,
		},
	},

	emits: [
		'update:modelValue',
	],

	setup(props, { emit }) {
		const scrollContainer = ref<HTMLElement>();

		const isSelected = (item: string) => item === props.modelValue;

		const onSelectedItem = (select: boolean, item: string) => {
			if (select) {
				return emit('update:modelValue', item);
			}

			return emit('update:modelValue', '');
		};

		const limitedItems = computed(() => {
			if (props.limit && props.items.length > props.limit) {
				return props.items.slice(0, props.limit);
			}

			return props.items;
		});

		const onWheel = (event: WheelEvent) => {
			event.preventDefault();

			if (!scrollContainer.value) {
				return;
			}

			scrollContainer.value.scrollLeft += event.deltaY;
		};

		return {
			scrollContainer,
			onSelectedItem,
			isSelected,
			limitedItems,
			onWheel,
		};
	},
});
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  height: 6px !important;
  width: 267px !important;
  scrollbar-width: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D4CBE4 !important;
  border-radius: 66px !important;
  scrollbar-color: #D4CBE4 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #D4CBE4 !important;
  scrollbar-color: #D4CBE4 !important;
}
</style>
