import {
	ref, computed, watch, Ref, unref,
} from 'vue';
import { DateTime } from 'luxon';
import { errAsync, ResultAsync } from 'neverthrow';
import {
	onBeforeRouteUpdate, RouteLocationNormalizedLoaded, useRouter,
} from 'vue-router';
import { Insight } from '@/models/Insight';
import { ComparableDateRange, toDateRange } from '@/models/ComparableDateRange';
import {
	getDateTime, getJSDate, isSameDay, toLocaleISOTime,
} from '@/utilities/Helpers';
import { createEmptyProviderInsightsByDate, ProviderInsights } from '@/models/ProviderInsights';
import { getAlltimeInsights, getDailyInsights } from '@/api/insights';
import { Insights } from '@/models/Insights';
import { CustomSearchParams } from '@/types/CustomSearchParams';
import { CompareType } from '@/enums/CompareType';
import { MaybeRef } from '@/utilities/Types';
import { DateRange } from '@/models/DateRange';
import { useSelectionStorage } from './useSelectionStorage';

export interface UseStrategyInsightsDefaults {
	startDate?: MaybeRef<Date>;
	endDate?: MaybeRef<Date>;
	compareRange?: MaybeRef<CompareType | DateRange>;
	defaultStartDate?: MaybeRef<Date>;
}

export interface UseStrategyInsightsOptions extends UseStrategyInsightsDefaults {
	providerId: Ref<number | null>;
	strategyId?: Ref<number | null>;
}

export const getStartDateFromRouteQuery = (r: RouteLocationNormalizedLoaded) => {
	if (r.query.start && typeof r.query.start === 'string') {
		return DateTime.fromISO(r.query.start).toJSDate();
	}

	return null;
};

export const getEndDateFromRouteQuery = (route: RouteLocationNormalizedLoaded) => {
	if (route.query.end && typeof route.query.end === 'string') {
		return DateTime.fromISO(route.query.end).toJSDate();
	}

	return null;
};

export const getCompareRangeTypeFromRouteQuery = (route: RouteLocationNormalizedLoaded): CompareType | DateRange | null => {
	if (route.query.compare && typeof route.query.compare === 'string') {
		const splittedCompareQuery = route.query.compare.split(',');

		if (splittedCompareQuery.length === 2) {
			return {
				start: DateTime.fromISO(splittedCompareQuery[0]),
				end: DateTime.fromISO(splittedCompareQuery[1]),
			};
		}

		return route.query.compare as CompareType;
	}

	return null;
};

export const getValuesFromRoute = (route: RouteLocationNormalizedLoaded): UseStrategyInsightsDefaults => {
	const startDate = getStartDateFromRouteQuery(route);
	const endDate = getEndDateFromRouteQuery(route);
	const compareRange = getCompareRangeTypeFromRouteQuery(route);

	return {
		...(startDate ? { startDate } : {}),
		...(endDate ? { endDate } : {}),
		...(compareRange ? { compareRange } : {}),
	};
};

const hasStrategyOption = (options: UseStrategyInsightsOptions): options is UseStrategyInsightsOptions & { strategyId: Ref<number> } => options.strategyId !== undefined && typeof options.strategyId.value === 'number';

export const useStrategyInsights = (options: UseStrategyInsightsOptions) => {
	const {
		compareType, startDate, endDate,
	} = useSelectionStorage(options, unref(options.defaultStartDate));

	const dateRange = computed<DateRange>({
		get() {
			return { start: startDate.value, end: endDate.value };
		},
		set(newDateRange) {
			startDate.value = getJSDate(newDateRange.start);
			endDate.value = getJSDate(newDateRange.end);
		},
	});

	const insights = ref<Insight[]>([]);
	const isLoading = ref(false);

	const compareableDateRange = computed(() => new ComparableDateRange(getJSDate(dateRange.value.start), getJSDate(dateRange.value.end)));

	const compareRange = computed<DateRange>(() => {
		if (typeof compareType.value === 'string') {
			const d = compareableDateRange.value.toCompareType(compareType.value);
			return toDateRange(d);
		}

		return compareType.value;
	});

	const formattedDateRange = computed(() => ({
		startDate: getDateTime(dateRange.value.start).toFormat('yyyy-LL-dd'),
		endDate: getDateTime(dateRange.value.end).toFormat('yyyy-LL-dd'),
	}));

	const fetchInsightsByDateRange = (start: Date, end: Date): ResultAsync<ProviderInsights, Error> => {
		if (options.providerId.value === null) {
			return errAsync(new Error('Can not use fetchInsightsByDateRange without ProviderId'));
		}

		const defaultParams: CustomSearchParams = {
			...(hasStrategyOption(options) ? { StrategyLink_Id: options.strategyId.value } : { ProviderLink_Id: options.providerId.value }),
			InsightDate: `${toLocaleISOTime(start)}, ${toLocaleISOTime(end)}`,
			order: 'insightdate desc',
		};

		const path = hasStrategyOption(options) ? 'StrategyInsights' : 'ProviderInsights';

		const requestDaily = getDailyInsights(path, defaultParams);
		const requestAlltime = getAlltimeInsights(path, defaultParams);

		return ResultAsync.combine([requestDaily, requestAlltime])
			.map(([responseDaily, responseAlltime]) => {
				const alltime = responseAlltime[0] ?? createEmptyProviderInsightsByDate(start);
				return new ProviderInsights(
					start,
					end,
					alltime,
					responseDaily,
				);
			});
	};

	const fetch = () => {
		isLoading.value = true;

		const providerInsightsOriginRequest = fetchInsightsByDateRange(getJSDate(dateRange.value.start), getJSDate(dateRange.value.end));
		const providerInsightsCompareRequest = fetchInsightsByDateRange(getJSDate(compareRange.value.start), getJSDate(compareRange.value.end));

		return ResultAsync.combine([providerInsightsOriginRequest, providerInsightsCompareRequest])
			.map(([providerInsightsOrigin, providerInsightsCompare]) => {
				isLoading.value = false;

				const mappedInsights = Insights.fromProviderInsights(providerInsightsOrigin, providerInsightsCompare);
				insights.value = mappedInsights.insightsByKey;

				return insights.value;
			})
			.mapErr((error) => {
				isLoading.value = false;
				return error;
			});
	};

	const createUpdateWatcher = () => watch([dateRange, compareType, options.providerId, options.strategyId], (value, oldValue) => {
		if (JSON.stringify(value) === JSON.stringify(oldValue)) {
			return;
		}

		fetch();
	});

	const fetchAndWatch = () => fetch()
		.map((response) => {
			createUpdateWatcher();
			return response;
		});

	const updateValuesByRoute = (route: RouteLocationNormalizedLoaded) => {
		const values = getValuesFromRoute(route);

		const newDateRange = { ...dateRange.value };

		if (values.startDate) {
			newDateRange.start = unref(values.startDate);
		}

		if (values.endDate) {
			newDateRange.end = unref(values.endDate);
		}

		dateRange.value = newDateRange;

		if (values.compareRange) {
			compareType.value = unref(values.compareRange);
		}
	};

	const router = useRouter();

	const toCompareQuery = (input: CompareType | DateRange): string => {
		if (typeof input === 'string') {
			return input;
		}

		return `${getDateTime(input.start).toISODate()},${getDateTime(input.end).toISODate()}`;
	};

	const updateQuery = () => {
		const currentQuery = router.currentRoute.value.query;
		const newQuery = { ...currentQuery };

		newQuery.compare = toCompareQuery(compareType.value);
		newQuery.start = formattedDateRange.value.startDate;
		newQuery.end = formattedDateRange.value.endDate;

		if (currentQuery.compare || currentQuery.start || currentQuery.end) {
			router.push({ ...router.currentRoute.value, query: newQuery });
		} else {
			router.replace({ ...router.currentRoute.value, query: newQuery });
		}
	};

	watch(insights, () => {
		updateQuery();
	});

	const hasQueryUpdates = (route: RouteLocationNormalizedLoaded) => {
		const values = getValuesFromRoute(route);

		if (values.startDate && !isSameDay(unref(values.startDate), getJSDate(dateRange.value.start))) {
			return true;
		}

		if (values.endDate && !isSameDay(unref(values.endDate), getJSDate(dateRange.value.end))) {
			return true;
		}

		if (values.compareRange && values.compareRange !== compareType.value) {
			return true;
		}

		return false;
	};

	onBeforeRouteUpdate((to, from) => {
		if (JSON.stringify(to.query) === JSON.stringify(from.query)) {
			return;
		}

		if (!hasQueryUpdates(to)) {
			return;
		}

		updateValuesByRoute(to);
	});

	return {
		isLoading,
		dateRange,
		compareType,
		insights,
		compareableDateRange,
		compareRange,
		formattedDateRange,
		fetch,
		createUpdateWatcher,
		fetchAndWatch,
		getValuesFromRoute,
		updateValuesByRoute,
	};
};
