import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DevelopmentDetails = _resolveComponent("DevelopmentDetails")!
  const _component_OrganismSidebar = _resolveComponent("OrganismSidebar")!

  return (_openBlock(), _createBlock(_component_OrganismSidebar, {
    navigation: _ctx.firstLevelNavigation,
    version: "V3.0.1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DevelopmentDetails)
    ]),
    _: 1
  }, 8, ["navigation"]))
}