/* eslint-disable camelcase */
import i18n from '@/plugins/i18n';
import { MutationType } from './MutationType';

export interface MutationLogItem {
	MutationDate: string,
	OldCpc: 0.13,
	NewCpc: 0.03,
	Name: string,
	ParentName: string,
	MutationType: MutationType,
	StrategyLink_Id: number,
	CredentialLink_Id: number,
	ProviderLink_Id: number
}

export const getContentFromMutationLogItem = (item: MutationLogItem): string => {
	if (item.MutationType === 'Cpc') {
		return i18n.global.t('updates.list.item.cpc', {
			itemName: `<span class="font-semibold">${item.Name}</span>`,
			oldCpc: item.OldCpc,
			newCpc: item.NewCpc,
		});
	}

	return i18n.global.t('updates.list.item.reactor', {
		itemName: `<span class="font-semibold">${item.Name}</span>`,
		parentName: item.ParentName,
	});
};
