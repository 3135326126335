<template>
	<div class="pb-24">
		<OrganismHero
			v-model:provider="selectedProviderName"
			v-model:strategy="selectedStrategyName"
			v-model:dateRange="dateRange"
			v-model:compareRange="compareType"
			:learning-strategy="learningStrategy"
			:strategies="topLevelStrategiesOptions"
			:selected-insights="selectedInsights"
			:user="currentUser"
			:providers="providerOptions"
			:logout="onLogout"
			headline="Hi friend!"
			subheadline="Welcome back to the mothership"
		/>
		<div class="px-20 -mt-16 grid grid-cols-12 gap-6 items-start">
			<transition
				name="fade-up-long"
				mode="out-in"
				appear
			>
				<InsightList
					v-model:selected-insights="selectedInsights"
					style="transition-delay: .15s; transition-duration: .4s"
					:insights="insights"
					:loading="isLoading"
					class="col-span-12"
				/>
			</transition>
			<transition
				name="fade-up-long"
				mode="out-in"
				appear
			>
				<OrganismPerformanceGraph
					class="col-span-8 h-[450px] transition relative z-20"
					style="transition-delay: .3s; transition-duration: .4s"
					:insights="insights"
					:selected-insights="selectedInsights"
				/>
			</transition>
			<transition
				name="fade-up-long"
				mode="out-in"
				appear
			>
				<WidgetUpdates
					style="transition-delay: .3s; transition-duration: .4s"
					class="col-span-4"
				/>
			</transition>
		</div>
	</div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import {
	computed, defineComponent, nextTick, onMounted, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import OrganismHero from '@/components/organisms/OrganismHero/OrganismHero.vue';
import OrganismPerformanceGraph from '@/components/organisms/OrganismPerformanceGraph/OrganismPerformanceGraph.vue';
import WidgetUpdates from '@/components/WidgetUpdates.vue';
import InsightList from '@/components/InsightList.vue';
import { useProviderLearningProgress } from '@/composables/useProviderLearningProgress';
import { useStrategyStore } from '@/store/strategy';
import { useUserStore } from '@/store/user';
import { getValuesFromRoute, useStrategyInsights } from '@/composables/useStrategyInsights';
import { ensureDate, getStrategyIdFromRoute } from '@/utilities/Helpers';
import { useProvider } from '@/composables/useProvider';
import { useInsightsVisibilitySettings } from '@/composables/useInsightsVisibilitySettings';

export default defineComponent({
	name: 'DasboardView',

	components: {
		OrganismHero,
		OrganismPerformanceGraph,
		WidgetUpdates,
		InsightList,
	},

	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		const { currentUser } = useUserStore();
		const providerId = computed<number | null>(() => (store.getters['provider/selectedProvider'] ? store.getters['provider/selectedProvider'].Id : null));
		const { strategy: lowestProgressStrategy } = useProviderLearningProgress({ providerId });

		const {
			selectedStrategyName,
			topLevelStrategiesOptions,
			selectedStrategy,
			topLevelStrategies,
			selectedStrategyId,
			useProviderId,
			selectedInsights,
		} = useStrategyStore(providerId.value);

		useProviderId(providerId);

		const learningStrategy = computed(() => {
			if (selectedStrategy.value) {
				return selectedStrategy.value;
			}

			return lowestProgressStrategy.value;
		});

		const providersIntegrationDate = computed(() => store.getters['provider/providersIntegrationDate']);
		const defaultStartDate = computed(() => ensureDate(providersIntegrationDate.value.toJSDate()));

		const {
			isLoading,
			insights: unfilteredInsights,
			fetchAndWatch,
			dateRange,
			compareType,
		} = useStrategyInsights({
			providerId,
			strategyId: selectedStrategyId,
			defaultStartDate,
			...getValuesFromRoute(route),
		});

		const { filterInsights } = useInsightsVisibilitySettings();

		const insights = computed(() => filterInsights(unfilteredInsights.value));

		onMounted(() => {
			nextTick(() => {
				fetchAndWatch();
			});
		});

		const updateQuery = () => {
			const currentQuery = route.query;
			const newQuery = { ...currentQuery };

			if (selectedStrategy.value) {
				newQuery.strategy = selectedStrategy.value.Id.toString();
			} else {
				delete newQuery.strategy;
			}

			router.push({ ...route, query: newQuery });
		};

		const mapQuery = () => {
			const routeStrategyId = getStrategyIdFromRoute(route);

			if (!routeStrategyId) {
				return;
			}

			const topLevelStrategyWatcher = watch(topLevelStrategies, (tls) => {
				if (!tls) {
					return;
				}

				topLevelStrategyWatcher();

				const strategy = topLevelStrategies.value.find((s) => s.Id === routeStrategyId);

				if (!strategy) {
					return;
				}

				selectedStrategyName.value = strategy.Name;

				updateQuery();
			});
		};

		const progressStrategy = computed(() => {
			if (selectedStrategy.value) {
				return selectedStrategy.value;
			}

			return lowestProgressStrategy.value;
		});

		onMounted(() => {
			mapQuery();
		});

		watch(selectedStrategy, () => {
			updateQuery();
		});

		const { selectedProviderName, providerOptions } = useProvider();

		const onLogout = async () => {
			store.dispatch('auth/logoutUser');
			router.push({ name: 'login' });
		};

		return {
			selectedStrategyName,
			topLevelStrategiesOptions,
			progressStrategy,

			currentUser,
			onLogout,
			providerOptions,
			selectedProviderName,

			insights,
			isLoading,
			dateRange,
			compareType,
			selectedInsights,
			learningStrategy,
		};
	},
});
</script>
