import numeral from 'numeral';
import { InsightType } from '@/enums/InsightType';
import { InsightDataset } from './InsightDataset';

const faEye = 'faEye';
const faMouse = 'faMouse';
const faLongArrowAltRight = 'faLongArrowAltRight';
const faEuroSign = 'faEuroSign';
const faReceipt = 'faReceipt';
const faCoins = 'faCoins';
const faLongArrowAltLeft = 'faLongArrowAltLeft';

export type TrendIndicator = 'none' | 'up' | 'down';

export interface InsightDefaultOptions {
	key: InsightType;
	id: string;
	displayName: string;
	color: string[];
	icon: String;
	formatter: (value: number) => string;
	positiveTrendIndicator?: TrendIndicator;
	hasAverage?: boolean;
}

export interface InsightOptions extends InsightDefaultOptions {
	originDataset: InsightDataset;
	compareDataset?: InsightDataset;
}

export type InsightDefault = Record<InsightType, InsightDefaultOptions>

export const insightDefaults: InsightDefault = {
	[InsightType.IMPRESSIONS]: {
		key: InsightType.IMPRESSIONS,
		id: 'ImpressionsInsight',
		displayName: 'Impressions',
		color: ['#512E95', '#FFCBB8', '#FFEFED'],
		icon: faEye,
		formatter: (value) => numeral(value).format('(0,0)'),
	},
	[InsightType.CLICKS]: {
		key: InsightType.CLICKS,
		id: 'ClicksInsight',
		displayName: 'Clicks',
		color: ['#2E27A0', '#B3E5FF', '#E8F7FF'],
		icon: faMouse,
		formatter: (value) => numeral(value).format('(0,0)'),
	},
	[InsightType.CTR]: {
		key: InsightType.CTR,
		id: 'CtrInsight',
		displayName: 'CTR',
		color: ['#872596', '#B3E5FF', '#E8F7FF'],
		icon: faLongArrowAltRight,
		formatter: (value) => `${numeral(value).format('(0,0.00)')}%`,
		hasAverage: false,
	},
	[InsightType.CPC]: {
		key: InsightType.CPC,
		id: 'CpcInsight',
		displayName: 'CPC',
		color: ['#00A4AB', '#FFCBD2', '#FFF0F3'],
		icon: faEuroSign,
		formatter: (value) => numeral(value).format('(0,0.00)$'),
		hasAverage: false,
	},
	[InsightType.COST]: {
		key: InsightType.COST,
		id: 'CostInsight',
		displayName: 'Costs',
		color: ['#FF274F', '#FFEF67', '#FCFBEA'],
		icon: faEuroSign,
		formatter: (value) => numeral(value).format('(0,0.00)$'),
	},
	[InsightType.CONVERSIONS]: {
		key: InsightType.CONVERSIONS,
		id: 'ConversionsInsight',
		displayName: 'Conversions',
		color: ['#00AB4A', '#A1FFA5', '#EAFFEA'],
		icon: faReceipt,
		formatter: (value) => numeral(value).format('(0,0)'),
	},
	[InsightType.COST_PER_CONVERSION]: {
		key: InsightType.COST_PER_CONVERSION,
		id: 'CostPerConversionInsight',
		displayName: 'CPA',
		color: ['#FF7028', '#D5FF9A', '#F5FFE7'],
		icon: faEuroSign,
		formatter: (value) => numeral(value).format('(0,0.00)$'),
		positiveTrendIndicator: 'down',
		hasAverage: false,
	},
	[InsightType.ROAS]: {
		key: InsightType.ROAS,
		id: 'RoasInsight',
		displayName: 'ROAS',
		color: ['#8349FF', '#FFCBB8', '#FFEFED'],
		icon: faLongArrowAltLeft,
		formatter: (value) => `${numeral(value * 100).format('(0,0)')}%`,
		positiveTrendIndicator: 'up',
		hasAverage: false,
	},
	[InsightType.CONVERSION_VALUE]: {
		key: InsightType.CONVERSION_VALUE,
		id: 'ConversionValueInsight',
		displayName: 'Conversion Value',
		color: ['#736986', '#FFCBB8', '#FFEFED'],
		icon: faCoins,
		formatter: (value) => numeral(value).format('(0,0.00)$'),
		positiveTrendIndicator: 'up',
		hasAverage: false,
	},
};

export class Insight {
	id: string;
	key: InsightType;
	displayName: string;
	color: string[];
	icon: String;
	formatter: (value: number) => string;
	originDataset: InsightDataset;
	compareDataset?: InsightDataset;
	positiveTrendIndicator: TrendIndicator;
	hasAverage: boolean;

	constructor(options: InsightOptions) {
		this.id = options.id;
		this.key = options.key;
		this.displayName = options.displayName;
		this.color = options.color;
		this.icon = options.icon;
		this.formatter = options.formatter;
		this.originDataset = options.originDataset;
		this.compareDataset = options.compareDataset;
		this.positiveTrendIndicator = options.positiveTrendIndicator || 'none';
		this.hasAverage = typeof options.hasAverage === 'undefined' ? true : options.hasAverage;
	}

	getDatasetByTerm(term: 'origin' | 'compare'): InsightDataset | null {
		if (term === 'origin') {
			return this.originDataset;
		}

		return this.compareDataset || null;
	}

	formatValue(value: number): string | number {
		if (typeof this.formatter === 'function') {
			return this.formatter(value);
		}

		return value;
	}

	getDateValueArray(fromDataset: 'origin' | 'compare'): (number | Date)[][] {
		const dataset = fromDataset === 'origin' ? this.originDataset : this.compareDataset;

		if (!dataset) {
			return [];
		}

		const dateValueArray = dataset.completeDailyValues.map((valueObject) => [valueObject.date, valueObject.value]);
		return dateValueArray;
	}

	percentDiffValue(): number | null {
		if (!this.compareDataset) {
			return null;
		}

		const newValue = this.originDataset.alltimeValue;
		const oldValue = this.compareDataset.alltimeValue;
		const returnValue = Math.abs(((newValue - oldValue) / oldValue) * 100);

		if (Number.isNaN(returnValue) || !Number.isFinite(returnValue)) {
			return null;
		}

		return returnValue;
	}

	trendDirection(): 'up' | 'down' | 'equal' {
		if (!this.compareDataset) {
			return 'equal';
		}

		const newValue = this.originDataset.alltimeValue;
		const oldValue = this.compareDataset.alltimeValue;

		if (newValue < oldValue) {
			return 'down';
		}

		if (newValue > oldValue) {
			return 'up';
		}

		return 'equal';
	}

	trendType(): 'none' | 'positive' | 'negative' {
		if (['up', 'down'].includes(this.positiveTrendIndicator)) {
			return this.trendDirection() === this.positiveTrendIndicator ? 'positive' : 'negative';
		}

		return 'none';
	}

	static fromType(type: InsightType, originDataset: InsightDataset, compareDataset?: InsightDataset): Insight {
		const typeDefault = insightDefaults[type];

		return new Insight({
			...typeDefault,
			originDataset,
			compareDataset,
		});
	}
}
