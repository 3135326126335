import { ResultAsync } from 'neverthrow';
import { ProviderInsightsRaw } from '@/models/ProviderInsights';
import { APIResponse } from '@/models/APIResponse';
import { CustomSearchParams } from '@/types/CustomSearchParams';
import { APIClient, responseWithErrorHandler } from './clients';
import { ressourceClient } from './clients/ressourceClient';

export const getDailyInsights = (path: string, params: CustomSearchParams): ResultAsync<ProviderInsightsRaw[], Error> => {
	const data: Promise<APIResponse<ProviderInsightsRaw>> = APIClient.useWithLoaderToJson(ressourceClient.client.get(path, {
		searchParams: params,
	}));

	return responseWithErrorHandler(data).map((response) => response.Objects);
};

export const getAlltimeInsights = (path: string, params: CustomSearchParams): ResultAsync<ProviderInsightsRaw[], Error> => {
	const data: Promise<APIResponse<ProviderInsightsRaw>> = APIClient.useWithLoaderToJson(ressourceClient.client.get(path, {
		searchParams: {
			...params,
			timeframe: 'alltime',
		},
	}));

	return responseWithErrorHandler(data).map((response) => response.Objects);
};
