<template>
	<OrganismKPIList
		:kpis="insights"
		:selected-kpis="selectedInsights"
		:visibility-settings="insightsVisibilitySettings"
		:is-loading="loading"
		@update:selectedKpis="onUpdateSelectedKpis"
	/>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Insight } from '@/models/Insight';
import { DEFAULT_SELECTED_INSIGHT_TYPES, InsightType } from '@/enums/InsightType';
import { useInsightsVisibilitySettings } from '@/composables/useInsightsVisibilitySettings';
import OrganismKPIList from './organisms/OrganismKPIList/OrganismKPIList.vue';

export default defineComponent({
	name: 'InsightList',

	components: {
		OrganismKPIList,
	},

	props: {
		insights: {
			type: Array as PropType<Insight[]>,
			default: () => ([]),
		},
		selectedInsights: {
			type: Array as PropType<InsightType[]>,
			default: DEFAULT_SELECTED_INSIGHT_TYPES,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},

	emits: ['update:selectedInsights'],

	setup(props, { emit }) {
		const onUpdateSelectedKpis = (newKpis: InsightType[]) => {
			emit('update:selectedInsights', newKpis);
		};

		const { insightsVisibilitySettings } = useInsightsVisibilitySettings();

		return {
			insightsVisibilitySettings,
			onUpdateSelectedKpis,
		};
	},
});
</script>
