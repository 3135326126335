import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "bg-purple-200 rounded-lg p-6" }
const _hoisted_2 = { class: "flex items-center mb-2" }
const _hoisted_3 = { class: "text-lg font-bold" }
const _hoisted_4 = { class: "text-sm text-grey-600 mb-6" }
const _hoisted_5 = {
  key: 0,
  class: "divide-y divide-purple-300 space-y-3 relative"
}
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_MoleculeTabBar = _resolveComponent("MoleculeTabBar")!
  const _component_AppPlaceholder = _resolveComponent("AppPlaceholder")!
  const _component_MoleculeNotificationCard = _resolveComponent("MoleculeNotificationCard")!
  const _component_MoleculeFlashMessage = _resolveComponent("MoleculeFlashMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AtomIcon, {
        icon: "updates",
        class: "mr-2 text-purple-500"
      }),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('widget.updates.title')), 1)
    ]),
    _createElementVNode("p", _hoisted_4, [
      _createVNode(_component_i18n_t, {
        keypath: `widget.updates.totalUpdatesInRange_${_ctx.MUTATION_DATE_RANGE}`
      }, {
        default: _withCtx(() => [
          _createElementVNode("strong", null, _toDisplayString(_ctx.formattedCount), 1)
        ]),
        _: 1
      }, 8, ["keypath"])
    ]),
    _createVNode(_component_MoleculeTabBar, {
      items: _ctx.updateCategories,
      "model-value": _ctx.updateCategory,
      "has-divider": false,
      size: "sm",
      class: "mb-4",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:updateCategory', $event)))
    }, null, 8, ["items", "model-value"]),
    _createVNode(_Transition, {
      "enter-from-class": "opacity-0 -translate-y-1",
      "enter-active-class": "transform duration-300",
      "leave-active-class": "transform duration-300",
      "leave-to-class": "opacity-0 translate-y-1",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (item) => {
                return _createElementVNode("div", {
                  key: item,
                  class: "pt-3 first:pt-0 flex"
                }, [
                  _createVNode(_component_AppPlaceholder, { class: "w-12 h-12 bg-purple-300 mr-3" }),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_AppPlaceholder, { class: "w-full h-3 bg-purple-300" }),
                    _createVNode(_component_AppPlaceholder, { class: "w-32 h-2 bg-purple-300" })
                  ])
                ])
              }), 64))
            ]))
          : (_ctx.updates.length > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: JSON.stringify(_ctx.updates),
                class: "divide-y divide-purple-300 space-y-3 relative"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayData, (update) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: update.date,
                    class: "pt-3 first:pt-0 inline-block w-full"
                  }, [
                    _createVNode(_component_MoleculeNotificationCard, _mergeProps(_ctx.toIconBadge(update.type), {
                      date: update.date
                    }), {
                      default: _withCtx(() => [
                        _createElementVNode("p", {
                          innerHTML: update.content
                        }, null, 8, _hoisted_7)
                      ]),
                      _: 2
                    }, 1040, ["date"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createBlock(_component_MoleculeFlashMessage, {
                key: 2,
                "hide-close": ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('updates.list.noUpdatesFound')), 1)
                ]),
                _: 1
              }))
      ]),
      _: 1
    })
  ]))
}