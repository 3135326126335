import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "text-sm mb-1" }
const _hoisted_3 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIconBadge = _resolveComponent("AtomIconBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AtomIconBadge, {
      icon: _ctx.icon,
      color: _ctx.color,
      class: "flex-shrink-0 mr-3"
    }, null, 8, ["icon", "color"]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("p", {
        class: "text-xs text-grey-500",
        title: _ctx.fromNowTooltip
      }, _toDisplayString(_ctx.fromNow), 9, _hoisted_3)
    ])
  ]))
}