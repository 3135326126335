import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-4 font-semibold" }
const _hoisted_2 = { class: "grid grid-cols-3 gap-4" }
const _hoisted_3 = { class: "text-xs" }
const _hoisted_4 = { class: "text-base font-semibold tracking-tight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoleculeKPIIconBadge = _resolveComponent("MoleculeKPIIconBadge")!
  const _component_MoleculeOverlayBox = _resolveComponent("MoleculeOverlayBox")!

  return (_openBlock(), _createBlock(_component_MoleculeOverlayBox, null, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.dataDate.toFormat('cccc dd LLLL yyyy')), 1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedAndFilteredKeys, (key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: key,
            class: "flex"
          }, [
            _createVNode(_component_MoleculeKPIIconBadge, {
              kpi: key,
              size: "sm",
              class: "mr-3"
            }, null, 8, ["kpi"]),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.getInsightDefault(key).displayName), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getInsightDefault(key).formatter(_ctx.data[key])), 1)
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}