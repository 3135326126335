import { computed, reactive, toRefs } from 'vue';
import i18n, { setI18nLanguage } from '@/plugins/i18n';
import { LanguageDictionary, supportedLanguages } from '@/models/Language';

interface LanguageState {
	language: string;
}

const state = reactive<LanguageState>({
	language: 'de',
});

export const useLanguageStore = () => {
	const getLanguageFromInput = (input: string | string[]): string => {
		if (typeof input === 'string') {
			return input;
		}

		const match = Object.values(supportedLanguages).find((sl) => input.find((l) => (l.split(new RegExp(sl.code, 'gi')).length - 1 > 0 ? sl.code : null)));

		if (match) {
			return match.code;
		}

		return 'de';
	};

	const setLanguage = (languages: string | string[]) => {
		const language = getLanguageFromInput(languages);

		state.language = language;
		localStorage.setItem('language', language);
		setI18nLanguage(i18n, language);
	};

	const setLanguageByNativeName = (nativeName: string) => {
		const { code: language } = Object.values(supportedLanguages).find((l) => l.nativeName === nativeName) || supportedLanguages.de;

		state.language = language;
		localStorage.setItem('language', language);
		setI18nLanguage(i18n, language);
	};

	const availableLanguages = computed((): LanguageDictionary => supportedLanguages);

	const initLanguage = () => {
		const language = localStorage.getItem('language') || navigator.languages as string | string[];
		setLanguage(language);
	};

	return {
		...toRefs(state),
		setLanguage,
		setLanguageByNativeName,
		availableLanguages,
		initLanguage,
	};
};
