<template>
	<div v-if="isDevelopment">
		<div class="mx-auto text-center space-y-1 px-2">
			<div class="bg-orange-500 text-white text-xs px-2 py-1 inline-block rounded-md font-semibold tracking-tight">
				Development
			</div>
			<a
				:href="`${githubRepositoryUrl}/commit/${gitCommitHash}`"
				class="bg-white/10 text-white text-xs px-2 py-1 inline-block rounded-md"
				target="_blank"
			>
				{{ shortenedGitCommitHash }}
			</a>
			<span
				class="text-purple-300 text-xs px-2 py-1 inline-block rounded-md"
			>
				Build Time: {{ buildTime }}
			</span>
		</div>
	</div>
</template>

<script lang="ts">
import { DateTime } from 'luxon';
import { computed, defineComponent } from 'vue';

export default defineComponent({
	name: 'DevelopmentDetails',

	setup() {
		const gitCommitHash = `${process.env.VUE_APP_COMMIT_REF}`;
		const githubRepositoryUrl = `${process.env.VUE_APP_REPOSITORY_URL}`;
		const buildTime = DateTime.fromISO(process.env.VUE_APP_BUILD_TIMESTAMP).toFormat('dd.LL.yyyy HH:mm:ss');
		const isDevelopment = process.env.NODE_ENV === 'development';

		const shortenedGitCommitHash = computed(() => gitCommitHash.substring(0, 6));

		return {
			gitCommitHash,
			shortenedGitCommitHash,
			githubRepositoryUrl,
			buildTime,
			isDevelopment,
		};
	},
});
</script>
