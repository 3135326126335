<template>
	<div class="flex">
		<AtomIconBadge
			:icon="icon"
			:color="color"
			class="flex-shrink-0 mr-3"
		/>
		<div>
			<p class="text-sm mb-1">
				<slot />
			</p>
			<p
				class="text-xs text-grey-500"
				:title="fromNowTooltip"
			>
				{{ fromNow }}
			</p>
		</div>
	</div>
</template>

<script lang="ts">
import { DateTime } from 'luxon';
import {
	computed, defineComponent, onUnmounted, PropType, ref,
} from 'vue';
import { IconBadgeColor } from '@/models/IconBadge';
import AtomIconBadge from '../../atoms/AtomIconBadge/AtomIconBadge.vue';

export default defineComponent({
	name: 'MoleculeNotificationCard',

	components: {
		AtomIconBadge,
	},

	props: {
		icon: {
			type: String,
			required: true,
		},
		color: {
			type: String as PropType<IconBadgeColor>,
			required: true,
		},
		date: {
			type: Date,
			required: true,
		},
	},

	setup(props) {
		const fromNowTooltip = computed(() => {
			const dateTime = DateTime.fromJSDate(props.date);
			return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
		});

		const getFromNow = () => {
			const dateTime = DateTime.fromJSDate(props.date);
			return dateTime.toRelative();
		};

		const fromNow = ref(getFromNow());

		const fromNowInterval = setInterval(() => { fromNow.value = getFromNow(); }, 5000);

		onUnmounted(() => {
			clearInterval(fromNowInterval);
		});

		return {
			fromNowTooltip,
			fromNow,
		};
	},
});
</script>
