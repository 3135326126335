import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganismKPIList = _resolveComponent("OrganismKPIList")!

  return (_openBlock(), _createBlock(_component_OrganismKPIList, {
    kpis: _ctx.insights,
    "selected-kpis": _ctx.selectedInsights,
    "visibility-settings": _ctx.insightsVisibilitySettings,
    "is-loading": _ctx.loading,
    "onUpdate:selectedKpis": _ctx.onUpdateSelectedKpis
  }, null, 8, ["kpis", "selected-kpis", "visibility-settings", "is-loading", "onUpdate:selectedKpis"]))
}