<template>
	<div>
		<div class="relative">
			<input
				ref="input"
				v-model="value"
				:type="type"
				:tabindex="tabindex"
				:placeholder="placeholder"
				:disabled="disabled"
				:readonly="readonly"
				:min="minValue"
				:max="maxValue"
				:aria-label="placeholder"
				:class="[
					colorModeClass,
					'input w-full outline-0 border rounded-md text-sm px-4 placeholder-opacity-50',
					'disabled:cursor-not-allowed',
					{
						'h-12': size === 'md',
						'h-9': size === 'sm',
						'border-red-500 border-opacity-100': isInvalid,
					}
				]"
				@focus="onFocus"
				@blur="onBlur"
				@keypress.enter="onSubmit"
			>
			<div
				v-if="isNumber"
				class="absolute right-0 top-0 bottom-0 h-full flex flex-col items-center justify-center mr-4 overflow-hidden"
				:class="{
					'pointer-events-none': !isNumberChangeable,
					'space-y-1': size === 'sm',
					'space-y-2': size === 'md',
				}"
			>
				<button
					class="h-[10px] overflow-hidden flex justify-center items-center"
					:class="colorModeNumberArrowClass"
					tabindex="-1"
					@click="increaseNumber"
				>
					<AtomIcon
						class="transform"
						icon="chevron-up"
					/>
				</button>
				<button
					class="h-[10px] overflow-hidden flex justify-center items-center"
					:class="colorModeNumberArrowClass"
					tabindex="-1"
					@click="decreaseNumber"
				>
					<AtomIcon
						class="transform"
						icon="chevron-down"
					/>
				</button>
			</div>
		</div>
		<div
			v-if="note"
			class="text-grey-500 text-sm mt-1"
		>
			{{ note }}
		</div>
		<div
			v-if="errorMessage && isInvalid"
			class="text-red-500 text-sm text-right mt-1"
		>
			{{ errorMessage }}
		</div>
	</div>
</template>

<script lang="ts">
import {
	computed, defineComponent, PropType, ref, watch,
} from 'vue';
import AtomIcon from '../AtomIcon/AtomIcon.vue';

export enum InputSize {
	SM = 'sm',
	MD = 'md',
}

export enum InputColorMode {
	LIGHT = 'light',
	DARK = 'dark',
}

export const colorModeClassMap: Record<InputColorMode, String> = {
	[InputColorMode.LIGHT]: 'border-purple-300 bg-white placeholder-black hover:bg-purple-100 focus:border-purple-600 focus:bg-white focus:shadow-sm disabled:bg-purple-200',
	[InputColorMode.DARK]: 'text-white border-opacity-50 border-white bg-transparent placeholder-white hover:bg-white hover:bg-opacity-10 focus:border-opacity-100 disabled:border-opacity-25',
};

export const colorModeNumberArrowClassMap: Record<InputColorMode, String> = {
	[InputColorMode.LIGHT]: 'text-grey-500 hover:text-grey-600',
	[InputColorMode.DARK]: 'text-grey-500 hover:text-white',
};

export default defineComponent({
	name: 'AtomInput',

	components: {
		AtomIcon,
	},

	props: {
		modelValue: {
			type: [String, Number],
			default: '',
		},
		size: {
			type: String as PropType<InputSize>,
			default: InputSize.MD,
		},
		colorMode: {
			type: String as PropType<InputColorMode>,
			default: InputColorMode.LIGHT,
		},
		type: {
			type: String,
			default: 'text',
		},
		tabindex: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		validationProps: {
			type: Object,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		note: {
			type: String,
			default: '',
		},
		errorMessage: {
			type: String,
			default: '',
		},
		min: {
			type: [String, Number],
			default: '',
		},
		max: {
			type: [String, Number],
			default: '',
		},
	},

	emits: [
		'update:modelValue',
		'blur',
		'focus',
		'submit',
	],

	setup(props, { emit }) {
		const colorModeClass = computed(() => colorModeClassMap[props.colorMode]);
		const colorModeNumberArrowClass = computed(() => colorModeNumberArrowClassMap[props.colorMode]);

		const value = ref(props.modelValue);
		const maxValue = ref(props.max);
		const minValue = ref(props.min);

		watch(value, (newValue) => {
			emit('update:modelValue', newValue);
		});

		watch(() => props.modelValue, (newValue) => {
			value.value = newValue;
		});

		const isValid = computed(() => {
			if (props.validationProps) {
				return props.validationProps.valid;
			}

			return false;
		});

		const isInvalid = computed(() => {
			if (props.validationProps && props.validationProps.validated && props.validationProps.touched) {
				return !props.validationProps.valid;
			}

			return false;
		});

		const onFocus = () => {
			emit('focus');
		};

		const onBlur = (event: InputEvent) => {
			emit('blur', event);
		};

		const onSubmit = () => {
			emit('submit');
		};

		const isDisabled = computed(() => props.disabled);
		const isReadonly = computed(() => props.readonly);

		const isNumber = computed(() => props.type === 'number');

		const isNumberChangeable = computed(() => !isDisabled.value && !isReadonly.value);

		const increaseNumber = () => {
			if (!isNumberChangeable.value) {
				return;
			}

			const modelValueNumber = typeof value.value === 'string' ? parseInt(value.value, 10) : value.value;
			const max = typeof maxValue.value === 'string' ? parseInt(maxValue.value, 10) : maxValue.value;

			if (Number.isNaN(max)) {
				return;
			}

			if (Number.isNaN(modelValueNumber)) {
				value.value = 1;
				return;
			}

			const increasedNumber = modelValueNumber + 1;

			if (increasedNumber > max) {
				return;
			}

			value.value = increasedNumber;
		};

		const decreaseNumber = () => {
			if (!isNumberChangeable.value) {
				return;
			}

			const modelValueNumber = typeof value.value === 'string' ? parseInt(value.value, 10) : value.value;
			const min = typeof minValue.value === 'string' ? parseInt(minValue.value, 10) : minValue.value;

			if (Number.isNaN(min)) {
				return;
			}

			if (Number.isNaN(modelValueNumber)) {
				value.value = -1;
				return;
			}

			const decreasedNumber = modelValueNumber - 1;

			if (decreasedNumber < min) {
				return;
			}

			value.value = decreasedNumber;
		};

		return {
			colorModeClass,
			colorModeNumberArrowClass,

			value,
			minValue,
			maxValue,

			isValid,
			isInvalid,
			isNumber,
			isDisabled,
			isNumberChangeable,

			increaseNumber,
			decreaseNumber,

			onFocus,
			onBlur,
			onSubmit,
		};
	},
});
</script>

<style scoped>
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.input[type=number] {
	-moz-appearance: textfield;
}
</style>
