<template>
	<div
		class="w-full"
		:class="classObject"
	/>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export enum DividerSize {
	SM = 'sm',
	MD = 'md',
}

export enum DividerColorMode {
	LIGHT = 'light',
	DARK = 'dark',
}

const sizeClassMap: Record<DividerSize, String> = {
	[DividerSize.SM]: 'h-px',
	[DividerSize.MD]: 'h-0.5',
};

const colorModeClassMap: Record<DividerColorMode, String> = {
	[DividerColorMode.LIGHT]: 'bg-purple-300',
	[DividerColorMode.DARK]: 'bg-white bg-opacity-50',
};

export default defineComponent({
	name: 'AtomDivider',

	props: {
		size: {
			type: String as PropType<DividerSize>,
			default: DividerSize.MD,
		},
		colorMode: {
			type: String as PropType<DividerColorMode>,
			default: DividerColorMode.LIGHT,
		},
	},

	setup(props) {
		const colorModeClass = computed(() => colorModeClassMap[props.colorMode]);
		const sizeClass = computed(() => sizeClassMap[props.size]);

		const classObject = computed(() => [
			colorModeClass.value,
			sizeClass.value,
		]);

		return {
			classObject,
		};
	},
});
</script>

<style scoped>

</style>
