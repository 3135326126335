import { RouteLocationRaw } from 'vue-router';

export type LinkLocation = string | RouteLocationRaw | (() => void);

export enum LinkSize {
	SM = 'sm',
	MD = 'md',
}

export enum LinkColor {
	PURPLE = 'purple',
	GREY = 'grey',
	GREEN = 'green',
	RED = 'red',
	WHITE = 'white',
}

export enum LinkIconPosition {
	LEFT = 'left',
	RIGHT = 'right',
}
