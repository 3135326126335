import { Plugin } from 'vue';

export const FreshworksWidgetPlugin: Plugin = {
	install() {
		const freshworksWidget = {
			isLoaded: false,
			script: null as null | HTMLScriptElement,
			onLoadListeners: [] as (() => void)[],
			init: () => {
				window.fwSettings = {
					widget_id: 61000001092,
				};

				if (typeof window.FreshworksWidget !== 'function') {
					const n = (...args: any[]) => { n.q.push(args); };
					n.q = [] as any[];
					window.FreshworksWidget = n;
				}

				freshworksWidget.script = document.createElement('script');
				freshworksWidget.script.setAttribute('src', 'https://widget.freshworks.com/widgets/61000001092.js');
				freshworksWidget.script.async = true;
				freshworksWidget.script.defer = true;
				document.head.appendChild(freshworksWidget.script);

				freshworksWidget.script.onload = () => {
					freshworksWidget.isLoaded = true;

					while (freshworksWidget.onLoadListeners.length > 0) {
						if (typeof freshworksWidget.onLoadListeners[0] === 'function') {
							freshworksWidget.onLoadListeners[0]();
						}

						freshworksWidget.onLoadListeners.shift();
					}
				};
			},
			do: (...args: any[]) => {
				if (freshworksWidget.isLoaded) {
					window.FreshworksWidget([...args]);
				} else {
					freshworksWidget.onLoadListeners.push(() => {
						window.FreshworksWidget([...args]);
					});
				}
			},
		};

		freshworksWidget.init();
	},
};
