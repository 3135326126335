import { createStore } from 'vuex';

// Modules
import auth from './modules/auth';
import strategies from './modules/strategies';
import provider from './modules/provider';
import updates from './modules/updates';
import { useUserStore } from './user';

export const store = createStore({
	state() {
		return {};
	},
	mutations: {
	},
	actions: {
		resetStore({ dispatch }) {
			const userStore = useUserStore();

			dispatch('provider/resetProviderState', {}, { root: true });
			userStore.resetState();
			dispatch('updates/resetUpdatesState', {}, { root: true });
			dispatch('strategies/resetStrategyState', {}, { root: true });
		},
	},
	getters: {
	},
	modules: {
		auth,
		strategies,
		provider,
		updates,
	},
});
