import * as Sentry from '@sentry/vue';
import { usePromiseProgressLoader } from '@sunlabde/vuelab/src/plugins/AppProgressLoader';
import ky, { HTTPError, Options, ResponsePromise } from 'ky';
import { ResultAsync } from 'neverthrow';
import { appProgressLoader } from '@/plugins/AppProgressLoader';

export class APIClient {
	#client = ky.extend({
		timeout: 15000,
	});

	constructor(options?: Options) {
		if (!options) {
			return;
		}

		this.setConfig(options);
	}

	setConfig(config: Options) {
		this.#client = this.#client.extend(config);
	}

	get client() {
		return this.#client;
	}

	async clientWithLoader(...paremeters: Parameters<typeof ky>): Promise<Response> {
		const promiseWithProgressLoader = usePromiseProgressLoader(this.client(...paremeters), appProgressLoader);
		return promiseWithProgressLoader.perform();
	}

	async clientWithLoaderToJson<T>(...paremeters: Parameters<typeof ky>): Promise<T> {
		return this.clientWithLoader(...paremeters).then((response) => response.json());
	}

	static useWithLoader = (responsePromise: ResponsePromise): Promise<Response> => {
		const promiseWithProgressLoader = usePromiseProgressLoader(responsePromise, appProgressLoader);
		return promiseWithProgressLoader.perform();
	};

	static useWithLoaderToJson = async <T>(responsePromise: ResponsePromise): Promise<T> => {
		const promiseWithProgressLoader = usePromiseProgressLoader(responsePromise, appProgressLoader);
		return promiseWithProgressLoader.perform().then((response) => response.json());
	};
}

const onFetchError = (error: unknown): Error | HTTPError => {
	Sentry.setContext('FetchError', { error });

	if (!(error instanceof Error)) {
		return new Error();
	}

	return error;
};

export const responseWithErrorHandler = <T>(data: Promise<T>, customErrorHandler?: (error: Error) => Error): ResultAsync<T, Error> => {
	const toCustomError = (error: Error) => {
		if (customErrorHandler) {
			return customErrorHandler(error);
		}

		return error;
	};

	return ResultAsync.fromPromise(data, onFetchError).mapErr(toCustomError);
};
