import { h } from 'vue';
import {
	createToastInterface, EventBus, PluginOptions, POSITION, TYPE,
} from 'vue-toastification';
import AtomIcon from '@/components/atoms/AtomIcon/AtomIcon.vue';
import AtomLink from '@/components/atoms/AtomLink/AtomLink.vue';
import { LinkSize } from '@/components/atoms/AtomLink/AtomLinkModel';

// Create a new event bus
const eventBus = new EventBus();

const DEFAULT_TOAST_CLASS_NAME = 'flex items-center py-2 px-3 rounded-md shadow-lg min-h-0';

const options: PluginOptions = {
	position: POSITION.TOP_RIGHT,
	bodyClassName: 'font-sans text-purple-900 text-sm',
	closeButtonClassName: 'opacity-50 ml-4',
	containerClassName: '!top-20 !right-20',
	toastClassName: DEFAULT_TOAST_CLASS_NAME,
	hideProgressBar: true,
	closeButton: {
		render: () => h(AtomLink, {
			icon: 'close',
			size: LinkSize.SM,
			to: () => {},
		}),
	},
	toastDefaults: {
		[TYPE.SUCCESS]: {
			toastClassName: ['bg-green-300', DEFAULT_TOAST_CLASS_NAME],
			icon: {
				render: () => h(AtomIcon, {
					icon: 'check-circle',
					class: 'text-green-500',
				}),
			},
		},
		[TYPE.ERROR]: {
			toastClassName: ['bg-red-300', DEFAULT_TOAST_CLASS_NAME],
			icon: {
				render: () => h(AtomIcon, {
					icon: 'exclamation-circle',
					class: 'text-red-500',
				}),
			},
		},
		[TYPE.INFO]: {
			toastClassName: ['bg-purple-300', DEFAULT_TOAST_CLASS_NAME],
			icon: {
				render: () => h(AtomIcon, {
					icon: 'info',
					class: 'text-purple-500',
				}),
			},
		},
		[TYPE.DEFAULT]: {
			toastClassName: ['bg-grey-300', DEFAULT_TOAST_CLASS_NAME],
			icon: {
				render: () => h(AtomIcon, {
					icon: 'bulb-circle',
					class: 'text-grey-500',
				}),
			},
		},
		[TYPE.WARNING]: {
			toastClassName: ['bg-orange-300', DEFAULT_TOAST_CLASS_NAME],
			icon: {
				render: () => h(AtomIcon, {
					icon: 'exclamation-triangle',
					class: 'text-orange-500',
				}),
			},
		},
	},
};

const toast = createToastInterface({
	eventBus,
	...options,
});

export default toast;

export {
	options, eventBus,
};
