import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "mr-6" }
const _hoisted_3 = { class: "flex h-full space-x-3" }
const _hoisted_4 = { class: "flex-1 border-r border-grey-300 pr-4 mr-4" }
const _hoisted_5 = { class: "flex items-center mb-2" }
const _hoisted_6 = { class: "text-sm font-semibold" }
const _hoisted_7 = { class: "flex space-x-4" }
const _hoisted_8 = { class: "text-xs" }
const _hoisted_9 = { class: "font-semibold" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "text-xs" }
const _hoisted_14 = { class: "font-semibold" }
const _hoisted_15 = { class: "flex-1" }
const _hoisted_16 = { class: "flex items-center mb-2" }
const _hoisted_17 = { class: "text-sm font-semibold" }
const _hoisted_18 = { class: "flex space-x-4" }
const _hoisted_19 = { class: "text-xs" }
const _hoisted_20 = { class: "font-semibold" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "text-xs" }
const _hoisted_25 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomComparativeBar = _resolveComponent("AtomComparativeBar")!
  const _component_AtomDot = _resolveComponent("AtomDot")!
  const _component_MoleculeOverlayBox = _resolveComponent("MoleculeOverlayBox")!

  return (_openBlock(), _createBlock(_component_MoleculeOverlayBox, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AtomComparativeBar, {
              hue: "300",
              "percentage-height": _ctx.getPercentageForRange(_ctx.range1)
            }, null, 8, ["percentage-height"]),
            _createVNode(_component_AtomComparativeBar, {
              "percentage-height": _ctx.getPercentageForRange(_ctx.range2)
            }, null, 8, ["percentage-height"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AtomDot, {
              hue: "300",
              class: "mr-2"
            }),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.range1.start.toFormat('dd.LL.yyyy')) + " - " + _toDisplayString(_ctx.range1.end.toFormat('dd.LL.yyyy')), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('graphLabel.total')), 1),
              _createElementVNode("p", _hoisted_9, [
                (_ctx.range1.totalValue)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.getFormattedValue(_ctx.range1.totalValue)), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_11, " - "))
              ])
            ]),
            (_ctx.range1.dailyAverageValue)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('graphLabel.dailyAverage')), 1),
                  _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.getFormattedValue(_ctx.range1.dailyAverageValue)), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_AtomDot, { class: "mr-2" }),
            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.range2.start.toFormat('dd.LL.yyyy')) + " - " + _toDisplayString(_ctx.range2.end.toFormat('dd.LL.yyyy')), 1)
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('graphLabel.total')), 1),
              _createElementVNode("p", _hoisted_20, [
                (_ctx.range2.totalValue)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.getFormattedValue(_ctx.range2.totalValue)), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_22, " - "))
              ])
            ]),
            (_ctx.range2.dailyAverageValue)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('graphLabel.dailyAverage')), 1),
                  _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.getFormattedValue(_ctx.range2.dailyAverageValue)), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}