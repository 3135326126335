<template>
	<span class="placeholder inline-block relative overflow-hidden leading-[1] text-transparent rounded-md" />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'AppPlaceholder',
	functional: true,
});
</script>

<style lang="postcss">
.placeholder:before {
	@apply bg-white bg-opacity-20 left-0 h-full w-full absolute content-[''] rounded-md;
}

.placeholder:after {
	background: linear-gradient(to right, transparent 0%, #ffffff 50%, transparent 100%);
	animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
	@apply opacity-60 content-[''] block absolute left-[-150px] top-0 h-full w-[150px];
}

@keyframes load {
	from {
		left: -150px;
	}

	to {
		left: 100%;
	}
}
</style>
