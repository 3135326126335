import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "text-sm font-semibold" }
const _hoisted_3 = { class: "text-sm text-grey-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomBadgeUser = _resolveComponent("AtomBadgeUser")!
  const _component_AtomLink = _resolveComponent("AtomLink")!
  const _component_MoleculeOverlayBox = _resolveComponent("MoleculeOverlayBox")!

  return (_openBlock(), _createBlock(_component_MoleculeOverlayBox, { class: "w-80" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AtomBadgeUser, {
          user: _ctx.user,
          class: "mr-3"
        }, null, 8, ["user"]),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.transformedUserName), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.accountType), 1),
          _createVNode(_component_AtomLink, {
            to: _ctx.logout,
            color: "grey",
            size: "sm",
            icon: "log-out",
            class: "mt-3"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('button.logout')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ]),
    _: 1
  }))
}