import { DateTime } from 'luxon';

export interface DateRange {
	start: DateTime | Date;
	end: DateTime | Date;
}

export const getNewDateRangeFromInput = (inputEvent: Event, keyToUpdate: keyof DateRange, sourceDateRange: DateRange): DateRange => {
	const target = inputEvent.target as HTMLInputElement;
	const asDateTime = DateTime.fromFormat(target.value, 'dd.LL.yyyy');
	const remainingKey = Object.keys(sourceDateRange).filter((k) => k !== keyToUpdate)[0] as keyof DateRange;
	const remainingValue = sourceDateRange[remainingKey];

	if (!asDateTime.isValid) {
		return sourceDateRange;
	}

	if (asDateTime < remainingValue) {
		return {
			start: asDateTime,
			end: remainingValue,
		};
	}

	return {
		start: remainingValue,
		end: asDateTime,
	};
};
