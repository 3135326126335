import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { App } from 'vue';
import LayoutDefault from '@/layouts/Default.vue';
import LayoutBlank from '@/layouts/Blank.vue';
import AppButton from '@/components/atoms/AtomButton/AtomButton.vue';

/**
 * Automatically add all .vue components starting with "App"
 */

const requireComponent = require.context(
	'@/components',
	false,
	/App[A-Z]\w+\.(vue|js)$/,
);

export const registerGlobalComponents = (app: App) => {
	requireComponent.keys().forEach((fileName) => {
		const componentConfig = requireComponent(fileName);

		const componentName = upperFirst(
			camelCase(fileName.split('/').pop()?.replace(/\.\w+$/, '')),
		);

		app.component(
			componentName,
			componentConfig.default || componentConfig,
		);
	});

	app.component('LayoutDefault', LayoutDefault);
	app.component('LayoutBlank', LayoutBlank);
	app.component('SunButton', AppButton);
};
