import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-5 h-5 rounded-sm border flex items-center justify-center outline-offset-2 focus:outline focus:outline-1", [_ctx.colorModeClass, {
			'cursor-not-allowed': _ctx.disabled,
		}]]),
    tabindex: "0",
    onKeypress: [
      _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["space"])),
      _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["enter"]))
    ],
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.modelValue)
      ? (_openBlock(), _createBlock(_component_AtomIcon, {
          key: 0,
          icon: "check"
        }))
      : _createCommentVNode("", true)
  ], 34))
}