<template>
	<div>
		<Icomoon
			:key="icon"
			name="icon"
			:icon-set="iconSet"
			:icon="icon"
			:size="computedSize"
		/>
	</div>
</template>

<script lang="ts">
import { Icomoon } from 'vue-icomoon';
import { computed, defineComponent, PropType } from 'vue';
import iconSet from '@/assets/icomoon/selection.json';

export enum IconSize {
	SM = 'sm',
	MD = 'md',
}

const sizePxMap: Record<IconSize, number> = {
	[IconSize.SM]: 24,
	[IconSize.MD]: 32,
};

export default defineComponent({
	name: 'AtomIcon',

	components: {
		Icomoon,
	},

	props: {
		icon: {
			type: String,
			required: true,
		},
		size: {
			type: String as PropType<IconSize>,
			default: IconSize.SM,
		},
	},

	setup(props) {
		const computedSize = computed(() => sizePxMap[props.size]);

		return {
			iconSet,
			computedSize,
		};
	},
});
</script>

<style>

</style>
