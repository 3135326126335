<template>
	<AtomIconBadge v-bind="iconBadgeBindings" />
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { IconBadgeColor } from '@/models/IconBadge';
import { InsightType } from '@/enums/InsightType';
import AtomIconBadge from '../../atoms/AtomIconBadge/AtomIconBadge.vue';

const insightTypeBadgeMap: Record<InsightType, { color: IconBadgeColor, icon: string }> = {
	[InsightType.IMPRESSIONS]: {
		color: IconBadgeColor.PURPLE,
		icon: 'impressions',
	},
	[InsightType.CLICKS]: {
		color: IconBadgeColor.BLUE,
		icon: 'clicks',
	},
	[InsightType.CTR]: {
		color: IconBadgeColor.MAGENTA,
		icon: 'ctr',
	},
	[InsightType.CPC]: {
		color: IconBadgeColor.CYAN,
		icon: 'cpc',
	},
	[InsightType.COST]: {
		color: IconBadgeColor.RED,
		icon: 'costs',
	},
	[InsightType.CONVERSIONS]: {
		color: IconBadgeColor.GREEN,
		icon: 'conversion',
	},
	[InsightType.COST_PER_CONVERSION]: {
		color: IconBadgeColor.ORANGE,
		icon: 'cta',
	},
	[InsightType.ROAS]: {
		color: IconBadgeColor.VIOLET,
		icon: 'roas',
	},
	[InsightType.CONVERSION_VALUE]: {
		color: IconBadgeColor.GREY,
		icon: 'conversionvalue-alt',
	},
};

export default defineComponent({
	name: 'MoleculeKPIIconBadge',

	components: {
		AtomIconBadge,
	},

	props: {
		kpi: {
			type: String as PropType<InsightType>,
			required: true,
		},
	},

	setup(props) {
		const iconBadgeBindings = computed(() => insightTypeBadgeMap[props.kpi]);

		return {
			iconBadgeBindings,
		};
	},
});
</script>
