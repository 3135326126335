import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["active"]
const _hoisted_2 = { class: "flex-1 grid items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: ["font-semibold font-sans flex flex-col max-w-max box-border whitespace-nowrap hover:text-purple-500 cursor-pointer", _ctx.classObject]
  }, _ctx.$attrs, {
    active: _ctx.isActive,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["enter"]))
  }), [
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["h-0.5 w-full", {
				'bg-purple-500': _ctx.isActive,
			}])
    }, null, 2)
  ], 16, _hoisted_1))
}