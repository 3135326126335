import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-2 overlay-backdrop-blur rounded-lg border border-purple-300 shadow-lg flex flex-col" }
const _hoisted_2 = {
  ref: "optionsListRef",
  class: "flex-1 overflow-auto scrollbar relative"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "p-2 text-grey-500" }
const _hoisted_5 = ["onMousedown", "onMouseover", "onFocus"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomCheckbox = _resolveComponent("AtomCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (!_ctx.hasSearchResults && _ctx.search)
        ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
            _renderSlot(_ctx.$slots, "noResults", {}, () => [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('noResults')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option, $index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: option,
          class: _normalizeClass(["flex items-center p-2 text-grey-500 rounded-sm w-full text-left focus-visible:bg-purple-200 outline-none transition cursor-pointer", {
					'!text-black bg-purple-200': _ctx.isOptionSelected(option) && !_ctx.multiselect || ($index === _ctx.typeAheadPointer),
				}]),
          onMousedown: ($event: any) => (_ctx.onMousedown(option, $event)),
          onMouseover: ($event: any) => (_ctx.typeAheadPointer = $index),
          onFocus: ($event: any) => (_ctx.typeAheadPointer = $index)
        }, [
          (_ctx.multiselect)
            ? (_openBlock(), _createBlock(_component_AtomCheckbox, {
                key: 0,
                "model-value": _ctx.isOptionSelected(option),
                class: "mr-2",
                tabindex: "-1"
              }, null, 8, ["model-value"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(option), 1)
        ], 42, _hoisted_5))
      }), 128))
    ], 512)
  ]))
}