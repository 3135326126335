import { Options } from 'ky';
import { ResultAsync } from 'neverthrow';
import { APIResponse } from '@/models/APIResponse';
import { User } from '@/models/User';
import { APIClient, responseWithErrorHandler } from './clients';
import { ressourceClient } from './clients/ressourceClient';

export const fetchUsers = (options?: Options): ResultAsync<User[], Error> => {
	const data: Promise<APIResponse<User>> = APIClient.useWithLoaderToJson(ressourceClient.client.get('users', {
		...options,
		searchParams: {
			order: 'Name',
		},
	}));

	return responseWithErrorHandler(data).map((response) => response.Objects);
};
