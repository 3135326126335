import { IconBadge, IconBadgeColor } from './IconBadge';

export type MutationType = 'Cpc' | 'Reactor';

export const mutationTypeIconBadgeMap: Record<MutationType, IconBadge> = {
	Cpc: {
		color: IconBadgeColor.VIOLET,
		icon: 'cpc',
	},
	Reactor: {
		color: IconBadgeColor.CYAN,
		icon: 'keyword',
	},
};

export const toIconBadge = (mutationType: MutationType): IconBadge => mutationTypeIconBadgeMap[mutationType];
