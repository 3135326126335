import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "transition flex items-center transform z-10 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: ["relative flex items-center justify-center box-border", _ctx.classObject]
  }, _ctx.$attrs), [
    _createElementVNode("span", _hoisted_1, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_AtomIcon, {
            key: 0,
            icon: _ctx.icon,
            size: _ctx.size
          }, null, 8, ["icon", "size"]))
        : _createCommentVNode("", true)
    ])
  ], 16))
}