import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href", "to", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_ctx.isActionLink)
    ? (_openBlock(), _createElementBlock("button", _mergeProps({ key: 0 }, {
			..._ctx.linkDefaultBindings,
			..._ctx.$attrs,
		}, {
        class: _ctx.linkDefaultBindings.class,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.to && _ctx.to(...args)))
      }), [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_AtomIcon, _normalizeProps(_mergeProps({ key: 0 }, _ctx.iconDefaultBindings)), null, 16))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ])
      ], 16))
    : (_ctx.isExternalLink)
      ? (_openBlock(), _createElementBlock("a", _mergeProps({ key: 1 }, {
			..._ctx.$attrs,
			..._ctx.linkDefaultBindings,
		}, {
          class: _ctx.linkDefaultBindings.class,
          href: _ctx.to,
          target: "_blank",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onExternalLinkClick && _ctx.onExternalLinkClick(...args)))
        }), [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_AtomIcon, _normalizeProps(_mergeProps({ key: 0 }, _ctx.iconDefaultBindings)), null, 16))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ])
        ], 16, _hoisted_1))
      : (_openBlock(), _createBlock(_component_RouterLink, _mergeProps({ key: 2 }, _ctx.$props, { custom: "" }), {
          default: _withCtx(({ href, navigate }) => [
            _createElementVNode("a", _mergeProps({
				..._ctx.linkDefaultBindings,
				..._ctx.$attrs,
			}, {
              class: _ctx.linkDefaultBindings.class,
              href: href,
              to: _ctx.to,
              onClick: navigate
            }), [
              (_ctx.icon)
                ? (_openBlock(), _createBlock(_component_AtomIcon, _normalizeProps(_mergeProps({ key: 0 }, _ctx.iconDefaultBindings)), null, 16))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ])
            ], 16, _hoisted_2)
          ]),
          _: 3
        }, 16))
}