import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aa3c076"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative w-full h-2 rounded-lg bg-purple-300 overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["h-full rounded-lg relative progress-bar overflow-hidden transition-all ease-in-out duration-1000", _ctx.progress === _ctx.fullValue ? 'bg-cyan-500' : 'bg-violet-500']),
      style: _normalizeStyle(`width: ${_ctx.progress}%;`)
    }, null, 6)
  ]))
}