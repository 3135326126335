import { computed } from 'vue';
import { useStore } from 'vuex';
import { Provider } from '@/models/Provider';

export const useProvider = () => {
	const store = useStore();

	const selectedProvider = computed<Provider | null>({
		get() {
			return store.getters['provider/selectedProvider'];
		},
		set(newProvider) {
			if (newProvider) {
				store.dispatch('provider/selectProvider', newProvider?.Id);
			}
		},
	});

	const selectedProviderName = computed<string>({
		get() {
			return selectedProvider.value?.Name || '';
		},
		set(providerName) {
			const providers = store.getters['provider/providers'] as Provider[];
			const provider = providers.find((p) => p.Name === providerName);
			selectedProvider.value = provider || null;
		},
	});

	const providerOptions = computed(() => {
		const providers = store.getters['provider/providers'] as Provider[];
		return providers.map((p) => p.Name);
	});

	return {
		selectedProvider,
		selectedProviderName,
		providerOptions,
	};
};
