<template>
	<OrganismUpdatesList
		v-model:updateCategory="selectedFilter"
		:updates="dataAsUpdateTime"
		:changes-count="totalUpdatesInRange"
		:loading="requestedNewFetch"
	/>
</template>

<script lang="ts">
import { DateTime } from 'luxon';
import { useStore } from 'vuex';
import {
	defineComponent, ref, computed, watch, onMounted, onUnmounted,
} from 'vue';
import { getContentFromMutationLogItem, MutationLogItem } from '@/models/MutationLogItem';
import { MutationType } from '@/models/MutationType';
import { UpdateItem } from '@/models/UpdateItem';
import OrganismUpdatesList from './organisms/OrganismUpdatesList/OrganismUpdatesList.vue';

export default defineComponent({
	name: 'WidgetUpdates',

	components: {
		OrganismUpdatesList,
	},

	setup() {
		const filterMutationTypeMap: Record<string, MutationType | ''> = {
			All: '',
			Bids: 'Cpc',
			Keywords: 'Reactor',
		};

		const requestedNewFetch = ref(true);
		const fetchingData = ref(false);
		const fetchInterval = ref<number>();
		const selectedFilter = ref<keyof typeof filterMutationTypeMap>('All');

		const filterToMutationType = (filter: keyof typeof filterMutationTypeMap) => filterMutationTypeMap[filter];

		const selectedMutationTypeFilter = computed(() => filterToMutationType(selectedFilter.value));

		const updatesFilter = [
			{
				name: 'Keyword',
				value: 'Reactor',
			},
			{
				name: 'Bid',
				value: 'Cpc',
			},
			{
				name: 'All',
				value: '',
			},
		];

		const store = useStore();

		const filteredData = computed<MutationLogItem[]>(() => {
			if (selectedMutationTypeFilter.value === 'Reactor') {
				return store.getters['updates/reactorUpdates'];
			}

			if (selectedMutationTypeFilter.value === 'Cpc') {
				return store.getters['updates/cpcUpdates'];
			}

			return store.getters['updates/updates'];
		});

		const dataAsUpdateTime = computed(() => filteredData.value.map((data) => ({
			date: DateTime.fromISO(data.MutationDate, { zone: 'utc' }).toLocal().toJSDate(),
			content: getContentFromMutationLogItem(data),
			type: data.MutationType,
		} as UpdateItem)));

		const getUpdatesFunctionForFilterCategory = (mutationType?: MutationType | '') => (options: { total: number, pagination?: boolean, silent?: boolean }) => {
			if (mutationType === 'Reactor') {
				selectedFilter.value = 'Keywords';
				return store.dispatch('updates/getReactorUpdates', options);
			}

			if (mutationType === 'Cpc') {
				selectedFilter.value = 'Bids';
				return store.dispatch('updates/getCpcUpdates', options);
			}

			selectedFilter.value = 'All';
			return store.dispatch('updates/fetchUpdates', options);
		};

		const getData = (mutationType?: MutationType | '') => {
			const requestTimeout = setTimeout(() => {
				requestedNewFetch.value = true;
			}, 100);

			const fetchFunction = getUpdatesFunctionForFilterCategory(mutationType);
			clearInterval(fetchInterval.value);

			fetchFunction({ total: 5, pagination: true }).then(() => {
				clearTimeout(requestTimeout);
				requestedNewFetch.value = false;
			});

			fetchInterval.value = setInterval(async () => {
				if (!fetchingData.value && document.hasFocus()) {
					fetchingData.value = true;
					await fetchFunction({ total: 5, silent: true });
					fetchingData.value = false;
				}
			}, 10000);
		};

		watch(() => store.getters['provider/selectedProvider'], (provider) => {
			if (provider) {
				getData();
			}
		});

		watch(selectedFilter, (newFilter) => {
			getData(filterToMutationType(newFilter));
		});

		onMounted(() => {
			getData();
		});

		onUnmounted(() => {
			clearInterval(fetchInterval.value);
		});

		const totalUpdatesInRange = computed(() => store.getters['updates/totalUpdatesInRange']);

		return {
			requestedNewFetch,
			fetchingData,
			fetchInterval,
			selectedFilter,
			updatesFilter,
			filteredData,
			dataAsUpdateTime,
			totalUpdatesInRange,
		};
	},
});
</script>
