<template>
	<MoleculeOverlayBox>
		<p class="mb-4 font-semibold">
			{{ dataDate.toFormat('cccc dd LLLL yyyy') }}
		</p>
		<div class="grid grid-cols-3 gap-4">
			<div
				v-for="key in sortedAndFilteredKeys"
				:key="key"
				class="flex"
			>
				<MoleculeKPIIconBadge
					:kpi="key"
					size="sm"
					class="mr-3"
				/>
				<div>
					<p class="text-xs">
						{{ getInsightDefault(key).displayName }}
					</p>
					<p class="text-base font-semibold tracking-tight">
						{{ getInsightDefault(key).formatter(data[key]) }}
					</p>
				</div>
			</div>
		</div>
	</MoleculeOverlayBox>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { DateTime } from 'luxon';
import { ProviderInsightsRaw } from '@/models/ProviderInsights';
import { insightDefaults } from '@/models/Insight';
import { InsightType } from '@/enums/InsightType';
import { DEFAULT_SORT_ORDER } from '@/models/Insights';
import MoleculeKPIIconBadge from '../../molecules/MoleculeKPIIconBadge/MoleculeKPIIconBadge.vue';
import MoleculeOverlayBox from '../../molecules/MoleculeOverlayBox/MoleculeOverlayBox.vue';

export default defineComponent({
	name: 'OrganismOverlayKPI',

	components: {
		MoleculeOverlayBox,
		MoleculeKPIIconBadge,
	},

	props: {
		data: {
			type: Object as PropType<ProviderInsightsRaw>,
			required: true,
		},
	},

	setup(props) {
		const getInsightDefault = (type: InsightType) => insightDefaults[type];

		const sortedAndFilteredKeys = computed(() => DEFAULT_SORT_ORDER.filter((orderItem) => Object.keys(props.data).includes(orderItem)));

		const dataDate = computed(() => DateTime.fromISO(props.data.InsightDate));

		return {
			getInsightDefault,
			sortedAndFilteredKeys,
			dataDate,
		};
	},
});
</script>
