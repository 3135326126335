import { Directive, DirectiveBinding } from 'vue';

const formatNumber = (value: number, binding: DirectiveBinding) => {
	if (Number.isNaN(value) || !Number.isFinite(value)) {
		return 'n/a';
	}

	if (binding.modifiers.toPercentageFixed) {
		return `${value.toFixed(1).replace('.', ',')}%`;
	}

	if (binding.modifiers.toPercentage) {
		return `${value}%`;
	}

	if (typeof binding.arg === 'function') {
		/* eslint-disable @typescript-eslint/ban-ts-comment */
		// @ts-ignore
		return binding.arg(value);
	}

	return value;
};

const directive: Directive = {
	beforeMount(el, binding) {
		el.innerHTML = formatNumber(binding.value, binding);
	},
	updated(el, binding) {
		let interval = 0;
		let counter = 0;
		const { oldValue, value } = binding;
		const transformedOldValue = Number.isFinite(oldValue) ? oldValue : 0;

		let updatedValue = transformedOldValue;

		if (interval) {
			clearInterval(interval);
		}

		if (transformedOldValue === value || oldValue === value) {
			return;
		}

		interval = window.setInterval(() => {
			if (updatedValue !== value && counter < 30) {
				let change = (value - updatedValue) / 10;

				if (binding.modifiers.toPercentage) {
					change = change >= 0 ? Math.ceil(change) : Math.floor(change);
				}

				updatedValue += change;
				el.innerHTML = formatNumber(updatedValue, binding);
				counter++;
			} else {
				el.innerHTML = formatNumber(value, binding);
				clearInterval(interval);
			}
		}, 30);
	},
};

export default directive;
