import ISO6391 from 'iso-639-1';
import { SUPPORTED_LOCALES } from '@/utilities/Constants';

export type Language = { code: string; name: string; nativeName: string; };
export type LanguageDictionary = Record<string, Language>;
export type SupportedLocale = typeof SUPPORTED_LOCALES[number];

export const localeToLanguage = (dictionary: LanguageDictionary, locale: string): LanguageDictionary => {
	const language: Language = {
		code: locale,
		name: ISO6391.getName(locale),
		nativeName: ISO6391.getNativeName(locale),
	};

	Object.assign(dictionary, {
		[locale]: language,
	});

	return dictionary;
};

export const supportedLanguages = SUPPORTED_LOCALES
	.reduce(localeToLanguage, {});

export const isLocaleSupported = (locale: string): boolean => Object.keys(supportedLanguages).includes(locale);
