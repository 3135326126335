<template>
	<div class="flex h-full">
		<div
			class="w-2 overflow-hidden rounded-lg self-end"
			:class="classObject"
			:style="`height: ${percentageHeight}%`"
		/>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export enum ComparativeBarColor {
	PURPLE = 'purple',
	GREY = 'grey',
	BLUE = 'blue',
	MAGENTA = 'magenta',
}

export enum ComparativeBarHue {
	LIGHT = '300',
	DARK = '500',
}

const colorClassMap: Record<ComparativeBarColor, Record<ComparativeBarHue, String>> = {
	[ComparativeBarColor.PURPLE]: {
		[ComparativeBarHue.LIGHT]: 'bg-purple-300',
		[ComparativeBarHue.DARK]: 'bg-purple-500',
	},
	[ComparativeBarColor.GREY]: {
		[ComparativeBarHue.LIGHT]: 'bg-grey-300',
		[ComparativeBarHue.DARK]: 'bg-grey-500',
	},
	[ComparativeBarColor.BLUE]: {
		[ComparativeBarHue.LIGHT]: 'bg-blue-300',
		[ComparativeBarHue.DARK]: 'bg-blue-500',
	},
	[ComparativeBarColor.MAGENTA]: {
		[ComparativeBarHue.LIGHT]: 'bg-magenta-300',
		[ComparativeBarHue.DARK]: 'bg-magenta-500',
	},
};

export default defineComponent({
	name: 'AtomComparativeBar',

	props: {
		percentageHeight: {
			type: Number,
			default: 100,
		},
		color: {
			type: String as PropType<ComparativeBarColor>,
			default: ComparativeBarColor.PURPLE,
		},
		hue: {
			type: String as PropType<ComparativeBarHue>,
			default: ComparativeBarHue.DARK,
		},
	},

	setup(props) {
		const colorClass = computed(() => colorClassMap[props.color][props.hue]);

		const classObject = computed(() => [
			colorClass.value,
		]);

		return {
			classObject,
		};
	},
});
</script>
