import {
	Ref, reactive, computed, onMounted, watch,
} from 'vue';
import { Strategy } from '@/models/Strategy';
import { fetchStrategies } from '@/api/strategy';

const accountProgressState = reactive({
	providerId: null as null | number,
	lowestProgressStrategy: null as null | Strategy,
});

export const useLowestProgressStrategy = (options: { providerId: Ref<number | null> }) => {
	const fetchStrategyByLowestProgress = async () => {
		if (options.providerId.value === null) {
			return;
		}

		const result = await fetchStrategies(options.providerId.value, {
			ParentStrategyLink_Id: 'null',
			order: 'TotalLearningProgress',
			skip: '0',
			take: '1',
			State: 'Active',
		});

		if (result.isOk()) {
			[accountProgressState.lowestProgressStrategy] = result.value;
		}
	};

	const lowestProgressStrategy = computed(() => accountProgressState.lowestProgressStrategy);

	onMounted(() => {
		if (options.providerId.value !== accountProgressState.providerId) {
			fetchStrategyByLowestProgress();
		}
	});

	watch([options.providerId], () => {
		fetchStrategyByLowestProgress();
	});

	return {
		lowestProgressStrategy,
		fetchStrategyByLowestProgress,
	};
};
