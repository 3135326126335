import i18n from '@/plugins/i18n';

export type StrategyLearningStage = 'Complete' | 'Initial' | 'BuildingExperience' | 'Finetuning';

export interface StrategyLearningStageDescription {
	id: StrategyLearningStage;
	displayName: string | (() => string);
}

export type StrategyLearningStageDictionary = Record<StrategyLearningStage, StrategyLearningStageDescription>;

export const strategyLearningStageDescription: StrategyLearningStageDictionary = {
	Complete: {
		id: 'Complete',
		displayName() {
			return i18n.global.t('learningStage.complete');
		},
	},
	Initial: {
		id: 'Initial',
		displayName() {
			return i18n.global.t('learningStage.initial');
		},
	},
	BuildingExperience: {
		id: 'BuildingExperience',
		displayName() {
			return i18n.global.t('learningStage.buildingExperience');
		},
	},
	Finetuning: {
		id: 'Finetuning',
		displayName() {
			return i18n.global.t('learningStage.finetuning');
		},
	},
};
