import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-xs mb-1" }
const _hoisted_2 = { class: "text-xl font-semibold tracking-tight" }
const _hoisted_3 = {
  ref: "appendAchor",
  class: "group inline-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoleculeKPIIconBadge = _resolveComponent("MoleculeKPIIconBadge")!
  const _component_AtomLink = _resolveComponent("AtomLink")!
  const _component_OrganismOverlayCompare = _resolveComponent("OrganismOverlayCompare")!
  const _directive_animated_number = _resolveDirective("animated-number")!
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative px-6 pt-6 pb-5 flex transition group ripple-purple-300", {
			'bg-white hover:bg-purple-200': _ctx.active,
			'hover:bg-purple-100': !_ctx.active,
		}])
  }, [
    _createVNode(_component_MoleculeKPIIconBadge, {
      kpi: _ctx.kpi.key,
      class: "mr-3"
    }, null, 8, ["kpi"]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.kpi.displayName), 1),
      _withDirectives(_createElementVNode("p", _hoisted_2, null, 512), [
        [_directive_animated_number, _ctx.kpi.originDataset.alltimeValue, _ctx.kpi.formatter]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AtomLink, {
          icon: _ctx.trendIcon,
          to: '',
          size: "sm",
          color: _ctx.trendColor,
          onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCompareOverlay = true)),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCompareOverlay = false)),
          onFocusin: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCompareOverlay = true)),
          onFocusout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showCompareOverlay = false))
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [
                _directive_animated_number,
                _ctx.kpi.percentDiffValue(),
                void 0,
                { toPercentageFixed: true }
              ]
            ])
          ]),
          _: 1
        }, 8, ["icon", "color"]),
        (_openBlock(), _createBlock(_Teleport, { to: "#poppers" }, [
          _createElementVNode("div", {
            ref: "overlayRef",
            class: _normalizeClass(["z-50", {
							'pointer-events-none': !_ctx.showCompareOverlay,
						}])
          }, [
            _createVNode(_component_OrganismOverlayCompare, {
              class: _normalizeClass(["transition min-w-[480px]", {
								'opacity-0': !_ctx.showCompareOverlay,
							}]),
              range1: _ctx.toCompareDateRange(_ctx.kpi.compareDataset, _ctx.kpi.hasAverage),
              range2: _ctx.toCompareDateRange(_ctx.kpi.originDataset, _ctx.kpi.hasAverage),
              formatter: _ctx.kpi.formatter,
              onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCompareOverlay = true)),
              onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showCompareOverlay = false)),
              onFocusin: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showCompareOverlay = true)),
              onFocusout: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showCompareOverlay = false))
            }, null, 8, ["class", "range1", "range2", "formatter"])
          ], 2)
        ]))
      ], 512)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute -z-[1] w-full h-full shadow-md top-0 left-0 pointer-events-none rounded-lg transition", {
				'opacity-0 group-hover:opacity-100': !_ctx.active,
			}])
    }, null, 2)
  ], 2)), [
    [_directive_ripple]
  ])
}