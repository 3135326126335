<template>
	<div class="flex flex-wrap gap-3">
		<AtomChip
			v-for="option in options"
			:key="option"
			:model-value="isSelected(option)"
			@update:modelValue="onSelectOption($event, option)"
		>
			{{ getOptionLabel(option) }}
		</AtomChip>
		<slot />
	</div>
</template>

<script lang="ts">
import {
	defineComponent, PropType, toRefs,
} from 'vue';
import AtomChip from '@/components/atoms/AtomChip/AtomChip.vue';
import { useSelect, SelectOption } from '@/composables/useSelect';

export default defineComponent({
	name: 'ChipList',

	components: { AtomChip },

	props: {
		modelValue: {
			type: [String, Array] as PropType<SelectOption | SelectOption[]>,
			required: true,
		},
		options: {
			type: Array as PropType<SelectOption[]>,
			default: () => ([]),
		},
		multiselect: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		'update:modelValue',
	],

	setup(props) {
		const {
			getOptionKey, getOptionLabel, isSelected, onSelectOption,
		} = useSelect(toRefs(props).modelValue, props.multiselect, props.required);

		return {
			isSelected,
			onSelectOption,
			getOptionLabel,
			getOptionKey,
		};
	},
});
</script>
