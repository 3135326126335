import { Ref, computed } from 'vue';
import { Strategy } from '@/models/Strategy';

export const useStrategyLearningProgress = (strategy: Ref<Strategy | null>) => {
	const totalLearningWeeksRemaining = computed(() => {
		if (strategy.value) {
			return Math.round(strategy.value.TotalLearningDaysRemaining / 7);
		}

		return 0;
	});

	const learningProgress = computed(() => {
		if (!strategy.value) {
			return 0;
		}

		return Math.round(strategy.value.TotalLearningProgress);
	});

	const computedStrategy = computed(() => strategy.value);

	return {
		totalLearningWeeksRemaining,
		learningProgress,
		strategy: computedStrategy,
	};
};
