import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d50b6ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["active"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomIcon = _resolveComponent("AtomIcon")!
  const _directive_ripple = _resolveDirective("ripple")!

  return _withDirectives((_openBlock(), _createElementBlock("button", _mergeProps({
    class: ["ripple-purple-300 font-semibold text-xs font-sans relative box-border cursor-pointer transition-all rounded-lg text-center tracking-wide flex flex-col justify-center items-center h-[69px] text-white", _ctx.classObject]
  }, _ctx.$attrs, {
    active: _ctx.isActive,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }), [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_AtomIcon, {
          key: 0,
          icon: _ctx.icon,
          size: "sm",
          class: _normalizeClass({
				'mb-1': _ctx.label.length > 0,
			})
        }, null, 8, ["icon", "class"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ], true)
  ], 16, _hoisted_1)), [
    [_directive_ripple]
  ])
}