import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganismKPICard = _resolveComponent("OrganismKPICard")!
  const _component_LoaderCheckmark = _resolveComponent("LoaderCheckmark")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["overlay-backdrop-blur shadow-lg rounded-lg grid relative", {
			'grid-cols-5': _ctx.renderedInsights.length > 8,
			'grid-cols-4': [7, 8].includes(_ctx.renderedInsights.length),
			'grid-cols-3': [6].includes(_ctx.renderedInsights.length),
			'grid-flow-col auto-cols-fr': _ctx.renderedInsights.length <= 5
		}])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderedInsights, (kpi, $index) => {
      return (_openBlock(), _createBlock(_component_OrganismKPICard, {
        key: kpi.key,
        kpi: kpi,
        class: _normalizeClass(["cursor-pointer border-purple-200", {
				'rounded-tl-lg': $index === 0,
				'rounded-tr-lg': _ctx.isLastOfFirstRow($index),
				'rounded-bl-lg': _ctx.isFirstOfLastRow($index),
				'rounded-br-lg': _ctx.isLastOfLastRow($index),
				'border-r': !_ctx.isLastOfRow($index),
				'border-b': !_ctx.isInLastRow($index),
			}]),
        active: _ctx.isSelected(kpi),
        onClick: ($event: any) => (_ctx.onSelectKpi(kpi))
      }, null, 8, ["kpi", "class", "active", "onClick"]))
    }), 128)),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoaderCheckmark, {
          key: 0,
          wrapper: true,
          class: "rounded-lg bg-white/80"
        }))
      : _createCommentVNode("", true)
  ], 2))
}